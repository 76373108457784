import { useContext } from "react";
import { AuthContext } from "../../components/Context/AuthContext";
import useCollection from "../firestore/useCollection";
import moment from "moment";
import { SessionContext } from "../../context/SessionContext";
import { Quota } from "./types";

const useCustomerQuota = (): Quota => {
  const FIRST_DAY_MOTNH = moment().startOf("month").toDate();
  const LAST_DAY_MOTNH = moment().endOf("month").toDate();
  const { mid } = useContext(AuthContext);
  const { authorizations } = useContext(SessionContext);
  const currentCustomer = useCollection(
    `merchants/${mid}/customer`,
    [
      ["createTime", ">", FIRST_DAY_MOTNH],
      ["createTime", "<=", LAST_DAY_MOTNH],
    ],
    ["createTime", "desc"],
    10000
  );
  const customerCount = Object.keys(currentCustomer || {}).length || 0;
  const percentReached =
    authorizations["general.customer"] === 0
      ? 100
      : Math.round(
          (customerCount * 100) / Number(authorizations["general.customer"])
        );

  return {
    current: customerCount,
    quoata: authorizations["general.customer"],
    percent: percentReached > 100 ? 100 : percentReached,
    percentLeft: 100 - (percentReached > 100 ? 100 : percentReached),
  };
};

export default useCustomerQuota;
