import { useTranslation } from "react-i18next";
import StepCard from "./StepCard";
import { FaBuilding, FaCreditCard, FaKey, FaMoneyCheck } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import {
  hasApiKey,
  hasBillingData,
  hasPaymentProvider,
  hasTariff,
} from "../../../../utils/setup";
import { AuthContext } from "../../../../components/Context/AuthContext";
import Loading from "../../../../components/Loading";

interface ISetUpWorkflowState {
  loading: boolean;
  hasData: boolean;
  hasTariff: boolean;
  hasProvider: boolean;
  hasKey: boolean;
  allDone: boolean;
}

const SetUpWorkflow = () => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const [setUpWorkflowState, setSetUpWorkflowState] =
    useState<ISetUpWorkflowState>({
      loading: true,
      hasData: false,
      hasTariff: false,
      hasProvider: false,
      hasKey: false,
      allDone: false,
    });
  useEffect(() => {
    const refreshData = async () => {
      try {
        const data = await hasBillingData(mid || "-");
        const tariff = await hasTariff(mid || "-");
        const provider = await hasPaymentProvider(mid || "-");
        const key = await hasApiKey(mid || "-");
        let allDone: boolean = false;
        if (data && tariff && provider && key) {
          allDone = true;
        }
        setSetUpWorkflowState((setUpWorkflowState) => {
          return {
            ...setUpWorkflowState,
            loading: false,
            hasData: data,
            hasTariff: tariff,
            hasProvider: provider,
            hasKey: key,
            allDone: allDone,
          };
        });
      } catch {
        setSetUpWorkflowState((setUpWorkflowState) => {
          return {
            ...setUpWorkflowState,
            loading: false,
            hasData: true,
            hasTariff: true,
            hasProvider: true,
            hasKey: true,
            allDone: true,
          };
        });
      }
    };
    refreshData();
  }, [mid]);
  return setUpWorkflowState.loading ? (
    <Loading />
  ) : setUpWorkflowState.allDone ? (
    <></>
  ) : (
    <div className="flex flex-row flex-wrap gap-4 mb-16">
      <div className="w-full text-left">
        <p className="text-3xl font-bold">{t("components.dashboard.setup")}</p>
        <p className="font-thin">
          {t("components.dashboard.start_with_abocloud")}
        </p>
      </div>
      <StepCard
        title={t("components.dashboard.setup_cards.data.title")}
        path="/settings/profile"
        icon={<FaBuilding />}
        action={t("components.dashboard.setup_cards.data.button")}
        hidden={setUpWorkflowState.hasData}
      >
        {t("components.dashboard.setup_cards.data.text")}
      </StepCard>
      <StepCard
        title={t("components.dashboard.setup_cards.tariff.title")}
        path="/settings/tariff"
        icon={<FaMoneyCheck />}
        action={t("components.dashboard.setup_cards.tariff.button")}
        hidden={setUpWorkflowState.hasTariff}
      >
        {t("components.dashboard.setup_cards.tariff.text")}
      </StepCard>
      <StepCard
        title={t("components.dashboard.setup_cards.payment.title")}
        path="/settings/payment"
        icon={<FaCreditCard />}
        action={t("components.dashboard.setup_cards.payment.button")}
        hidden={setUpWorkflowState.hasProvider}
      >
        {t("components.dashboard.setup_cards.payment.text")}
      </StepCard>
      <StepCard
        title={t("components.dashboard.setup_cards.key.title")}
        path="/settings/token"
        icon={<FaKey />}
        action={t("components.dashboard.setup_cards.key.button")}
        hidden={setUpWorkflowState.hasKey}
      >
        {t("components.dashboard.setup_cards.key.text")}
      </StepCard>
    </div>
  );
};

export default SetUpWorkflow;
