import { useCallback, useEffect, useState } from "react";
import { DB } from "../../../firebase";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import firebase from "firebase/compat/app";
import { Navigate, useMatch } from "react-router-dom";

interface IRedirectPaymentState {
  redirect: boolean;
}

const RedirectPayment = () => {
  const { t } = useTranslation();
  const match = useMatch("/redirect/:id");
  const [redirectPaymentState, setRedirectPaymentState] =
    useState<IRedirectPaymentState>({
      redirect: false,
    });

  const handleUpdate = useCallback(
    async (
      session: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
      snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
      aboSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ) => {
      if (
        snapshot.exists &&
        snapshot.data() &&
        snapshot.data()?.payment &&
        snapshot.data()?.payment.mollieapiobject &&
        snapshot.data()?.payment.mollieapiobject.status
      ) {
        if (
          ["paid", "failed", "expired", "canceled"].includes(
            snapshot.data()?.payment.mollieapiobject.status
          )
        ) {
          session.ref.set({ state: "expired" }, { merge: true });
          window.location.assign(aboSnap.data()?.abodetails.redirecturl);
        }
      }
    },
    []
  );

  useEffect(() => {
    const sessionid = match?.params.id || "";

    console.log("useEffect", sessionid);

    DB()
      .collection("transaction_session")
      .doc(sessionid)
      .get()
      .then((session) => {
        console.log("useEffect::then", session.data());

        const sessionData = session.data();
        if (sessionData && sessionData.state !== "expired") {
          sessionData.transaction
            .get()
            .then(
              (
                transactionSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
              ) => {
                console.log("Transaction:", transactionSnap.id);
                sessionData.abo
                  .get()
                  .then(
                    (
                      aboSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
                    ) => {
                      console.log("Abo:", aboSnap.id);
                      transactionSnap.ref.onSnapshot((snap) =>
                        handleUpdate(session, snap, aboSnap)
                      );
                    }
                  );
              }
            );
          //sessionData.transaction.ref.onSnapshot(snap => handleUpdate(snap, sessionData.abo.ref))
        }
      })
      .catch((err) => {
        console.log("useEffect::error", err);
        setRedirectPaymentState((redirectPaymentState) => {
          return { ...redirectPaymentState, redirect: true };
        });
      });
  }, [match?.params.id, handleUpdate]);

  if (redirectPaymentState.redirect) {
    return <Navigate to="/Login" />;
  } else {
    return (
      <>
        <div className="p-0 w-full text-center h-screen">
          <div className="h-full flex flex-wrap grid grid-cols-1 content-center bg-background_blue">
            <div className="flex m-auto">
              <p className="text-xl font-bold text-white">
                {t("components.redirect.redirect_message")}
              </p>
            </div>
            <div className="flex">
              <Loading color="white" />
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default RedirectPayment;
