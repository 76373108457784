import { Amount } from "../../../types/Amount";

type BadgeModeProps = {
  amount: Amount;
  operator?: string;
};

const BadgeAmount = (props: BadgeModeProps) => {
  return (
    <div className="bg-gray-100 rounded rounded-xl w-max px-2 text-sm text-bold text-green-700">
      {props.operator ? props.operator : ""}
      {props.amount.value} {props.amount.currency}
    </div>
  );
};

export default BadgeAmount;
