import { COLL_KEY, merchantRef } from "./firestore";

export const hasBillingData = async (merchant: string): Promise<boolean> => {
  const doc = (await merchantRef(merchant).get()).data();
  return new Promise<boolean>((resolve) => {
    resolve(
      doc?.company &&
        doc?.company?.sevdesk &&
        doc?.company?.sevdesk?.c_id &&
        doc?.company?.sevdesk?.ca_id &&
        doc?.company?.sevdesk?.cp_id &&
        doc?.company?.sevdesk?.cw_id
    );
  });
};

export const hasTariff = async (merchant: string): Promise<boolean> => {
  const doc = (await merchantRef(merchant).get()).data();
  return new Promise<boolean>((resolve) => {
    resolve(doc?.tariff && doc?.tariff.tariffRef !== undefined);
  });
};

export const hasPaymentProvider = async (
  merchant: string
): Promise<boolean> => {
  const doc = (await merchantRef(merchant).get()).data();
  return new Promise<boolean>((resolve) => {
    resolve(
      doc?.paymentprovider &&
        doc?.paymentprovider?.mollie &&
        doc?.paymentprovider?.mollie?.access_token !== undefined &&
        doc?.paymentprovider?.mollie?.refresh_token !== undefined &&
        doc?.paymentprovider?.mollie?.profileid !== undefined
    );
  });
};

export const hasApiKey = async (merchant: string): Promise<boolean> => {
  const doc = await merchantRef(merchant).collection(COLL_KEY).get();
  return new Promise<boolean>((resolve) => {
    resolve(!doc.empty);
  });
};
