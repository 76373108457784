import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Dropdown";
import { useFirestore } from "react-redux-firebase";
import { COLL_MERCHANT } from "../../../utils/firestore";
import { AuthContext } from "../../Context/AuthContext";
import { CountryType, getStaticCountryFromLocal } from "../../../utils/sevdesk";
import { Checkbox } from "@mui/material";
import { Functions } from "../../../firebase";
import Loading from "../../Loading";
import moment from "moment";

const CompanyDataForm = ({
  onComplete,
  showConditions = true,
  showAvv = false,
  submitText,
}: {
  onComplete: () => void;
  showConditions?: boolean;
  showAvv?: boolean;
  submitText?: string;
}) => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { mid, currentUser } = useContext(AuthContext);
  const handleData = Functions().httpsCallable(
    "calls-sevdesk-handleCompanyData"
  );
  const createAvvPdf = Functions().httpsCallable(
    "management-calls-contracts-generatePDF"
  );

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [organisation, setOrganisation] = useState<string>("");
  const [extra, setExtra] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<number>(1);
  const [countries, setCountries] = useState<Array<CountryType>>([]);
  const [vat, setVat] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [acceptedConditions, setAcceptedConditions] = useState<boolean>(false);
  const [acceptedAvv, setAcceptedAvv] = useState<boolean>(false);

  const [hasOrganisationName, setHasOrganisationName] = useState<boolean>(true);

  const [errors, setErrors] = useState<Array<string> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const storeData = () => {
    setLoading(true);
    if (hasErrors()) {
      setLoading(false);
      return;
    }
    handleData({
      company: {
        firstname: firstname,
        lastname: lastname,
        organisation: organisation,
        extra: extra,
        street: street,
        number: number,
        zip: zip,
        city: city,
        country: country,
        vat: vat,
        email: email,
        ...(showConditions === true && { terms_accepted: acceptedConditions }),
        ...(showAvv === true &&
          acceptedAvv === true && {
            avv_accepted: `${moment().format(
              "DD_MM_YYYY_hh_mm_ss"
            )}#[MID]${mid}#[UID]${currentUser || "empty"}#[VERSION]${`1.0.0`}`,
          }),
      },
    })
      .then(async (data) => {
        console.log(data);
        if (showAvv === true) {
          await createAvvPdf({ version: `1.0.0` })
            .then((avvResponse) => {
              if (
                data.data?.success &&
                data.data?.success === true &&
                avvResponse.data === true
              ) {
                onComplete();
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hasErrors = (): boolean => {
    const errorArray: Array<string> = [];
    if (!firstname || firstname.trim().length === 0) {
      errorArray.push("firstname");
    }
    if (!lastname || lastname.trim().length === 0) {
      errorArray.push("lastname");
    }
    if (!organisation || organisation.trim().length === 0) {
      errorArray.push("organisation");
    }
    if (!street || street.trim().length === 0) {
      errorArray.push("street");
    }
    if (!number || number.trim().length === 0) {
      errorArray.push("number");
    }
    if (!zip || zip.trim().length === 0) {
      errorArray.push("zip");
    }
    if (!city || city.trim().length === 0) {
      errorArray.push("city");
    }
    if (!vat || vat.trim().length === 0) {
      errorArray.push("vat");
    }
    if (!email || email.trim().length === 0) {
      errorArray.push("email");
    }
    if (showConditions === true && acceptedConditions === false) {
      errorArray.push("terms");
    }
    if (showAvv === true && acceptedAvv === false) {
      errorArray.push("avv");
    }
    setErrors(errorArray);
    return errorArray.length > 0;
  };

  useEffect(() => {
    const countryConfig = getStaticCountryFromLocal();
    setCountries(countryConfig.objects);
  }, []);

  useEffect(() => {
    setLoading(true);
    setErrors(null);
    firestore
      .collection(COLL_MERCHANT)
      .doc(mid || "-")
      .get()
      .then((merchant) => {
        if (merchant.data()?.company) {
          const company_data = merchant.data()?.company;
          setFirstname(company_data.firstname);
          setLastname(company_data.lastname);
          setOrganisation(company_data.organisation);
          setExtra(company_data.extra);
          setStreet(company_data.street);
          setNumber(company_data.number);
          setZip(company_data.zip);
          setCity(company_data.city);
          setCountry(company_data.country || 1);
          setVat(company_data.vat);
          setEmail(company_data.email);
          setHasOrganisationName(company_data?.organisation !== undefined);
        } else {
          setHasOrganisationName(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [mid, firestore]);

  const onCustomerDataChanged = (event: any, id: string) => {
    if (id === "organisation") {
      if (hasOrganisationName === false) {
        setOrganisation(event.target.value);
      }
    } else if (id === "firstname") {
      setFirstname(event.target.value);
      clearError("firstname");
    } else if (id === "lastname") {
      setLastname(event.target.value);
      clearError("lastname");
    } else if (id === "email") {
      setEmail(event.target.value);
      clearError("email");
    } else if (id === "street") {
      setStreet(event.target.value);
      clearError("street");
    } else if (id === "number") {
      setNumber(event.target.value);
      clearError("number");
    } else if (id === "zip") {
      setZip(event.target.value);
      clearError("zip");
    } else if (id === "city") {
      setCity(event.target.value);
      clearError("city");
    } else if (id === "country") {
      setCountry(+event.target.value);
      clearError("country");
    } else if (id === "vat") {
      setVat(event.target.value);
      clearError("vat");
    } else if (id === "extra") {
      setExtra(event.target.value);
      clearError("extra");
    } else {
      return;
    }
  };

  const showError = (id: string) => {
    if (errors?.includes(id)) {
      return { borderColor: "#ff8a80", borderWidth: 2 };
    }
    return undefined;
  };

  const clearError = (id: string) => {
    const errorArray = errors;
    if (errorArray && errorArray?.indexOf(id) > -1) {
      errorArray.splice(errorArray?.indexOf(id, 1));
    }
    setErrors(errorArray);
  };

  return (
    <div className="flex flex-wrap justify-between content-center items-center w-1/2 lg:w-10/12 sm:w-full gap-2 sm:mx-auto my-2 box-border text-center">
      {loading === true ? (
        <div className="flex flex-row w-full justify-center content-center items-center">
          <Loading />
        </div>
      ) : (
        <>
          <input
            onChange={(event) => onCustomerDataChanged(event, "firstname")}
            type="text"
            required={true}
            value={firstname}
            id="firstname"
            placeholder={`${t("forms.company_data.firstname")}*`}
            className="sm:w-full rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
            style={showError("firstname")}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "lastname")}
            type="text"
            required={true}
            value={lastname}
            id="lastname"
            placeholder={`${t("forms.company_data.lastname")}*`}
            className="sm:w-full rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
            style={showError("lastname")}
          />

          <input
            onChange={(event) => onCustomerDataChanged(event, "organisation")}
            type="text"
            required={true}
            readOnly={hasOrganisationName}
            value={organisation}
            id="organisation"
            placeholder={`${t("forms.company_data.organisation")}*`}
            className={
              hasOrganisationName
                ? "block mx-auto sm:w-full rounded-lg text-base w-full p-2 mt-2 box-border border-black border text-black font-bold bg-gray-100 cursor-not-allowed"
                : "block mx-auto sm:w-full rounded-lg text-base w-full p-2 mt-2 box-border border-black border text-black"
            }
            style={showError("organisation")}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "extra")}
            type="text"
            required={true}
            value={extra}
            id="extra"
            placeholder={`${t("forms.company_data.extra")}`}
            className="block mx-auto rounded-lg text-base w-full p-2 mt-2 box-border border-black border text-black"
            style={showError("extra")}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "street")}
            type="text"
            required={true}
            value={street}
            id="street"
            placeholder={`${t("forms.company_data.street")}*`}
            className="sm:w-full  rounded-lg text-base w-8/12 p-2 mt-2 box-border border-black border text-black"
            style={showError("street")}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "number")}
            type="text"
            required={true}
            value={number}
            id="number"
            placeholder={`${t("forms.company_data.number")}*`}
            className="sm:w-full  rounded-lg text-base w-3/12 p-2 mt-2 box-border border-black border text-black"
            style={showError("number")}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "zip")}
            type="text"
            required={true}
            value={zip}
            id="zip"
            placeholder={`${t("forms.company_data.zipcode")}*`}
            className="sm:w-full  rounded-lg text-base w-3/12 p-2 mt-2 box-border border-black border text-black"
            style={showError("zip")}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "city")}
            type="text"
            required={true}
            value={city}
            id="city"
            placeholder={`${t("forms.company_data.city")}*`}
            className="sm:w-full  rounded-lg text-base w-8/12 p-2 mt-2 box-border border-black border text-black"
            style={showError("city")}
          />
          <Dropdown
            initialId={`${country || 1}`}
            id="country"
            options={countries.map((country) => {
              return {
                id: `${country.id}`,
                value: `${country.id}`,
                name: country.name,
              };
            })}
            onChangeListener={onCustomerDataChanged}
          />
          <input
            onChange={(event) => onCustomerDataChanged(event, "vat")}
            type="text"
            required={true}
            value={vat}
            id="vat"
            placeholder={`${t("forms.company_data.vat")}*`}
            className="sm:w-full rounded-lg text-base w-1/2 p-2 mt-2 box-border border-black border text-black"
            style={showError("vat")}
          />
          <div className="w-1/2" />
          <input
            onChange={(event) => onCustomerDataChanged(event, "email")}
            type="email"
            required={true}
            value={email}
            id="email"
            placeholder={`${t("forms.company_data.email")}*`}
            className="sm:w-full  rounded-lg text-base w-1/2 p-2 mt-2 box-border border-black border text-black"
            style={showError("email")}
          />
          <div className="w-1/3 sm:w-full">
            <p className="text-sm text-left">
              {t("forms.company_data.email_info")}
            </p>
          </div>
          {showConditions && (
            <div
              className="w-full mt-4 flex flex-row justify-start items-center content-center"
              style={showError("terms")}
            >
              <Checkbox
                id="accept"
                checked={acceptedConditions}
                onChange={() => {
                  setAcceptedConditions(!acceptedConditions);
                }}
                sx={{
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
              />
              <label htmlFor="accept">
                {t("forms.company_data.conditions_text_1")}
                <a
                  href="https://abocloud.io/nutzungsbedingungen/"
                  className="underline"
                >
                  {` ${t("forms.company_data.conditions_text_2")} `}
                </a>
                {t("forms.company_data.conditions_text_3")}
                <a href="https://abocloud.io/datenschutz" className="underline">
                  {` ${t("forms.company_data.conditions_text_4")}`}
                </a>
              </label>
            </div>
          )}
          {showAvv && (
            <div className="w-full mt-4 flex flex-col justify-center items-start content-start">
              <div
                className="w-full flex flex-row justify-start items-center content-center"
                style={showError("avv")}
              >
                <Checkbox
                  id="acceptAvv"
                  checked={acceptedAvv}
                  onChange={() => {
                    setAcceptedAvv(!acceptedAvv);
                  }}
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: "black",
                    },
                  }}
                />
                <label>
                  {t("forms.company_data.avv_text_1")}
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `https://ws-cdn.de/abocloud/Dokumente/AVV_Muster.pdf`,
                        `_blank`
                      );
                    }}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {` (${t("forms.company_data.avv_text_2")}) `}
                  </p>
                </label>
              </div>
              <p className="text-sm mt-10 text-left">
                {t("forms.company_data.avv_text_3")}
              </p>
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <input
              onClick={storeData}
              type="submit"
              id="save"
              disabled={loading ? true : false}
              value={
                loading
                  ? "..."
                  : hasOrganisationName
                  ? t("forms.company_data.refresh").toString()
                  : submitText
                  ? submitText
                  : t("forms.company_data.save").toString()
              }
              className="block sm:w-full rounded-xl p-2 w-1/4 text-base my-8 box-border text-white bg-black cursor-pointer"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyDataForm;
