import { useTranslation } from "react-i18next";
import ExternalLink from "../../../../modules/Admin/Customer/ExternalLink";
import { Merchant } from "../../../../types/Merchant";
import TokenCardAdmin from "../TokenCard";

type DetailviewCustomerProps = {
  id: string;
  merchant: Merchant | null;
  keys?: any[];
  abos?: any[];
  onClick?: (path: string, object: any) => {};
};

const CustomerDetailView = (props: DetailviewCustomerProps) => {
  const { t } = useTranslation();

  /*
  const doClick = (path: string, abo: any) => {
    if (props.onClick && props.abos) {
      props.onClick(path, abo);
    }
  };*/

  return (
    <>
      {props.merchant !== null ? (
        <>
          <div className="flex flex-row gap-4 flex-wrap p-16 2xl:p-8 xl:p-2 items-start">
            <div className="text-left p-4 rounded rounded-xl bg-white w-1/3 3xl:w-1/2 2xl:w-2/3 xl:w-full xl:mx-auto">
              <div>
                <div className="flex flex-wrap justify-between">
                  <p className="font-light text-xl w-max">Kunde</p>
                  <p className="font-thin text-sm text-gray-700 w-max truncate">
                    {props.id}
                  </p>
                </div>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                  <>
                    <p className="font-light">Name</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.name}
                    </div>
                  </>
                  <>
                    <p className="font-light">Firma</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata?.organisation || (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                  <>
                    <p className="font-light">E-Mail</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata?.email || (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                  <>
                    <p className="font-light">Anschrift</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata ? (
                        <>
                          <p>{`${props.merchant?.customerdata?.firstname} ${props.merchant?.customerdata?.lastname}`}</p>
                          <p>{`${props.merchant?.customerdata?.street} ${props.merchant?.customerdata?.number}`}</p>
                          <p>{`${props.merchant?.customerdata?.zip || ""} ${
                            props.merchant?.customerdata?.city || ""
                          }`}</p>
                        </>
                      ) : (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                </div>
                <div className="flex flex-wrap justify-between">
                  <p className="font-light text-xl w-max">Sevdesk</p>
                </div>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                  <>
                    <p className="font-light">Ansprechpartner</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata?.sevdesk
                        ?.personcontactid ? (
                        <div className="flex flex-wrap gap-2 content-center">
                          <p className="w-max self-center">
                            {
                              props.merchant?.customerdata?.sevdesk
                                ?.personcontactid
                            }
                          </p>{" "}
                          <ExternalLink
                            link={
                              `https://my.sevdesk.de/#/crm/detail/id/` +
                              props.merchant?.customerdata?.sevdesk
                                ?.personcontactid
                            }
                          />
                        </div>
                      ) : (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                  <>
                    <p className="font-light">Kontakt</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata?.sevdesk?.contactid ? (
                        <div className="flex flex-wrap gap-2 content-center">
                          <p className="w-max self-center">
                            {props.merchant?.customerdata?.sevdesk?.contactid}
                          </p>{" "}
                          <ExternalLink
                            link={
                              `https://my.sevdesk.de/#/crm/detail/id/` +
                              props.merchant?.customerdata?.sevdesk?.contactid
                            }
                          />
                        </div>
                      ) : (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                  <>
                    <p className="font-light">Adresse</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata?.sevdesk
                        ?.contactaddressid || (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                  <>
                    <p className="font-light">Kommunikationsweg</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.customerdata?.sevdesk
                        ?.communicationwayid || (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                </div>
                <div className="flex flex-wrap justify-between">
                  <p className="font-light text-xl w-max">Zahlungsanbieter</p>
                </div>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                  <>
                    <p className="font-light">Profil-ID</p>
                    <div className="font-bold col-span-2">
                      {props.merchant?.paymentprovider?.mollie?.profileid || (
                        <p className="font-thin italic">nicht hinterlegt</p>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="text-left p-4 rounded rounded-xl bg-white w-1/3 3xl:w-1/2 2xl:w-2/3 xl:w-full xl:mx-auto max-h-max">
              <div>
                <div className="flex flex-wrap justify-between">
                  <p className="font-light text-xl w-max">API-Schlüssel</p>
                </div>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                {props.keys && props.keys.length > 0 ? (
                  <div className="flex flex-wrap gap-2 p-2">
                    {props.keys.map((key) => {
                      return (
                        <TokenCardAdmin
                          id={key.id}
                          merchantid={props.id}
                          title={key.data.name}
                          url={key.data.url}
                          apikey={key.data.key}
                          children={null}
                          status={key.data.status}
                        />
                      );
                    })}{" "}
                  </div>
                ) : (
                  <p className="font-thin italic mt-2">
                    Der Händler hat keine API-Schlüssel
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-max m-auto p-4 mt-8">
          {t("components.customers_details.not_found", { id: props.id })}
        </div>
      )}
    </>
  );
};

export default CustomerDetailView;
