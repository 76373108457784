import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { AuthContext } from "../../../../components/Context/AuthContext";
import Dropdown from "../../../../components/Dropdown";
import { DB } from "../../../../firebase";
// import { LanguageState } from "../../../../store/store";
import SettingCard from "../SettingCard";
import { COLL_MERCHANT, COLL_USER } from "../../../../utils/firestore";
import {
  // useLanguage,
  useSetLanguage,
} from "../../../../components/ThemeLanguageWrapper/language";
import { Languages } from "../../../../types/language";

const LanguageSettings = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { currentUser, mid } = useContext(AuthContext);
  const setLanguage = useSetLanguage();
  // const lang = useLanguage();
  const [currentLanguage, setCurrentLanguage] = useState<Languages>(
    i18n?.language as Languages
  );
  // const language = useSelector(
  //   (state: { language: LanguageState }) => state.language.language
  // );

  useImperativeHandle(ref, () => ({
    saveLanguage() {
      i18n.changeLanguage(currentLanguage).then(() => {
        setLanguage(currentLanguage);
        storeLanguage(currentLanguage);
      });
    },
  }));

  // const dispatch = useDispatch();

  const onLanguageChanged = (event: any, id: string) => {
    setCurrentLanguage(event.target.value);
  };

  const storeLanguage = (language: string) => {
    if (currentUser) {
      DB()
        .collection(`${COLL_MERCHANT}/${mid}/${COLL_USER}`)
        .doc(currentUser)
        .set(
          {
            preferences: {
              language: language,
            },
          },
          { merge: true }
        )
        .then(console.log)
        .catch(console.error);
    }
  };

  return (
    <SettingCard title={t("components.settings.account.setting_language")}>
      <div className="w-1/3 lg:w-1/2 xs:w-full">
        <Dropdown
          id="language"
          initialId={currentLanguage}
          options={[
            { id: "de", value: "de", name: "Deutsch" },
            { id: "en", value: "en", name: "Englisch" },
          ]}
          onChangeListener={onLanguageChanged}
        />
      </div>
    </SettingCard>
  );
});
export default LanguageSettings;
