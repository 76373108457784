import { useTranslation } from "react-i18next";
import { Abo } from "../../../../types/Abo";
import BadgeAmount from "../../../Badge/BadgeAmount";
import {
  calculateNextExecution,
  formatDate,
  formatDateFromFirebaseTimestamp,
  translateAboState,
  translateTransactionState,
} from "../../../../utils/utils";
import BadgeMode from "../../../Badge/BadgeMode";

type DetailviewAboProps = {
  id: string;
  merchantid: string | undefined;
  abo: Abo | undefined;
  transactions?: any[];
  onClick?: (path: string, object: any) => void | {};
};

const AboDetailView = (props: DetailviewAboProps) => {
  const { t } = useTranslation();

  const doClick = (path: string, transaction: any) => {
    if (props.onClick && props.transactions) {
      props.onClick(path, transaction);
    }
  };

  const getNextExecution = (date: any, abo: Abo): string => {
    if (date === undefined) {
      return (
        t("components.abonnement_details.execution_probably_at") +
        " " +
        formatDate(calculateNextExecution(abo), "DD.MM.YYYY")
      );
    } else if (date === null) {
      return t("components.abonnement_details.execution_date_now");
    } else {
      return formatDateFromFirebaseTimestamp(date);
    }
  };

  return (
    <>
      {props.abo !== undefined ? (
        <>
          <div className="xl:p-4">
            <div className="text-left m-8 p-4 rounded rounded-xl bg-white w-1/2 xl:w-full xl:mx-auto">
              <div>
                <div className="flex flex-wrap justify-between">
                  <p className="font-light text-xl">
                    {t("components.abonnement_details.abonnement_details")}
                  </p>
                  <p className="font-thin text-sm text-gray-700 w-max truncate">
                    {props.id}
                  </p>
                </div>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                  <>
                    <p className="font-light">Händler</p>
                    <div className="font-bold col-span-2">
                      {props.merchantid}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.description")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.description}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.created")}
                    </p>
                    <div className="font-bold col-span-2">
                      {formatDateFromFirebaseTimestamp(props.abo.createTime)}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.interval")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.interval}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.repeatitions")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.times
                        ? `${props.abo.count} ${t(
                            "components.abonnement_details.of"
                          )} ${props.abo.times}`
                        : `${props.abo.count}`}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.amount_products")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.products?.length}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.amount")}
                    </p>
                    <div className="font-bold col-span-2">
                      <BadgeAmount amount={props.abo.amount} />
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.next_execution")}
                    </p>
                    <div className="font-bold col-span-2">
                      {getNextExecution(props.abo.nextExecution, props.abo)}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.mode")}
                    </p>
                    <div className="font-bold col-span-2">
                      <BadgeMode isTest={props.abo.test} />
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.state")}
                    </p>
                    <div className="font-bold col-span-2">
                      {translateAboState(props.abo.status)}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.metadata")}
                    </p>
                    <div className="font-bold col-span-2">
                      <div className="h-min w-full">
                        <pre>
                          <code>
                            {props.abo.metadata
                              ? JSON.stringify(props.abo.metadata, null, 2)
                              : ""}
                          </code>
                        </pre>
                      </div>
                    </div>
                  </>
                </div>
                <p className="font-light text-xl">
                  {t("components.abonnement_details.payment_details")}
                </p>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                <div className="m-2 grid grid-cols-3 xs:grid-cols-1">
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.mandat_id")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.ppMandateId || "n/a"}
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="text-left m-8 p-4 rounded rounded-xl bg-white w-1/2 xl:w-full xl:mx-auto">
              <div>
                <p className="font-light text-xl">
                  {t("components.abonnement_details.last_transactions")}
                </p>
                <hr className=" rounded rounded-2xl border-solid border-black" />
                {props.transactions?.map((transaction) => {
                  return (
                    <div
                      key={transaction.id}
                      className="flex flex-wrap justify-between p-1 my-1 rounded rounded-xl border border-solid grid grid-cols-2 hover:bg-gray-300 hover:border-black"
                      onClick={() =>
                        doClick(
                          "../../atransactions/" +
                            props.merchantid +
                            "/" +
                            transaction.id,
                          transaction
                        )
                      }
                    >
                      <div className="flex flex-wrap justify-between">
                        <p className="flex truncate">{transaction.id}</p>
                        <p className="flex truncate">
                          {translateTransactionState(transaction.data.status)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-max m-auto p-4 mt-8">
          {t("components.abonnement_details.not_found", { id: props.id })}
        </div>
      )}
    </>
  );
};

export default AboDetailView;
