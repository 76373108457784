import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../components/Context/AuthContext";
import VersionInfo from "../VersionInfo";

const SidebarFooterContent = () => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div className="w-full mb-12">
      <p className=" mx-auto w-max font-normal">
        {auth.authContextState.user.displayName &&
        auth.authContextState.user !== null
          ? auth.authContextState.user.displayName
          : ""}
      </p>

      <p
        onClick={auth.signOut}
        className="cursor-pointer mx-auto w-max lowercase text-base pt-2 pb-2 font-bold"
      >
        {t("auth.logout")}
      </p>
      <VersionInfo />
    </div>
  );
};

export default SidebarFooterContent;
