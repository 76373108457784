import {
  Button as MuiButton,
  MobileStepper,
  SxProps,
  Theme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { AuthContext } from "../Context/AuthContext";
import { ScreenInterface } from "./ScreenInterface";
import Button from "../Button";
import { COLL_MERCHANT } from "../../utils/firestore";
import { SessionContext } from "../../context/SessionContext";

const Screens = () => {
  const firestore = useFirestore();
  const { mid } = useContext(AuthContext);
  const { userRole } = useContext(SessionContext);
  const [screenVisible, setScreenVisible] = useState<boolean>(false);
  const [userScreens, setUserScreens] = useState<Array<string> | null>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [currentScreen, setCurrentScreen] = useState<ScreenInterface | null>(
    null
  );

  useEffect(() => {
    firestore
      .collection(COLL_MERCHANT)
      .doc(`${mid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const screens = doc?.data()?.screens || null;
          setUserScreens(screens);
        }
      });
  }, [firestore]);

  const updateScreens = async () => {
    const updatedScreens = userScreens?.filter((_v: string, index: number) => {
      return index > 0;
    });
    await firestore
      .collection(COLL_MERCHANT)
      .doc(`${mid}`)
      .set({ screens: updatedScreens }, { merge: true });
  };

  useEffect(() => {
    if (userScreens !== null && userScreens?.length > 0) {
      const currentScreen = userScreens[0];
      import(`./${currentScreen.toLowerCase()}`)
        .then((screenModule) => {
          const _Screen = screenModule.default;
          const Screen = new _Screen();
          if (
            true ||
            Screen.screenRole() === null ||
            Screen.screenRole().includes(userRole)
          ) {
            console.log(`Show screen`);
            setCurrentScreen(Screen);
            setScreenVisible(true);
            updateScreens();
          }
        })
        .catch(() => {
          //ToDo remove Screen to prevent blocking
          setScreenVisible(false);
          updateScreens();
        });
    }
  }, [userScreens]);

  const closeScreen = () => {
    setScreenVisible(false);
  };

  const handleNextStep = () => {
    if (
      currentScreen?.screenLength() &&
      activeStep === currentScreen?.screenLength() - 1
    ) {
      closeScreen();
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const handlePreviousStep = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const handleChildClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  const customScreenStyle: SxProps<Theme> = {
    fontFamily: "Comfortaa",
    width: "100%",
    "& .MuiMobileStepper-dotActive": {
      color: "black",
      backgroundColor: "black",
    },
  };

  return screenVisible ? (
    <div
      onClick={/*closeScreen*/ () => {}}
      className="block h-screen w-auto z-50 inset-0 bg-black_rgba fixed"
    >
      <div className="flex w-full h-screen justify-center content-center fixed">
        <div
          onClick={(e) => handleChildClick(e)}
          className="flex justify-start flex-wrap flex-col rounded rounded-xl bg-white text-black p-5 m-2 self-center w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-full"
          style={{ minHeight: 370 }}
        >
          <div className="flex flex-col justify-between content-between h-full min-h-full grow">
            <div>
              <div className="text-2xl font-bold w-max mb-2">{`${
                currentScreen?.screen(activeStep)?.title
              }`}</div>
              <div className="w-max">
                {currentScreen?.screen(activeStep)?.content}
              </div>
            </div>
            <div style={{ display: "block", bottom: 0 }}>
              <div className="w-full flex justify-center mt-8 px-16 bottom-0">
                <MuiButton
                  size="medium"
                  onClick={closeScreen}
                  sx={{ fontFamily: "Comfortaa" }}
                  style={{ color: "#000000", marginRight: 20 }}
                >
                  Schließen
                </MuiButton>
                <MobileStepper
                  variant="dots"
                  steps={currentScreen?.screenLength() || 0}
                  position="static"
                  color="#000"
                  activeStep={activeStep}
                  sx={customScreenStyle}
                  nextButton={
                    // <Button
                    //   size="medium"
                    //   onClick={handleNextStep}
                    //   disabled={
                    //     currentScreen?.screenLength()
                    //       ? activeStep === currentScreen?.screenLength() - 1
                    //       : true
                    //   }
                    //   sx={{ fontFamily: "Comfortaa" }}
                    //   style={{ color: "#000000" }}
                    // >
                    //   Weiter
                    // </Button>
                    <Button
                      title={
                        currentScreen?.screenLength()
                          ? activeStep === currentScreen?.screenLength() - 1
                            ? `Schließen`
                            : `Weiter`
                          : `Weiter`
                      }
                      onClickListener={handleNextStep}
                      disabled={
                        currentScreen?.screenLength()
                          ? activeStep === currentScreen?.screenLength()
                          : true
                      }
                    />
                  }
                  backButton={
                    <>
                      {/* <Button
                      size="medium"
                      onClick={handlePreviousStep}
                      disabled={activeStep === 0}
                      sx={{ fontFamily: "Comfortaa" }}
                      style={{ color: "#000000" }}
                    >
                      Zurück
                      
                    </Button> */}
                      <Button
                        title={`Zurück`}
                        onClickListener={handlePreviousStep}
                        disabled={activeStep === 0}
                      />
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Screens;
