import { ReactNode, useContext, useEffect } from "react";
import "../../locales/i18n";
import numbro from "numbro";
import moment from "moment";
import { useSelector } from "react-redux";
import { Languages } from "../../types/language";
import { Interfaces } from "../../store";
import { LanguageContext, useSetLanguage } from "./language";
import { SessionContext } from "../../context/SessionContext";

const ThemeLanguageWrapper = ({ children }: { children?: ReactNode }) => {
  const { preferences } = useContext(SessionContext);
  const language = useSelector(
    (state: Interfaces.Store) => state.language.language
  );
  const setLanguageHook = useSetLanguage();

  useEffect(() => {
    if (preferences && preferences !== null && preferences.language) {
      const lang = preferences?.language === "de" ? Languages.DE : Languages.EN;
      if (lang !== language) {
        setLanguageHook(lang);
        numbro.setLanguage(lang);
        moment.locale(lang);
      }
    }
  }, [preferences, language, setLanguageHook]);

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  );
};

export default ThemeLanguageWrapper;
