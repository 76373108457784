import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import {
  aboCollectionRef,
  customerCollectionRef,
} from "../../../../utils/firestore";
import { useFirestore } from "react-redux-firebase";
import { CLASS_NAME_MAIN } from "../../../../constants";
import LoadingComponent from "../../../../components/Loading/LoadingWrapper";
import { Customer } from "../../../../types/Customer";
// import DetailviewCustomer from "../../../../components/Detailview/Main/Customer";
import DetailviewCustomer from "../../../../components/Detailview/Main/CustomerRenewed";
import { replaceReferencesWithPath } from "../../../../utils/utils";
import { Abo } from "../../../../types/Abo";
import firebase from "firebase/compat/app";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

type ICustomerdetailState = {
  loading: boolean;
  customerid: string;
  customer: any;
  shopName: string;
  shopUrl: string;
  abos: any[];
  abos_loading: boolean;
};

const CustomerDetail = () => {
  const [customerdetailState, setCustomerdetailState] =
    useState<ICustomerdetailState>({
      loading: true,
      customerid: "",
      customer: undefined,
      shopName: "",
      shopUrl: "",
      abos: [],
      abos_loading: true,
    });

  const match = useMatch("/customer/:customerid");
  const location = useLocation();
  const navigate = useNavigate();

  const customerid = match?.params.customerid || "n/a";
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();

  useEffect(() => {
    const getAboDetails = async () => {
      if (location.state && location.state.customer) {
        setCustomerdetailState((customerdetailState) => {
          return {
            ...customerdetailState,
            customer: location.state.customer.data,
            customerid: location.state.customer.id,
            loading: false,
          };
        });
        const customerPath: string = location.state.customer.path;
        const customerRef: firebase.firestore.DocumentReference =
          firestore.doc(customerPath);
        aboCollectionRef(mid || "-")
          .where("customerRef", "==", customerRef)
          .orderBy("createTime", "asc")
          .get()
          .then((abos) => {
            let aboArray: any[] = [];
            abos.docs.forEach((abo) => {
              aboArray.push({
                data: replaceReferencesWithPath(abo.data()),
                path: abo.ref.path,
                id: abo.id,
              });
            });
            setCustomerdetailState((customerdetailState) => {
              return {
                ...customerdetailState,
                abos: aboArray,
                abos_loading: false,
              };
            });
          });
        return;
      } else if (mid) {
        const customer = await customerCollectionRef(mid || "-")
          .doc(customerid)
          .get();

        if (customer.exists) {
          setCustomerdetailState((customerdetailState) => {
            return {
              ...customerdetailState,
              customer: customer.data() as Customer,
              aboid: customer.id,
              loading: false,
            };
          });
          const abos = await aboCollectionRef(mid || "-")
            .where("customerRef", "==", customer.ref)
            .orderBy("createTime", "asc")
            .get();

          let aboArray: any[] = [];
          abos.docs.forEach((abo) => {
            aboArray.push({
              data: replaceReferencesWithPath(abo.data()) as Abo,
              path: abo.ref.path,
              id: abo.id,
            });
          });
          setCustomerdetailState((customerdetailState) => {
            return {
              ...customerdetailState,
              abos: aboArray,
              abos_loading: false,
            };
          });
        }

        setCustomerdetailState((customerdetailState) => {
          return {
            ...customerdetailState,
            loading: false,
          };
        });
      }
    };
    getAboDetails();
  }, [mid, customerid, firestore, location.state]);

  const handleOnClick = useCallback(
    (link: string, customer?: any) =>
      navigate(link, { state: { customer: customer } }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <LoadingComponent isLoading={customerdetailState.loading}>
        <DetailviewCustomer
          customer={customerdetailState.customer}
          id={customerid}
          onClick={handleOnClick}
          abos={customerdetailState.abos}
        ></DetailviewCustomer>
      </LoadingComponent>
    </div>
  );
};

export default CustomerDetail;
