import { useEffect, useState } from "react";
import CompanyDataForm from "../../../Form/CompanyData";
import { useTranslation } from "react-i18next";

const CompanyData = ({ nextStep }: { nextStep: () => void }) => {
  const [completed, setCompleted] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (completed === true) {
      nextStep();
    }
  }, [completed, nextStep]);
  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: 30,
      }}
    >
      {/* <p
        style={{
          marginRight: 60,
          marginTop: 20,
          fontSize: 18,
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        {`${t("components.onboarding.company_data.company_data")}:`}
      </p> */}
      <CompanyDataForm
        onComplete={() => {
          setCompleted(true);
        }}
        showConditions={true}
        showAvv={true}
        submitText={`${t("components.onboarding.company_data.submit")}`}
      />
    </div>
  );
};

export default CompanyData;
