import {
  BubbleDataPoint,
  Chart,
  ChartTypeRegistry,
  ScatterDataPoint,
} from "chart.js";

export const addTextToChart = (
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >
) => {
  const width = chart.width;
  const height = chart.height;
  const ctx = chart.ctx;

  ctx.restore();
  const fontSize = (height / 10).toFixed(2);
  ctx.font = `bold ${fontSize}px Comfortaa`;
  ctx.textBaseline = "hanging";
  const text = `${chart.data.datasets[0].data[0]?.toString()}%` || "";
  const textX = Math.round((width - ctx.measureText(text).width) / 2);
  const textY = height / 2 + 2;

  ctx.fillText(text, textX, textY);
  ctx.save();
};
