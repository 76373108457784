import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import {
  aboCollectionRef,
  createTimestampFromObject,
} from "../../../utils/firestore";
import { Abo } from "../../../types/Abo";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import BadgeAmount from "../../../components/Badge/BadgeAmount";
import BadgeMode from "../../../components/Badge/BadgeMode";
import firebase from "firebase/compat/app";
import {
  formatDateFromFirebaseTimestamp,
  replaceReferencesWithPath,
  translateAboState,
} from "../../../utils/utils";
import Filter, { FilterTypes } from "../../../components/Filter";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../../context/SessionContext";

interface IAboState {
  loading: boolean;
  abos: Array<Record<string, any>>;
  moreItemsExist: boolean;
  loadingMore: boolean;
  aboQuery: firebase.firestore.CollectionReference | firebase.firestore.Query;
}

const Abos = () => {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);

  const navigate = useNavigate();

  console.log(`MID: ${mid}`);

  const [aboState, setAboState] = useState<IAboState>({
    abos: [],
    loading: true,
    moreItemsExist: true,
    loadingMore: false,
    aboQuery: aboCollectionRef(mid || "-")
      .orderBy("createTime", "desc")
      .where(
        "profileRef",
        "==",
        firebase.firestore().doc(currentProfile?.path || "-/-")
      ),
  });

  useEffect(() => {
    if (currentProfile?.path) {
      updateAboQuery(
        aboCollectionRef(mid || "-")
          .where(
            "profileRef",
            "==",
            firebase.firestore().doc(currentProfile.path)
          )
          .orderBy("createTime", "desc")
      );
    }
  }, [currentProfile, mid]);

  useEffect(() => {
    const refreshAbos = async (
      abosParam?: firebase.firestore.QuerySnapshot
    ) => {
      let abos: firebase.firestore.QuerySnapshot;
      if (abosParam) {
        abos = abosParam;
      } else {
        abos = (await aboState.aboQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get()) as firebase.firestore.QuerySnapshot;
      }
      if (abos.empty) {
        setAboState((aboState) => {
          return {
            ...aboState,
            loading: false,
            ...(abos.size < FIREBASE_REQUEST_LIMIT && {
              moreItemsExist: false,
            }),
          };
        });
        return;
      }
      // const aboArray: Array<Record<string, any>> = [];

      // abos.forEach((abo) => {
      //   const profileRef: firebase.firestore.DocumentReference =
      //     abo.data().profileRef;
      //   profileRef.get().then((profile) => {
      //     aboArray.push({
      //       data: replaceReferencesWithPath(abo.data()) as Abo,
      //       path: abo.ref.path,
      //       id: abo.id,
      //       profile: profile.data(),
      //     });

      //   });
      // });
      const aboArray = await getAboArray(abos);

      setAboState((aboState) => {
        return {
          ...aboState,
          abos: aboArray,
          loading: false,
          ...(abos.size < FIREBASE_REQUEST_LIMIT && {
            moreItemsExist: false,
          }),
        };
      });
    };
    aboState.aboQuery.limit(FIREBASE_REQUEST_LIMIT).onSnapshot(
      (abos) => {
        refreshAbos(abos);
      },
      () => {
        setAboState((aboState) => {
          return {
            ...aboState,
            loading: false,
            moreItemsExist: false,
          };
        });
      }
    );
  }, [mid, firestore, aboState.aboQuery]);

  const updateAboQuery = (query: firebase.firestore.Query) => {
    setAboState((aboState) => {
      return {
        ...aboState,
        loading: true,
        abos: [],
        aboQuery: query,
      };
    });
  };

  const loadMoreItems = async () => {
    setAboState((aboState) => {
      return {
        ...aboState,
        loadingMore: true,
      };
    });
    const abos = await aboState.aboQuery
      .startAfter(
        createTimestampFromObject(
          aboState.abos[aboState.abos.length - 1].data.createTime
        ).toDate()
      )
      .limit(FIREBASE_REQUEST_LIMIT)
      .get();
    if (abos.empty) {
      setAboState((aboState) => {
        return { ...aboState, loading: false, loadingMore: false };
      });
      return;
    }
    const aboArray = await getAboArray(abos);
    // const aboTempArray: Array<{
    //   data: Record<string, any>;
    //   path: string;
    //   id: string;
    //   profileRef: firebase.firestore.DocumentReference;
    //   customerRef: firebase.firestore.DocumentReference;
    // }> = [];
    // const aboArray: Array<Record<string, any>> = [];

    // abos.forEach((abo) => {
    //   const data = abo.data();
    //   const profileRef: firebase.firestore.DocumentReference = data.profileRef;
    //   const customerRef: firebase.firestore.DocumentReference = data.profileRef;
    //   aboTempArray.push({
    //     data: replaceReferencesWithPath(data) as Abo,
    //     path: abo.ref.path,
    //     id: abo.id,
    //     profileRef: profileRef,
    //     customerRef: customerRef,
    //   });
    // });
    // for (const abo of aboTempArray) {
    //   const profile = (await abo.profileRef.get()).data();
    //   const customer = (await abo.customerRef.get()).data();
    //   aboArray.push({
    //     data: abo.data,
    //     path: abo.path,
    //     id: abo.id,
    //     profile: profile,
    //     customer: customer,
    //   });
    // }

    setAboState((aboState) => {
      return {
        ...aboState,
        abos: aboState.abos.concat(aboArray),
        loadingMore: false,
        loading: false,
        ...(abos.size < FIREBASE_REQUEST_LIMIT && {
          moreItemsExist: false,
        }),
      };
    });
  };

  const handleOnClick = useCallback(
    (link: string, abo?: any) =>
      navigate(link, {
        // state: { abo: abo },
      }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.ABONNEMENT}
        query={aboCollectionRef(mid || "-")}
        updateQuery={updateAboQuery}
      />
      <LoadingComponent isLoading={aboState.loading}>
        <Table
          loadMoreItems={loadMoreItems}
          moreItemsExist={aboState.moreItemsExist}
          loadingMoreItems={aboState.loadingMore}
          itemsTitle={[
            t("components.abonnements.shop_name"),
            t("components.abonnements.customer"),
            t("components.abonnements.description"),
            t("components.abonnements.mode"),
            t("components.abonnements.amount"),
            t("components.abonnements.created"),
            t("components.abonnements.state"),
          ]}
        >
          {aboState.abos.map((abo) => {
            return (
              <TableBody
                key={abo.id}
                categoryTitle={`${t("components.abonnements.abonnement")}`}
                component={abo}
                componentPath={"/abo/" + abo.id}
                isClickable={true}
                onClick={handleOnClick}
                id={abo.id}
                rows={7}
                itemsBody={[
                  {
                    title: t("components.abonnements.shop_name"),
                    value: abo?.profile?.name || "n/a",
                  },
                  {
                    title: t("components.abonnements.customer"),
                    value: abo?.customer?.name || "n/a",
                  },
                  {
                    title: t("components.abonnements.description"),
                    value: abo?.data?.description || "n/a",
                  },
                  {
                    title: t("components.abonnements.mode"),
                    value: <BadgeMode isTest={abo?.data?.test || false} />,
                  },
                  {
                    title: t("components.abonnements.amount"),
                    value: <BadgeAmount amount={abo?.data?.amount} />,
                  },
                  {
                    title: t("components.abonnements.created"),
                    value: formatDateFromFirebaseTimestamp(
                      abo?.data?.createTime
                    ),
                  },
                  {
                    title: t("components.abonnements.state"),
                    value: translateAboState(abo?.data?.status),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

const getAboArray = async (
  abos: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): Promise<Array<Record<string, any>>> => {
  const aboTempArray: Array<{
    data: Record<string, any>;
    path: string;
    id: string;
    profileRef: firebase.firestore.DocumentReference;
    customerRef: firebase.firestore.DocumentReference;
  }> = [];
  const aboArray: Array<Record<string, any>> = [];

  abos.forEach((abo) => {
    const data = abo.data();
    const profileRef: firebase.firestore.DocumentReference = data.profileRef;
    const customerRef: firebase.firestore.DocumentReference = data.customerRef;
    aboTempArray.push({
      data: replaceReferencesWithPath(data) as Abo,
      path: abo.ref.path,
      id: abo.id,
      profileRef: profileRef,
      customerRef: customerRef,
    });
  });
  for (const abo of aboTempArray) {
    const profile = (await abo.profileRef.get()).data();
    const customer = (await abo.customerRef.get()).data();
    aboArray.push({
      data: abo.data,
      path: abo.path,
      id: abo.id,
      profile: profile,
      customer: customer,
    });
  }
  return aboArray;
};

export default Abos;
