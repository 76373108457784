import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import {
  createTimestampFromObject,
  paymentCollectionRef,
} from "../../../utils/firestore";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import {
  createArrayByProperty,
  formatDateFromFirebaseTimestamp,
  translateTransactionState,
} from "../../../utils/utils";
import Filter, { FilterTypes } from "../../../components/Filter";
import { useNavigate } from "react-router-dom";
import { Payment as PaymentType } from "../../../types/Payment";
import { SessionContext } from "../../../context/SessionContext";

interface IPaymentState {
  loading: boolean;
  payments: any[];
  moreItemsExist: boolean;
  loadingMore: boolean;
  paymentQuery:
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query;
}

const Payment = () => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);
  const firestore = useFirestore();
  const navigate = useNavigate();

  const [paymentState, setPaymentState] = useState<IPaymentState>({
    loading: true,
    payments: [],
    moreItemsExist: true,
    loadingMore: false,
    paymentQuery: paymentCollectionRef(mid || "-")
      .orderBy("updateTime", "desc")
      .where(
        "profileRef",
        "==",
        firebase.firestore().doc(currentProfile?.path || "-/-")
      ),
  });

  useEffect(() => {
    if (currentProfile?.path) {
      updatePaymentQuery(
        paymentCollectionRef(mid || "-")
          .where(
            "profileRef",
            "==",
            firebase.firestore().doc(currentProfile.path)
          )
          .orderBy("updateTime", "desc")
      );
    }
  }, [currentProfile, mid]);

  useEffect(() => {
    const refreshPayments = async (
      paymentsParam?: firebase.firestore.QuerySnapshot
    ) => {
      let payments: firebase.firestore.QuerySnapshot;
      if (paymentsParam) {
        payments = paymentsParam;
      } else {
        payments = (await paymentState.paymentQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get()) as firebase.firestore.QuerySnapshot;
      }
      const paymentsArray = createArrayByProperty<PaymentType>(payments);
      setPaymentState((paymentState) => {
        return {
          ...paymentState,
          payments: paymentsArray,
          loading: false,
          ...(payments.size < FIREBASE_REQUEST_LIMIT && {
            moreItemsExist: false,
          }),
        };
      });
    };
    paymentState.paymentQuery.limit(FIREBASE_REQUEST_LIMIT).onSnapshot(
      (payments) => {
        refreshPayments(payments);
      },
      () => {
        setPaymentState((paymentState) => {
          return {
            ...paymentState,
            loading: false,
            moreItemsExist: false,
          };
        });
      }
    );
  }, [mid, firestore, paymentState.paymentQuery]);

  const updatePaymentQuery = (query: firebase.firestore.Query) => {
    setPaymentState((paymentState) => {
      return {
        ...paymentState,
        loading: true,
        payments: [],
        paymentQuery: query,
      };
    });
  };

  const loadMoreItems = async () => {
    setPaymentState((paymentState) => {
      return { ...paymentState, loadingMore: true };
    });
    if (paymentState.payments.length <= 0) {
      setPaymentState((paymentState) => {
        return { ...paymentState, loadingMore: false };
      });
      return;
    }
    const payments = await paymentState.paymentQuery
      .startAfter(
        createTimestampFromObject(
          paymentState.payments[paymentState.payments.length - 1].data
            .updateTime
        ).toDate()
      )
      .limit(FIREBASE_REQUEST_LIMIT)
      .get();
    if (payments.empty) {
      setPaymentState((paymentState) => {
        return { ...paymentState, loading: false, loadingMore: false };
      });
      return;
    }
    const paymentsArray = createArrayByProperty<PaymentType>(payments);
    setPaymentState((paymentState) => {
      return {
        ...paymentState,
        payments: paymentState.payments.concat(paymentsArray),
        loading: false,
        loadingMore: false,
        ...(payments.size < FIREBASE_REQUEST_LIMIT && {
          moreItemsExist: false,
        }),
      };
    });
  };

  const handleOnClick = useCallback(
    (link: string, payment?: any) =>
      navigate(link, {
        state: { payment: payment },
      }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.TRANSACTION}
        query={paymentCollectionRef(mid || "-")}
        updateQuery={updatePaymentQuery}
      />
      <LoadingComponent isLoading={paymentState.loading}>
        <Table
          loadMoreItems={loadMoreItems}
          moreItemsExist={paymentState.moreItemsExist}
          loadingMoreItems={paymentState.loadingMore}
          itemsTitle={[
            t("components.payments.id"),
            t("components.payments.created"),
            t("components.payments.state"),
          ]}
        >
          {paymentState.payments.map((payment) => {
            return (
              <TableBody
                key={payment.id}
                categoryTitle={`${t("components.payments.payments")}`}
                component={payment}
                componentPath={"/payment/" + payment.id}
                isClickable={true}
                onClick={handleOnClick}
                id={payment.id}
                rows={3}
                itemsBody={[
                  {
                    title: t("components.payments.id"),
                    value: payment.id,
                  },
                  {
                    title: t("components.payments.created"),
                    value: formatDateFromFirebaseTimestamp(
                      payment.data.createTime
                    ),
                  },
                  {
                    title: t("components.payments.state"),
                    value: translateTransactionState(payment.data.status),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

export default Payment;
