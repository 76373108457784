type SettingCardProps = {
  title: string;
  children: any;
};

const SettingCard = (props: SettingCardProps) => {
  return (
      <div className="w-1/2 lg:w-10/12 sm:w-full text-left my-4 sm:mx-auto">
        <p className="text-xl font-bold break-normal truncate">{props.title}</p>
        <div className="flex flex-wrap">{props.children}</div>
      </div>
  );
};

export default SettingCard;
