import { FaBan } from "react-icons/fa";

const NoEntry = () => {
  return (
    <div className="flex w-full justify-center align-center items-center">
      <FaBan className="text-xl font-lighter mr-4 inline-block " />
      <p className="text-base mr-4 inline-block ">Keine Einträge gefunden</p>
    </div>
  );
};

export default NoEntry;
