import { useContext, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { Functions } from "../../../../firebase";
import Error from "../../../../components/Error";
import { SessionContext } from "../../../../context/SessionContext";
// import { COLL_MERCHANT } from "../../../../utils/firestore";
// import firebase from "firebase/compat/app";
interface IRegisterState {
  email: string;
  password: string;
  passwordRepeat: string;
  firstname: string;
  lastname: string;
  accept: boolean;
  errorComponent: any;
}

const RegisterComponent = () => {
  const initializeMerchant = Functions().httpsCallable(
    "calls-merchant-initialize"
  );
  const refererId = new URLSearchParams(window.location.search).get('via') ;
  const [registerState, setRegisterState] = useState<IRegisterState>({
    email: "",
    password: "",
    passwordRepeat: "",
    firstname: "",
    lastname: "",
    accept: false,
    errorComponent: null,
  });

  const auth = useContext(AuthContext);
  const { refreshMerchant } = useContext(SessionContext);

  const onLoginDataChanged = (event: any, id: string) => {
    if (id === "email") {
      setRegisterState({ ...registerState, email: event.target.value });
    } else if (id === "password") {
      setRegisterState({ ...registerState, password: event.target.value });
    } else if (id === "passwordRepeat") {
      setRegisterState({
        ...registerState,
        passwordRepeat: event.target.value,
      });
    } else if (id === "firstname") {
      setRegisterState({ ...registerState, firstname: event.target.value });
    } else if (id === "lastname") {
      setRegisterState({ ...registerState, lastname: event.target.value });
    } else if (id === "accept") {
      setRegisterState({ ...registerState, accept: event.target.checked });
    } else {
      return;
    }
  };

  const register = () => {
    if (!registerState.accept) {
      setRegisterState({
        ...registerState,
        errorComponent: (
          <Error errorMessage="Bitte akzeptiere zunächst unsere Nutzungsbedingungen!" />
        ),
      });
      return;
    } else {
      if (registerState.password === registerState.passwordRepeat) {
        auth
          .signUp(registerState.email, registerState.password)
          .then((userCredential) => {
            const user = userCredential.user;
            if (user) {
              user
                .updateProfile({
                  displayName: `${registerState.firstname} ${registerState.lastname}`,
                })
                .then(() => {})
                .catch((error: any) => {
                  setRegisterState({
                    ...registerState,
                    errorComponent: <Error errorMessage={error.message} />,
                  });
                });
              initializeMerchant({
                firstname: `${registerState.firstname}`,
                lastname: `${registerState.lastname}`,
                refererId: refererId
              } ).then(() => {
                refreshMerchant();
              });
              // DB()
              //   .collection(COLL_MERCHANT)
              //   .doc(user.uid)
              //   .set({
              //     name: `${registerState.firstname} ${registerState.lastname}`,
              //     firstname: `${registerState.firstname}`,
              //     lastname: `${registerState.lastname}`,
              //     email: `${registerState.email}`,
              //     createTime: firebase.firestore.Timestamp.now(),
              //     updateTime: firebase.firestore.Timestamp.now(),
              //   });
            }
          })
          .catch((error) => {
            setRegisterState({
              ...registerState,
              errorComponent: <Error errorMessage={error.message} />,
            });
          })
          .finally(() => {
            refreshMerchant();
          });
      } else {
        setRegisterState({
          ...registerState,
          errorComponent: (
            <Error errorMessage="Passwörter müssen identisch sein" />
          ),
        });
        return;
      }
    }
  };
  return (
    <div>
      <p className=" mt-16 text-3xl mb-32 font-bold">
        Biete deinen Kunden automatisierte wiederkehrende Zahlungen
      </p>
      {registerState.errorComponent}
      <div className="block w-full my-2 box-border text-center">
        <div className="flex justify-between">
          <input
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                register();
              }
            }}
            onChange={(event) => onLoginDataChanged(event, "firstname")}
            type="text"
            id="firstname"
            placeholder="Vorname"
            className=" rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
          />
          <input
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                register();
              }
            }}
            onChange={(event) => onLoginDataChanged(event, "lastname")}
            type="text"
            id="lastname"
            placeholder="Name"
            className="  rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
          />
        </div>
        <input
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              register();
            }
          }}
          onChange={(event) => onLoginDataChanged(event, "email")}
          type="email"
          id="email"
          placeholder="E-Mail"
          className="block mx-auto rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
        />

        <input
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              register();
            }
          }}
          onChange={(event) => onLoginDataChanged(event, "password")}
          type="password"
          id="password"
          placeholder="Passwort"
          className="block mx-auto rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
        />
        <input
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              register();
            }
          }}
          onChange={(event) => onLoginDataChanged(event, "passwordRepeat")}
          type="password"
          id="passwordRepeat"
          placeholder="Passwort wiederholen"
          className="block mx-auto rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
        />
        <div className="w-full mt-4">
          <input
            onChange={(event) => onLoginDataChanged(event, "accept")}
            type="checkbox"
            id="accept"
            className="text-base mr-4 my-2 box-border border-black border text-black bg-black"
          />
          <label htmlFor="accept">
            Mit dem Klick auf "Jetzt starten" erklärst du, dass du unsere{" "}
            <a
              href="https://abocloud.io/nutzungsbedingungen/"
              className="underline"
            >
              {" "}
              Nutzungsbedingungen
            </a>{" "}
            und
            <a href="https://abocloud.io/datenschutz" className="underline">
              {" "}
              Datenschutzbestimmungen
            </a>{" "}
            gelesen und akzeptiert hast.
          </label>
        </div>
        <input
          onClick={() => register()}
          type="submit"
          id="register"
          value="jetzt starten"
          className="block mx-auto rounded-lg p-4 w-full text-base my-8 box-border text-white bg-black cursor-pointer"
        />
      </div>
    </div>
  );
};
export default RegisterComponent;
