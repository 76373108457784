import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import { aboCollectionGroupRef } from "../../../utils/firestore";
import { Abo as AboType } from "../../../types/Abo";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import BadgeAmount from "../../../components/Badge/BadgeAmount";
import BadgeMode from "../../../components/Badge/BadgeMode";
import firebase from "firebase/compat/app";
import {
  formatDateFromFirebaseTimestamp,
  replaceReferencesWithPath,
  translateAboState,
} from "../../../utils/utils";
import Filter, { FilterTypes } from "../../../components/Filter";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom-v5-compat";
//const queryString = require("query-string");

// type AboProps = {
//   history: any;
//   location: any;
//   match: any;
// };

interface IAboState {
  loading: boolean;
  abos: any[];
  moreItemsExist: boolean;
  loadingMore: boolean;
  aboQuery: firebase.firestore.CollectionReference | firebase.firestore.Query;
}

const Abo = () => {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [aboState, setAboState] = useState<IAboState>({
    abos: [],
    loading: true,
    moreItemsExist: true,
    loadingMore: false,
    aboQuery: aboCollectionGroupRef().orderBy("createTime", "desc"),
  });
  //const query = queryString.parse(props.location.search);

  useEffect(() => {
    const refreshAbos = async (
      abosParam?: firebase.firestore.QuerySnapshot
    ) => {
      let abos: firebase.firestore.QuerySnapshot;
      if (abosParam) {
        abos = abosParam;
      } else {
        abos = (await aboState.aboQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get()) as firebase.firestore.QuerySnapshot;
      }
      if (abos.empty) {
        setAboState((aboState) => {
          return {
            ...aboState,
            loading: false,
            ...(abos.size < FIREBASE_REQUEST_LIMIT && {
              moreItemsExist: false,
            }),
          };
        });
        return;
      }
      let aboArray: any[] = [];
      abos.forEach((abo) => {
        const profileRef: firebase.firestore.DocumentReference =
          abo.data().profileRef;
        const merchantid = abo.ref.parent.parent?.id;
        profileRef.get().then((profile) => {
          aboArray.push({
            data: replaceReferencesWithPath(abo.data()) as AboType,
            path: abo.ref.path,
            id: abo.id,
            merchantid: merchantid,
            profile: profile.data(),
          });
          setAboState((aboState) => {
            return {
              ...aboState,
              abos: aboArray,
              loading: false,
              ...(abos.size < FIREBASE_REQUEST_LIMIT && {
                moreItemsExist: false,
              }),
            };
          });
        });
      });
    };
    aboState.aboQuery.limit(FIREBASE_REQUEST_LIMIT).onSnapshot((abos) => {
      refreshAbos(abos);
    });
  }, [auth.authContextState.user, firestore, aboState.aboQuery]);

  const updateAboQuery = (query: firebase.firestore.Query) => {
    setAboState((aboState) => {
      return {
        ...aboState,
        loading: true,
        abos: [],
        aboQuery: query,
      };
    });
  };

  const loadMoreItems = async () => {
    setAboState((aboState) => {
      return {
        ...aboState,
        loadingMore: true,
      };
    });
    const abos = await aboState.aboQuery
      .startAfter(aboState.abos[aboState.abos.length - 1].data.created)
      .limit(FIREBASE_REQUEST_LIMIT)
      .get();
    let aboArray: any[] = [];
    abos.forEach((abo) => {
      const profileRef: firebase.firestore.DocumentReference =
        abo.data().profileRef;
      const merchantid = abo.ref.parent.parent?.id;
      profileRef.get().then((profile) => {
        aboArray.push({
          data: replaceReferencesWithPath(abo.data()) as AboType,
          path: abo.ref.path,
          id: abo.id,
          merchantid: merchantid,
          profile: profile.data(),
        });
        setAboState((aboState) => {
          return {
            ...aboState,
            abos: aboArray,
            loading: false,
            ...(abos.size < FIREBASE_REQUEST_LIMIT && {
              moreItemsExist: false,
            }),
          };
        });
      });
    });
  };

  const handleOnClick = useCallback(
    (link: string, abo?: any) => navigate(link, { state: { abo: abo } }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.ABONNEMENT}
        query={aboCollectionGroupRef()}
        updateQuery={updateAboQuery}
      />
      <LoadingComponent isLoading={aboState.loading}>
        <Table
          loadMoreItems={loadMoreItems}
          moreItemsExist={aboState.moreItemsExist}
          loadingMoreItems={aboState.loadingMore}
          itemsTitle={[
            t("components.abonnements.shop_name"),
            t("components.abonnements.description"),
            t("components.abonnements.mode"),
            t("components.abonnements.amount"),
            t("components.abonnements.created"),
            t("components.abonnements.state"),
          ]}
        >
          {aboState.abos.map((abo) => {
            return (
              <TableBody
                key={abo.id}
                categoryTitle={`${t("components.abonnements.abonnement")}`}
                component={abo}
                componentPath={"aabos/" + abo.merchantid + "/" + abo.id}
                isClickable={true}
                onClick={handleOnClick}
                id={abo.id}
                rows={6}
                itemsBody={[
                  {
                    title: t("components.abonnements.shop_name"),
                    value: abo.profile.name,
                  },
                  {
                    title: t("components.abonnements.description"),
                    value: abo.data.description,
                  },
                  {
                    title: t("components.abonnements.mode"),
                    value: <BadgeMode isTest={abo.data.test} />,
                  },
                  {
                    title: t("components.abonnements.amount"),
                    value: <BadgeAmount amount={abo.data.amount} />,
                  },
                  {
                    title: t("components.abonnements.created"),
                    value: formatDateFromFirebaseTimestamp(abo.data.createTime),
                  },
                  {
                    title: t("components.abonnements.state"),
                    value: translateAboState(abo.data.status),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

export default Abo;
