import { Functions } from "../../../firebase";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import TokenCard from "./TokenCard";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { useFirestore } from "react-redux-firebase";
import { allKeys, keyCollectionRef } from "../../../utils/firestore";
// import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import { hasTariff } from "../../../utils/setup";
import Modal from "../../../components/Modal";
import SettingsWrapper from "../../../components/SettingsWrapper";
import { useNavigate } from "react-router-dom";
import { ProfileStatus } from "../../../types/Profile";
import { SessionContext } from "../../../context/SessionContext";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";

const Token = () => {
  const { mid } = useContext(AuthContext);
  const { authorizations } = useContext(SessionContext);
  const [canAddProfile, setCanAddProfile] = useState<boolean>(false);
  const [newProfileModal, setNewProfileModal] = useState<boolean>(false);
  const [tariffModal, setTariffModal] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<Array<Record<string, any>>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [profileName, setProfileName] = useState<string | null>(null);
  const [profileUrl, setProfileUrl] = useState<string | null>(null);
  const [syncProfiles, setSyncProfiles] = useState<boolean>(true);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);

  const { t } = useTranslation();
  const firestore = useFirestore();
  const navigate = useNavigate();

  const deleteToken = async (id: string) => {
    setLoading(true);
    const key = await keyCollectionRef(mid || "-")
      .where("key", "==", id)
      .get();
    key.docs[0].ref.delete();
    setSyncProfiles(true);
  };

  useEffect(() => {
    setCanAddProfile(
      authorizations["general.profile"] === -1 ||
        profiles.length < authorizations["general.profile"]
    );
  }, [profiles]);

  useEffect(() => {
    hasTariff(mid || "-").then((hasTariff) => {
      if (!hasTariff) {
        openModal();
      }
    });
    if (syncProfiles === true) {
      setLoading(true);
      setSyncProfiles(false);
      allKeys(mid || "-")
        .then((keys) => {
          let keyArray: Array<Record<string, any>> = [];
          keys.forEach((key) => {
            keyArray.push({ ...key.data() });
          });
          setProfiles(keyArray);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [mid, firestore, syncProfiles]);

  const onTokenDataChanged = (event: any, id: string) => {
    if (id === "name") {
      if (event.target.value.trim().length > 0) {
        setProfileName(event.target.value);
      } else {
        setProfileName(null);
      }
    } else if (id === "url") {
      if (event.target.value.trim().length > 0) {
        setProfileUrl(event.target.value);
      } else {
        setProfileUrl(null);
      }
    } else {
      return;
    }
  };

  const openProfileModal = () => {
    setNewProfileModal(true);
  };
  const closeProfileModal = () => {
    setNewProfileModal(false);
  };

  const openModal = () => {
    setTariffModal(true);
  };
  const closeModal = () => {
    setTariffModal(false);
  };

  const redirectToTariff = () => {
    navigate("/tariff");
  };

  const generateToken = () => {
    setLoading(true);
    setGeneratingToken(true);
    const data = { name: profileName, url: profileUrl };
    const addApiKey = Functions().httpsCallable("calls-merchant-createApikey");
    addApiKey(data)
      .then(() => {
        setProfileName(null);
        setProfileUrl(null);
        setSyncProfiles(true);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        closeProfileModal();
        setGeneratingToken(false);
      });
  };

  return (
    <>
      <SettingsWrapper title={t("components.settings.profiles.myprofiles")}>
        {canAddProfile && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <input
              onClick={openProfileModal}
              type="submit"
              id="choose"
              disabled={loading}
              value={`${t("components.settings.profiles.add")}`}
              style={{
                width: 300,
                backgroundColor: "#000",
                borderRadius: 10,
                padding: 10,
                marginTop: 30,
                cursor: "pointer",
                color: "#FFF",
              }}
            />
          </div>
        )}
        <Modal
          isOpen={newProfileModal}
          onRequestClose={closeProfileModal}
          title={`${t("components.settings.profiles.add_new_service")}`}
          body={
            generatingToken === true ? (
              <div
                style={{
                  minWidth: `100%`,
                  width: "100%",
                  margin: 30,
                  marginTop: 50,
                  marginBottom: 50,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingComponent isLoading />
              </div>
            ) : (
              <div className="w-full mb-4 bg-primry-light">
                <input
                  onChange={(event) => onTokenDataChanged(event, "name")}
                  type="text"
                  value={profileName || ""}
                  id="name"
                  placeholder="Name"
                  className="w-full rounded-lg text-base  p-2 mt-2 box-border border-black border text-black"
                />
                <input
                  onChange={(event) => onTokenDataChanged(event, "url")}
                  type="text"
                  value={profileUrl || ""}
                  id="url"
                  placeholder="Domain"
                  className="w-full rounded-lg text-base p-2 mt-2 box-border border-black border text-black"
                />
              </div>
            )
          }
          footer={
            <div>
              <Button
                title={t("actions.close")}
                disabled={generatingToken === true}
                onClickListener={() => closeProfileModal()}
              />
              <Button
                title={`${t("components.settings.profiles.create")}`}
                disabled={generatingToken === true}
                onClickListener={() => generateToken()}
              />
            </div>
          }
        />
        <Modal
          isOpen={tariffModal}
          onRequestClose={closeModal}
          title={t("notifications.no_tariff.title")}
          body={
            <p
              style={{
                maxWidth: 600,
                width: "100%",
                textAlign: "left",
                float: "left",
                wordBreak: "normal",
                wordWrap: "break-word",
              }}
            >
              {t("notifications.no_tariff.text")}
            </p>
          }
          footer={
            <>
              <Button
                title={t("notifications.no_tariff.button")}
                onClickListener={() => redirectToTariff()}
              />
              <Button
                title={t("actions.close")}
                onClickListener={() => closeModal()}
              />
            </>
          }
        />
        {/* <LoadingComponent isLoading={loading}> */}
        {profiles.map((value) => {
          return (
            <TokenCard
              key={`${value.name}_token_${value.id}`}
              title={value.name}
              url={value.url}
              apikey={value.key}
              actions={
                // <Button
                //   color="bg-red-900"
                //   onClickListener={() => deleteToken(value.key)}
                //   title={t("actions.delete")}
                // />
                <div
                  className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-red-900"
                  onClick={() => deleteToken(value.key)}
                >
                  {t("actions.delete")}
                </div>
              }
            >
              <p
                className={
                  value.status === ProfileStatus.Requested
                    ? "block font-bold text-red-900"
                    : "hidden"
                }
              >
                {t("components.settings.token.wait_for_unlock")}
              </p>
            </TokenCard>
          );
        })}
        {/* </LoadingComponent> */}
      </SettingsWrapper>
    </>
  );
};

export default Token;
