import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import { AuthContext } from "../../../../../components/Context/AuthContext";
import Dropdown, { Option } from "../../../../../components/Dropdown";
import { Functions } from "../../../../../firebase";
import Loading from "../../../../../components/Loading";
import { useFirestore } from "react-redux-firebase";
import { COLL_MERCHANT } from "../../../../../utils/firestore";

interface IMollieChooseProfileContentState {
  loading: boolean;
  profilesCount: number;
  selectedProfile: string;
  profiles: any[];
}

type MollieChooseProfileContentProps = {
  storeProfile: (profile: string) => void;
};
const MollieChooseProfileContent = (props: MollieChooseProfileContentProps) => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const [fetchingProfiles, setFetchingProfiles] = useState<boolean>(false);
  const [mollieChooseProfileState, setMollieChooseProfileState] =
    useState<IMollieChooseProfileContentState>({
      loading: true,
      profilesCount: 0,
      selectedProfile: "init",
      profiles: [],
    });

  const firestore = useFirestore();

  const onProfilSelectChanged = (event: any, id: string) => {
    if (event.target.value.length > 0) {
      setMollieChooseProfileState((mollieChooseProfileState) => {
        return {
          ...mollieChooseProfileState,
          selectedProfile: event.target.value,
        };
      });
    }
  };

  const storeProfile = () => {
    if (
      mollieChooseProfileState.selectedProfile.length > 0 &&
      mollieChooseProfileState.selectedProfile !== "init"
    ) {
      props.storeProfile(mollieChooseProfileState.selectedProfile);
    }
  };

  useEffect(() => {
    if (mid) {
      firestore
        .collection(COLL_MERCHANT)
        .doc(mid || "-")
        .get()
        .then((result) => {
          if (fetchingProfiles === true) return;
          if (result.exists) {
            if (
              result?.data()?.paymentprovider &&
              result?.data()?.paymentprovider?.mollie &&
              result?.data()?.paymentprovider?.mollie?.access_token
            ) {
              setFetchingProfiles(true);
              Functions()
                .httpsCallable("calls-mollie-getProfiles")()
                .then((value) => {
                  console.log(value);
                  setMollieChooseProfileState((mollieChooseProfileState) => {
                    return {
                      ...mollieChooseProfileState,
                      loading: false,
                      profilesCount: value.data.count,
                      profiles: [...value.data.profiles],
                    };
                  });
                })
                .finally(() => {
                  setFetchingProfiles(false);
                });
            }
          } else {
            setMollieChooseProfileState((mollieChooseProfileState) => {
              return {
                ...mollieChooseProfileState,
                loading: false,
              };
            });
          }
        });
    }
  }, [mid, firestore]);

  if (mollieChooseProfileState.loading) {
    return <Loading />;
  } else {
    // if (mollieChooseProfileState.profilesCount === 1) {
    //   mollieChooseProfileState.profiles.map((elem) => {
    //     setMollieChooseProfileState((mollieChooseProfileState) => {
    //       return {
    //         ...mollieChooseProfileState,
    //         selectedProfile: elem.id,
    //       };
    //     });
    //     return true;
    //   });

    //   return (
    //     <>
    //       <p className="text-left m-4 text-xl">
    //         {t("components.settings.payments.mollie_found_one_profile")}
    //       </p>
    //       <Button
    //         title={t("actions.choose")}
    //         onClickListener={() => storeProfile()}
    //       />
    //     </>
    //   );
    /*} else*/ if (mollieChooseProfileState.profilesCount > 0) {
      let options: Option[] = [
        { id: "init", value: "Mollie-Profile", name: "Mollie-Profile" },
      ];
      mollieChooseProfileState.profiles.map((elem) => {
        const option: Option = {
          id: elem.id,
          value: `${elem.id}`,
          name: `${elem.name} (${elem.id})`,
        };
        options.push(option);
        return true;
      });
      return (
        <>
          <p className="text-left m-4 text">
            {t("components.settings.payments.mollie_choose_profile")}
          </p>
          <div className="w-1/2 md:w-full md:mx-2 m-auto">
            <Dropdown
              options={options}
              id="profile"
              className="m-auto"
              onChangeListener={onProfilSelectChanged}
            />
          </div>
          <br></br>
          <Button
            title={t("actions.choose")}
            onClickListener={() => storeProfile()}
          />
        </>
      );
    } else {
      return (
        <p className="text-left m-4 text-xl">
          {t("components.settings.payments.mollie_no_profiles_found")}
        </p>
      );
    }
  }
};
export default MollieChooseProfileContent;
