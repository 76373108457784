import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { useTranslation } from "react-i18next";
import { addTextToChart } from "./helper";
import useCustomerQuota from "../../../../hooks/quoata/useCustomerQuota";
import unlimited_image from "./../../../../assets/abocloud_unbegrenzt.svg";

// interface IContingentChartCustomerState {
//   data: ContingentForMonth;
//   loading: boolean;
// }

const ContingentChartCustomer = () => {
  const { t } = useTranslation();
  // const {mid} = useContext(AuthContext);
  const customerQuota = useCustomerQuota();
  // const [contingentChartCustomerState, setContingentChartCustomerState] =
  //   useState<IContingentChartCustomerState>({
  //     data: { contingent: 0, current: 0, percentReached: 0, percentLeft: 100 },
  //     loading: true,
  //   });

  // useEffect(() => {
  //   const refreshData = async () => {
  //     const data = await getCustomerContingent(mid);
  //     setContingentChartCustomerState((contingentChartCustomerState) => {
  //       return {
  //         ...contingentChartCustomerState,
  //         data: data,
  //         loading: false,
  //       };
  //     });
  //   };
  //   /*
  //   customerCollectionRef(auth.authContextState.user.uid).onSnapshot(() => {
  //     refreshData();
  //   });*/
  //   refreshData();
  // }, [auth.authContextState.user.uid]);

  return (
    <div className="rounded rounded-xl bg-white w-1/4 2xl:w-1/2 lg:w-3/4 flex flex-wrap p-4">
      {/* {contingentChartCustomerState.loading ? (
        <Loading />
      ) : ( */}
      <>
        <div className="w-2/6">
          {customerQuota.quoata === -1 ? (
            <img
              className=""
              src={unlimited_image}
              alt="Logo"
              style={{
                width: "100%",
                padding: 10,
              }}
            />
          ) : (
            <Doughnut
              data={{
                datasets: [
                  {
                    borderColor: "white",
                    borderWidth: 0,
                    data: [customerQuota.percent, customerQuota.percentLeft],
                    backgroundColor:
                      customerQuota.percent > 90
                        ? ["rgb(166, 0, 0)", "rgb(235, 235, 235)"]
                        : customerQuota.percent > 70
                        ? ["rgb(201, 83, 4)", "rgb(235, 235, 235)"]
                        : ["rgb(0, 0, 0)", "rgb(235, 235, 235)"],
                  },
                ],
              }}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
                events: [],
              }}
              plugins={[
                {
                  id: "textInMiddle",
                  beforeDraw: (chart) => {
                    //@ts-ignore
                    addTextToChart(chart);
                  },
                  afterDraw: (chart) => {
                    //@ts-ignore
                    addTextToChart(chart);
                  },
                },
              ]}
            />
          )}
        </div>
        <div className="w-3/6">
          <p className="font-bold text-xl">
            {t("components.dashboard.customer")}
          </p>
          <div className="flex flex-wrap gap-2 m-4 justify-center text-center">
            <p className="w-4/6 text-sm font-thin">
              {t("components.dashboard.included_tariff")}
            </p>
            <p className="w-1/6 text-sm font-bold">
              {/* {contingentChartCustomerState.data.contingent} */}
              {customerQuota.quoata === -1
                ? t("components.dashboard.unlimited")
                : customerQuota.quoata}
            </p>
            <p className="w-4/6 text-sm font-thin">
              {t("components.dashboard.current")}
            </p>
            <p className="w-1/6 text-sm font-bold">
              {/* {contingentChartCustomerState.data.current} */}
              {customerQuota.current}
            </p>
          </div>
        </div>
      </>
    </div>
  );
};

export default ContingentChartCustomer;
