import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../components/Context/AuthContext";
import SettingCard from "../SettingCard";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";

interface IProfileSettingsState {
  name: string;
  email: string;
  password: string;
  error: string | null;
  modalIsOpen: boolean;
}

const ProfileSettings = forwardRef((props, ref) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    storeProfileData() {
      auth.updateProfileName(profileSettingState.name);
      if (profileSettingState.email !== auth.authContextState.user.email) {
        openModal();
      }
    },
  }));

  useEffect(() => {
    setProfileSettingState((profileSettingState) => {
      return {
        ...profileSettingState,
        name: auth.authContextState.user.displayName,
        email: auth.authContextState.user.email,
      };
    });
  }, [
    auth.authContextState.user.displayName,
    auth.authContextState.user.email,
  ]);

  const [profileSettingState, setProfileSettingState] =
    useState<IProfileSettingsState>({
      name: "",
      email: "",
      password: "",
      error: null,
      modalIsOpen: false,
    });
  const reauthenticateWithCredential = () => {
    auth
      .reauthenticateWithCredential(
        auth.authContextState.user.email,
        profileSettingState.password
      )
      ?.then(() => {
        auth.updateProfileEmail(profileSettingState.email);
        closeModal();
        setProfileSettingState({
          ...profileSettingState,
          password: "",
          error: null,
        });
        closeModal();
      })
      ?.catch((e) => {
        setProfileSettingState({
          ...profileSettingState,
          error: e.message,
          password: "",
        });
      });
  };

  const onProfileDataChanged = (event: any, id: string) => {
    if (id === "name") {
      setProfileSettingState({
        ...profileSettingState,
        name: event.target.value,
      });
    } else if (id === "email") {
      setProfileSettingState({
        ...profileSettingState,
        email: event.target.value,
      });
    } else if (id === "password") {
      setProfileSettingState({
        ...profileSettingState,
        password: event.target.value,
      });
    }
  };
  const openModal = () => {
    setProfileSettingState((profileSettingState) => {
      return {
        ...profileSettingState,
        modalIsOpen: true,
      };
    });
  };
  const closeModal = () => {
    setProfileSettingState((profileSettingState) => {
      return {
        ...profileSettingState,
        modalIsOpen: false,
      };
    });
  };
  return (
    <>
      <SettingCard title={t("components.settings.account.setting_profile")}>
        <div className="flex flex-wrap justify-between ">
          <input
            onChange={(event) => onProfileDataChanged(event, "name")}
            type="text"
            required={true}
            value={profileSettingState.name}
            id="name"
            placeholder={`${t("components.settings.account.name")}`}
            className="flex rounded-lg text-base w-4/9 p-2 mt-2 box-border lg:w-full lg:mx-auto border-black border text-black"
          />
          <input
            onChange={(event) => onProfileDataChanged(event, "email")}
            type="text"
            required={true}
            value={profileSettingState.email}
            id="email"
            placeholder={`${t("components.settings.account.email")}`}
            className="flex rounded-lg text-base w-4/9 p-2 mt-2 lg:w-full box-border border-black border text-black"
          />
        </div>
      </SettingCard>
      <Modal
        isOpen={profileSettingState.modalIsOpen}
        onRequestClose={closeModal}
        title={t("components.settings.account.reauthenticate")}
        body={
          <div className="flex flex-wrap justify-start text-left">
            <p className="">
              {t("components.settings.account.reauthenticate_message")}
            </p>
            <input
              onChange={(event) => onProfileDataChanged(event, "password")}
              type="password"
              required={true}
              id="password"
              placeholder={`${t("components.settings.account.password")}`}
              className="  rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
            />
            {profileSettingState.error !== null ? (
              <p className="text-sm text-thin text-error w-full my-1">
                {profileSettingState.error}
              </p>
            ) : (
              <></>
            )}
          </div>
        }
        footer={
          <Button
            title={t("components.settings.account.update_email")}
            onClickListener={reauthenticateWithCredential}
          />
        }
      />
    </>
  );
});

export default ProfileSettings;
