import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";

const SuccessModal = ({ close }: { close: () => void }) => {
  return (
    <div
      onClick={/*closeScreen*/ () => {}}
      className="block h-screen w-auto z-50 inset-0 fixed "
      style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
    >
      <div className="flex w-full h-screen justify-center content-center items-center fixed ">
        <div
          className="flex justify-start flex-wrap flex-col rounded rounded-xl text-black p-5 m-2 self-center w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-full blur_effect"
          style={{
            minHeight: 370,
            height: 370,
            backgroundColor: "rgba(255,255,255,0.6)",
          }}
        >
          <FontAwesomeIcon
            icon={"xmark-circle"}
            size="2x"
            color={"#000"}
            onClick={close}
            style={{
              marginTop: 0,
              top: 10,
              right: 10,
              position: "absolute",
              cursor: "pointer",
            }}
          />
          <FontAwesomeIcon
            icon={"check-circle"}
            size="10x"
            color={"#33B100"}
            style={{ marginTop: 30 }}
          />
          <p
            style={{
              color: "#33B100",
              fontSize: 30,
              fontWeight: "bold",
              marginTop: 30,
            }}
          >{`erfolgreich abgeschlossen!`}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
