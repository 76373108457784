import { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { useFirestore } from "react-redux-firebase";
import { COLL_MERCHANT, COLL_TARIFF } from "../../../../utils/firestore";
import { AuthContext } from "../../../Context/AuthContext";
import { Grid } from "@mui/material";
import {
  getCountryCodeFromId,
  getCountryNameFromId,
} from "../../../../utils/sevdesk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../../../locales/i18n";
import { calculateMwSt } from "../../../../utils/currencies";
import { useTranslation } from "react-i18next";
import { Functions } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Loading";

const Summary = ({ previousStep }: { previousStep: () => void }) => {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const createBooking = Functions().httpsCallable(
    "management-calls-booking-create"
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedTariff, selectTariff, refreshMerchant } =
    useContext(SessionContext);
  const { mid } = useContext(AuthContext);
  const [tariff, setTariff] = useState<Record<string, any> | null>(null);
  const [companyData, setCompanyData] = useState<Record<string, any> | null>(
    null
  );
  const currentLanguage = i18n.language;
  const tariffInfo: Array<string> = tariff?.details[currentLanguage] || [];

  const changeTariff = () => {
    selectTariff(null);
    previousStep();
  };

  const finalizeOnboarding = () => {
    setLoading(true);
    if (!mid) return;
    firestore
      .collection(COLL_MERCHANT)
      .doc(mid)
      .set({ initialized: true }, { merge: true })
      .then(() => {
        if (tariff && tariff.canBeBooked === true) {
          createBooking({ tid: tariff.id })
            .then((res) => {
              if (res.data.success === true) {
                window.location.href = res.data.checkout;
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            })
            .finally(() => {});
        } else {
          navigate("/settings/tariff?initialized=true");
          refreshMerchant();
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => selectTariff(null));
  };

  useEffect(() => {
    if (selectedTariff !== null) {
      firestore
        .collection(COLL_TARIFF)
        .doc(selectedTariff)
        .get()
        .then((tariffDoc) => {
          setTariff({ ...tariffDoc.data() });
        });
    }
  }, [selectedTariff, firestore]);

  useEffect(() => {
    if (mid !== null) {
      firestore
        .collection(COLL_MERCHANT)
        .doc(mid)
        .get()
        .then((merchantDoc) => {
          setCompanyData({ ...merchantDoc.data()?.company });
        });
    }
  }, [mid, firestore]);

  if (companyData === null || tariff === null) {
    return null;
  }

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Grid
              container
              spacing={4}
              alignItems="flex-start"
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              style={{ marginTop: 30, width: "70%" }}
            >
              <Grid item xs={5}>
                <p style={{ textAlign: "left", fontWeight: "bold" }}>{`${t(
                  "components.onboarding.summary.client"
                )}:`}</p>
              </Grid>
              <Grid item xs={7}>
                <Grid
                  container
                  spacing={0}
                  alignItems="flex-start"
                  justifyContent={"flex-start"}
                  alignContent={"flex-start"}
                >
                  <Grid item xs={12}>
                    <p style={{ textAlign: "left" }}>
                      {companyData.organisation}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p
                      style={{ textAlign: "left" }}
                    >{`${companyData.firstname} ${companyData.lastname}`}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p
                      style={{ textAlign: "left" }}
                    >{`${companyData.street} ${companyData.number}`}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p
                      style={{ textAlign: "left" }}
                    >{`${companyData.zip} ${companyData.city}`}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p style={{ textAlign: "left" }}>{`${getCountryNameFromId(
                      +companyData.country
                    )}`}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <p style={{ textAlign: "left", fontWeight: "bold" }}>{`${t(
                  "components.onboarding.summary.tariff"
                )}:`}</p>
              </Grid>
              <Grid item xs={7}>
                <Grid
                  container
                  spacing={0}
                  display={"flex"}
                  alignItems="flex-start"
                  justifyContent={"flex-start"}
                  alignContent={"flex-start"}
                >
                  <Grid item xs={12}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: 18,
                        color: tariff.color,
                        marginBottom: 15,
                      }}
                    >
                      {tariff.name}
                    </p>
                  </Grid>
                  {tariffInfo.map((info, index) => {
                    return (
                      <>
                        <Grid item xs={12} display={"flex"}>
                          <FontAwesomeIcon
                            icon={"check-circle"}
                            style={{ textAlign: "left" }}
                            size="lg"
                            color={tariff.color}
                          />
                          <p
                            style={{
                              fontWeight: "normal",
                              marginLeft: 10,
                              fontSize: 14,
                              textAlign: "left",
                            }}
                          >
                            {info}
                          </p>
                        </Grid>
                        {index < tariffInfo.length - 1 && (
                          <Grid item xs={12} key={`${index}_${info}_divider`}>
                            <Divider />
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <p style={{ textAlign: "left", fontWeight: "bold" }}>{`${t(
                  "components.onboarding.summary.cost"
                )}:`}</p>
              </Grid>
              <Grid item xs={7}>
                <Grid
                  container
                  spacing={0}
                  alignItems="flex-start"
                  justifyContent={"flex-start"}
                  alignContent={"flex-start"}
                >
                  <Grid item xs={12}>
                    <p style={{ textAlign: "left" }}>
                      {tariff.interval === "12 months"
                        ? `${t(
                            "components.onboarding.summary.recurring_yearly"
                          )}`
                        : `${t(
                            "components.onboarding.summary.recurring_monthly"
                          )}`}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="flex-start"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                    >
                      <Grid item xs={3}>
                        <p style={{ textAlign: "left" }}>{`${t(
                          "components.onboarding.summary.price_netto"
                        )}`}</p>
                      </Grid>
                      <Grid item xs={9}>
                        <p
                          style={{ textAlign: "left" }}
                        >{`${tariff.price.value}€`}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="flex-start"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                    >
                      <Grid item xs={3}>
                        <p style={{ textAlign: "left" }}>{`${t(
                          "components.onboarding.summary.price_vat"
                        )}`}</p>
                      </Grid>
                      <Grid item xs={9}>
                        <p style={{ textAlign: "left" }}>{`${
                          calculateMwSt(
                            tariff.price,
                            getCountryCodeFromId(companyData.country)
                          ).mwst.value
                        }€`}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="flex-start"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                    >
                      <Grid item xs={3}>
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 16,
                          }}
                        >
                          {tariff.interval === "12 months"
                            ? `${t(
                                "components.onboarding.summary.price_total_year"
                              )}`
                            : `${t(
                                "components.onboarding.summary.price_total_month"
                              )}`}
                        </p>
                      </Grid>
                      <Grid item xs={9}>
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 16,
                          }}
                        >{`${
                          calculateMwSt(
                            tariff.price,
                            getCountryCodeFromId(companyData.country)
                          ).totalAmount.value
                        }€`}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <input
              onClick={changeTariff}
              type="submit"
              id="choose"
              disabled={false}
              value={`${t("components.onboarding.summary.change_tariff")}`}
              style={{
                width: 200,
                borderColor: "#000",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
                color: "#000",
              }}
            />
            <input
              onClick={finalizeOnboarding}
              type="submit"
              id="choose"
              disabled={false}
              value={`${t("components.onboarding.summary.submit")}`}
              style={{
                width: 300,
                backgroundColor: "#000",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
                color: "#FFF",
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const Divider = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        padding: 3,
        marginTop: 6,
        marginBottom: 6,
      }}
    >
      <div
        style={{
          width: "90%",
          height: 1,
          borderColor: "#CCC",
          backgroundColor: "#CCC",
        }}
      />
    </div>
  );
};

export default Summary;
