import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { FaInfoCircle } from "react-icons/fa";
import { useFirestore } from "react-redux-firebase";
import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import { AuthContext } from "../../../components/Context/AuthContext";
// import Dropdown from "../../../components/Dropdown";
import Loading from "../../../components/Loading";
// import { Functions } from "../../../firebase";
import Banner from "../../../components/Banner";
// import { COLL_MERCHANT } from "../../../utils/firestore";
import SettingsWrapper from "../../../components/SettingsWrapper";
import CompanyDataForm from "../../../components/Form/CompanyData";

// interface ICustomerDataState {
//   firstname: string;
//   lastname: string;
//   organisation: string;
//   email: string;
//   street: string;
//   number: string;
//   zip: string;
//   city: string;
//   country: number | null;
//   countries: any[];
//   exist: boolean;
//   storing: boolean;
//   stored: boolean;
//   error: boolean;
//   loading: boolean;
// }

const CustomerData = () => {
  const firestore = useFirestore();

  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);

  // const [customerDataState, setCustomerDataState] =
  //   useState<ICustomerDataState>({
  //     firstname: "",
  //     lastname: "",
  //     organisation: "",
  //     email: "",
  //     street: "",
  //     number: "",
  //     zip: "",
  //     city: "",
  //     country: null,
  //     countries: ["Deutschland"],
  //     exist: true,
  //     storing: false,
  //     stored: false,
  //     error: false,
  //     loading: true,
  //   });

  useEffect(() => {
    // setCustomerDataState((customerDataState) => {
    //   return {
    //     ...customerDataState,
    //     loading: true,
    //   };
    // });
    // const getStaticCountrys = Functions().httpsCallable(
    //   "calls-sevdesk-getStaticCountry"
    // );
    // getStaticCountrys().then((value) => {
    //   setCustomerDataState((customerDataState) => {
    //     return {
    //       ...customerDataState,
    //       countries: value.data.countries.objects,
    //       loading: false,
    //     };
    //   });
    // });
    // firestore
    //   .collection(COLL_MERCHANT)
    //   .doc(mid || "-")
    //   .get()
    //   .then((merchant) => {
    //     if (merchant.data()?.customerdata) {
    //       setCustomerDataState((customerDataState) => {
    //         return {
    //           ...customerDataState,
    //           firstname: merchant.data()?.customerdata.firstname,
    //           lastname: merchant.data()?.customerdata.lastname,
    //           organisation: merchant.data()?.customerdata.organisation,
    //           email: merchant.data()?.customerdata.email,
    //           street: merchant.data()?.customerdata.street,
    //           number: merchant.data()?.customerdata.number,
    //           zip: merchant.data()?.customerdata.zip,
    //           city: merchant.data()?.customerdata.city,
    //           country: merchant.data()?.customerdata.country,
    //           exist: true,
    //         };
    //       });
    //     } else {
    //       setCustomerDataState((customerDataState) => {
    //         return {
    //           ...customerDataState,
    //           exist: false,
    //         };
    //       });
    //     }
    //   });
  }, [mid, firestore]);

  // const onCustomerDataChanged = (event: any, id: string) => {
  //   setCustomerDataState({
  //     ...customerDataState,
  //     stored: false,
  //     error: false,
  //   });
  //   if (id === "organisation") {
  //     if (!customerDataState.exist) {
  //       setCustomerDataState({
  //         ...customerDataState,
  //         organisation: event.target.value,
  //       });
  //     }
  //   } else if (id === "firstname") {
  //     setCustomerDataState({
  //       ...customerDataState,
  //       firstname: event.target.value,
  //     });
  //   } else if (id === "lastname") {
  //     setCustomerDataState({
  //       ...customerDataState,
  //       lastname: event.target.value,
  //     });
  //   } else if (id === "email") {
  //     setCustomerDataState({ ...customerDataState, email: event.target.value });
  //   } else if (id === "street") {
  //     setCustomerDataState({
  //       ...customerDataState,
  //       street: event.target.value,
  //     });
  //   } else if (id === "number") {
  //     setCustomerDataState({
  //       ...customerDataState,
  //       number: event.target.value,
  //     });
  //   } else if (id === "zip") {
  //     setCustomerDataState({ ...customerDataState, zip: event.target.value });
  //   } else if (id === "city") {
  //     setCustomerDataState({
  //       ...customerDataState,
  //       city: event.target.value,
  //     });
  //   } else if (id === "country") {
  //     setCustomerDataState({
  //       ...customerDataState,
  //       country: +event.target.value,
  //     });
  //   } else {
  //     return;
  //   }
  // };

  // const storeCustomerData = () => {
  //   setCustomerDataState({
  //     ...customerDataState,
  //     storing: true,
  //   });
  //   const createContact = Functions().httpsCallable(
  //     "calls-sevdesk-createContact"
  //   );
  //   const updateContact = Functions().httpsCallable(
  //     "calls-sevdesk-updateContact"
  //   );
  //   if (customerDataState.exist) {
  //     updateContact({
  //       lastname: customerDataState.lastname,
  //       firstname: customerDataState.firstname,
  //       email: customerDataState.email,
  //       street: customerDataState.street,
  //       number: customerDataState.number,
  //       zip: customerDataState.zip,
  //       city: customerDataState.city,
  //       country: customerDataState.country,
  //       createinvoice: true,
  //     })
  //       .then((value) => {
  //         if (value.data.success) {
  //           firestore
  //             .collection(COLL_MERCHANT)
  //             .doc(mid || "-")
  //             .set(
  //               {
  //                 customerdata: {
  //                   firstname: customerDataState.firstname,
  //                   lastname: customerDataState.lastname,
  //                   email: customerDataState.email,
  //                   street: customerDataState.street,
  //                   number: customerDataState.number,
  //                   zip: customerDataState.zip,
  //                   city: customerDataState.city,
  //                   country: customerDataState.country,
  //                 },
  //               },
  //               { merge: true }
  //             );
  //           setCustomerDataState({
  //             ...customerDataState,
  //             storing: false,
  //             stored: true,
  //             error: false,
  //           });
  //         } else {
  //           setCustomerDataState({
  //             ...customerDataState,
  //             storing: false,
  //             stored: false,
  //             error: true,
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setCustomerDataState({
  //           ...customerDataState,
  //           storing: false,
  //           stored: true,
  //         });
  //       });
  //   } else {
  //     createContact({
  //       organisationname: customerDataState.organisation,
  //       lastname: customerDataState.lastname,
  //       firstname: customerDataState.firstname,
  //       email: customerDataState.email,
  //       street: customerDataState.street,
  //       number: customerDataState.number,
  //       zip: customerDataState.zip,
  //       city: customerDataState.city,
  //       country: customerDataState.country,
  //     })
  //       .then((value) => {
  //         if (value.data.success) {
  //           firestore
  //             .collection(COLL_MERCHANT)
  //             .doc(mid || "-")
  //             .set(
  //               {
  //                 customerdata: {
  //                   firstname: customerDataState.firstname,
  //                   lastname: customerDataState.lastname,
  //                   organisation: customerDataState.organisation,
  //                   email: customerDataState.email,
  //                   street: customerDataState.street,
  //                   number: customerDataState.number,
  //                   zip: customerDataState.zip,
  //                   city: customerDataState.city,
  //                   country: customerDataState.country,
  //                 },
  //               },
  //               { merge: true }
  //             );
  //           setCustomerDataState({
  //             ...customerDataState,
  //             storing: false,
  //             stored: true,
  //             error: false,
  //           });
  //         } else {
  //           setCustomerDataState({
  //             ...customerDataState,
  //             storing: false,
  //             stored: false,
  //             error: true,
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setCustomerDataState({
  //           ...customerDataState,
  //           storing: false,
  //           stored: true,
  //         });
  //       });
  //   }
  // };

  return (
    <>
      {false ? (
        <Banner
          cancelable={true}
          type={2}
          text={t("components.settings.mydata.stored")}
        />
      ) : (
        <></>
      )}
      {false ? (
        <Banner
          cancelable={true}
          type={0}
          text={t("components.settings.mydata.error")}
        />
      ) : (
        <></>
      )}
      {false ? (
        <Loading />
      ) : (
        <SettingsWrapper title={t("components.settings.mydata.mydata")}>
          <CompanyDataForm onComplete={() => {}} showConditions={false} />
          {/* <div className="block sm:flex sm:flex-wrap sm:justify-between w-1/2 lg:w-10/12 sm:w-full sm:mx-auto my-2 box-border text-center">
            <p className="w-full text-left mt-4">
              <li className="inline-flex">
                {t("components.settings.mydata.contact_information")}
                <FaInfoCircle
                  data-tip=""
                  data-for={"contactPersonInfo"}
                  className="mt-1 ml-2"
                />
              </li>
              <ReactTooltip
                id={"contactPersonInfo"}
                // type="info"
                place="right"
                // effect="solid"
                // backgroundColor="black"
              >
                <span>
                  {t("components.settings.mydata.info_contact_person")}
                </span>
              </ReactTooltip>
            </p>
            <div className="flex sm:flex-wrap justify-between">
              <input
                onChange={(event) => onCustomerDataChanged(event, "firstname")}
                type="text"
                required={true}
                value={customerDataState.firstname}
                id="firstname"
                placeholder={`${t("components.settings.mydata.firstname")}`}
                className="sm:w-full  rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
              />
              <input
                onChange={(event) => onCustomerDataChanged(event, "lastname")}
                type="text"
                required={true}
                value={customerDataState.lastname}
                id="lastname"
                placeholder={`${t("components.settings.mydata.lastname")}`}
                className="sm:w-full  rounded-lg text-base w-4/9 p-2 mt-2 box-border border-black border text-black"
              />
            </div>
            <p className="w-full text-left mt-4">
              <li className="inline-flex">
                {t("components.settings.mydata.organisation_information")}
                <FaInfoCircle
                  data-tip=""
                  data-for={"organisationInfo"}
                  className="mt-1 ml-2"
                />
              </li>
              <ReactTooltip
                id={"organisationInfo"}
                className="ml-12"
                // type="info"
                place="right"
                // effect="solid"
                // backgroundColor="black"
              >
                <span>{t("components.settings.mydata.info_organisation")}</span>
              </ReactTooltip>
            </p>
            <input
              onChange={(event) => onCustomerDataChanged(event, "organisation")}
              type="text"
              required={true}
              readOnly={customerDataState.exist ? true : false}
              value={customerDataState.organisation}
              id="organisation"
              placeholder={`${t("components.settings.mydata.organisation")}`}
              className={
                customerDataState.exist
                  ? "block mx-auto sm:w-full rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black font-bold bg-gray-100 cursor-not-allowed"
                  : "block mx-auto sm:w-full rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
              }
            />
            <input
              onChange={(event) => onCustomerDataChanged(event, "email")}
              type="email"
              required={true}
              value={customerDataState.email}
              id="email"
              placeholder={`${t("components.settings.mydata.email")}`}
              className="block  mx-auto rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
            />
            <div className="flex sm:flex-wrap justify-between">
              <input
                onChange={(event) => onCustomerDataChanged(event, "street")}
                type="text"
                required={true}
                value={customerDataState.street}
                id="street"
                placeholder={`${t("components.settings.mydata.street")}`}
                className="sm:w-full  rounded-lg text-base w-8/12 p-2 mt-2 box-border border-black border text-black"
              />
              <input
                onChange={(event) => onCustomerDataChanged(event, "number")}
                type="text"
                required={true}
                value={customerDataState.number}
                id="number"
                placeholder={`${t("components.settings.mydata.number")}`}
                className="sm:w-full  rounded-lg text-base w-3/12 p-2 mt-2 box-border border-black border text-black"
              />
            </div>
            <div className="flex sm:flex-wrap justify-between">
              <input
                onChange={(event) => onCustomerDataChanged(event, "zip")}
                type="text"
                required={true}
                value={customerDataState.zip}
                id="zip"
                placeholder={`${t("components.settings.mydata.zipcode")}`}
                className="sm:w-full  rounded-lg text-base w-2/12 p-2 mt-2 box-border border-black border text-black"
              />
              <input
                onChange={(event) => onCustomerDataChanged(event, "city")}
                type="text"
                required={true}
                value={customerDataState.city}
                id="city"
                placeholder={`${t("components.settings.mydata.city")}`}
                className="sm:w-full  rounded-lg text-base w-9/12 p-2 mt-2 box-border border-black border text-black"
              />
            </div>
            <Dropdown
              initialId={`${customerDataState.country || 1}`}
              id="country"
              options={customerDataState.countries.map((country) => {
                return {
                  id: `${country.id}`,
                  value: country.id,
                  name: country.name,
                };
              })}
              onChangeListener={onCustomerDataChanged}
            />
            <input
              onClick={() => storeCustomerData()}
              type="submit"
              id="save"
              disabled={customerDataState.storing ? true : false}
              value={
                customerDataState.storing
                  ? "..."
                  : customerDataState.exist
                  ? t("components.settings.mydata.update").toString()
                  : t("components.settings.mydata.save").toString()
              }
              className="block sm:w-full rounded-xl p-2 w-1/4 text-base my-8 box-border text-white bg-black cursor-pointer"
            />
          </div> */}
        </SettingsWrapper>
      )}
    </>
  );
};

export default CustomerData;
