import { getTailwindColumnClass } from "../../../utils/utils";

type BodyItem = {
  title: string;
  value: any;
};

type TableBodyProps = {
  itemsBody: BodyItem[];
  rows: number;
  id: string;
  categoryTitle?: string;
  isClickable?: boolean;
  componentPath?: string;
  component?: any;
  onClick?: (path: string, object: any) => void | {};
};

const TableBody = (props: TableBodyProps) => {
  const colString: string = getTailwindColumnClass(props.rows);
  const hoverString = ` hover:bg-gray-300 cursor-pointer`;
  const doClick = () => {
    if (props.onClick && props.component && props.componentPath) {
      props.onClick(props.componentPath, props.component);
    }
  };
  return (
    <li
      className={"block text-sm rounded-xl bg-white m-1 mx-10 sm:mx-4 xs:mx-2 font-normal grid xl:grid-cols-1 xl:mt-2"
        .concat(colString)
        .concat(hoverString)}
      key={props.id}
      onClick={() => doClick()}
    >
      {props.categoryTitle ? (
        <>
          <p className="hidden xl:block font-black text-lg m-2 text-left">
            {props.categoryTitle}
          </p>
          <hr className="hidden xl:block" />
        </>
      ) : (
        <></>
      )}
      {props.itemsBody.map((item) => {
        return (
          <div
            key={item.title + Math.random() * 1000}
            className="block col-span-1 text-black m-2 pr-4 text-left w-full xl:w-full xl:grid-cols-4 sm:grid-cols-2 xl:grid xl:text-left xs:grid-cols-1 text-left"
          >
            <p className="font-normal text-gray-800 hidden xl:block xl:mr-4">
              {item.title}
            </p>
            <div className="block xl:font-black xl:col-span-2 sm:col-span-1 px-1 w-full truncate">
              {item.value}
            </div>
          </div>
        );
      })}
    </li>
  );
};

export default TableBody;
