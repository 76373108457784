import LoadingComponent from "../../../../components/Loading/LoadingWrapper";

type PaymentCardProps = {
  title: string;
  smallTitle?: string;
  children: any;
  icon?: any;
  actions?: any;
  loading?: boolean;
};

const PaymentCard = (props: PaymentCardProps) => {
  return (
    <div className="w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-11/12 rounded-lg mt-6 pb-6 bg-white ">
      <div className="flex justify-start inline-block text-left h-max bg-white rounded-t-xl overflow-hidden align-middle">
        <p className="h-full m-4 text-xl font-bold flex">{props.title}</p>
        <p className="h-full m-4 my-auto text-sm flex text-gray-500 font-bold">
          {props.smallTitle}
        </p>
      </div>
      {props.loading && props.loading === true ? (
        <div>
          <LoadingComponent isLoading />
        </div>
      ) : (
        <>
          <div>{props.children}</div>
          <div className="text-right  h-max p-2 pr-4 rounded-b-xl overflow-hidden align-middle">
            {props.actions}
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentCard;

//OLD Card

/*
<div className="w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-11/12 rounded-lg mt-6 pb-6 bg-white ">
    <div className="flex justify-start inline-block text-left h-max bg-white rounded-t-xl overflow-hidden align-middle">
      <p className="h-full m-4 text-xl font-bold flex">{props.title}</p>
          <div className="h-full w-max m-auto text-2xl flex mr-2">
            {props.icon}
          </div>
        </div>
        <div>{props.children}</div>
        <div className="text-right h-max p-2 pr-4 rounded-b-xl overflow-hidden align-middle">
          {props.actions}
        </div>
      </div>



*/
