import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import {
  createTimestampFromObject,
  customerCollectionRef,
} from "../../../utils/firestore";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import {
  formatCustomerMail,
  replaceReferencesWithPath,
} from "../../../utils/utils";

import { Customer } from "../../../types/Customer";
import firebase from "firebase/compat/app";
import Filter, { FilterTypes } from "../../../components/Filter";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../../context/SessionContext";
// import { useNavigate } from "react-router-dom-v5-compat";

interface ICustomerState {
  loading: boolean;
  customers: Array<Record<string, any>>;
  moreItemsExist: boolean;
  loadingMore: boolean;
  customerQuery:
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query;
}

const Customers = () => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);
  const firestore = useFirestore();
  const navigate = useNavigate();

  const [customerState, setCustomerState] = useState<ICustomerState>({
    customers: [],
    loading: true,
    moreItemsExist: true,
    loadingMore: false,
    customerQuery: customerCollectionRef(mid || "-")
      .orderBy("createTime", "desc")
      .where(
        "profileRef",
        "==",
        firebase.firestore().doc(currentProfile?.path || "-/-")
      ),
  });

  useEffect(() => {
    if (currentProfile?.path) {
      updateCustomerQuery(
        customerCollectionRef(mid || "-")
          .where(
            "profileRef",
            "==",
            firebase.firestore().doc(currentProfile.path)
          )
          .orderBy("createTime", "desc")
      );
    }
  }, [currentProfile, mid]);

  useEffect(() => {
    const refreshCustomer = async (
      customersParam?: firebase.firestore.QuerySnapshot
    ) => {
      let customers: firebase.firestore.QuerySnapshot;
      if (customersParam) {
        customers = customersParam;
      } else {
        customers = await customerState.customerQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get();
      }
      if (customers.empty) {
        setCustomerState((customerState) => {
          return {
            ...customerState,
            loading: false,
            ...(customers.size < FIREBASE_REQUEST_LIMIT && {
              moreItemsExist: false,
            }),
          };
        });
        return;
      }
      let customerArray: Array<Record<string, any>> = [];
      customers.forEach((customer) => {
        const profileRef: firebase.firestore.DocumentReference =
          customer.data().profileRef;
        profileRef.get().then((profile) => {
          customerArray.push({
            data: replaceReferencesWithPath(customer.data()) as Customer,
            path: customer.ref.path,
            id: customer.id,
            profile: profile.data(),
          });
          setCustomerState((customerState) => {
            return {
              ...customerState,
              customers: customerArray,
              loading: false,
              ...(customers.size < FIREBASE_REQUEST_LIMIT && {
                moreItemsExist: false,
              }),
            };
          });
        });
      });
    };

    customerState.customerQuery.limit(FIREBASE_REQUEST_LIMIT).onSnapshot(
      (customers) => {
        refreshCustomer(customers);
      },
      () => {
        setCustomerState((customerState) => {
          return {
            ...customerState,
            loading: false,
            moreItemsExist: false,
          };
        });
      }
    );
  }, [mid, firestore, customerState.customerQuery]);

  const updateCustomerQuery = (query: firebase.firestore.Query) => {
    setCustomerState((customerState) => {
      return {
        ...customerState,
        loading: true,
        customers: [],
        customerQuery: query,
      };
    });
  };

  const loadMoreItems = async () => {
    setCustomerState((customerState) => {
      return {
        ...customerState,
        loadingMore: true,
      };
    });
    const customers = await customerState.customerQuery
      .startAfter(
        createTimestampFromObject(
          customerState.customers[customerState.customers.length - 1].data
            .createTime
        ).toDate()
      )
      .limit(FIREBASE_REQUEST_LIMIT)
      .get();
    const customerTempArray: Array<{
      data: Record<string, any>;
      path: string;
      id: string;
      profileRef: firebase.firestore.DocumentReference;
    }> = [];
    const customerArray: Array<Record<string, any>> = [];
    customers.forEach((customer) => {
      const data = customer.data();
      const profileRef: firebase.firestore.DocumentReference = data.profileRef;
      customerTempArray.push({
        data: replaceReferencesWithPath(data) as Customer,
        path: customer.ref.path,
        id: customer.id,
        profileRef: profileRef,
      });
    });
    for (const customer of customerTempArray) {
      const profile = (await customer.profileRef.get()).data();
      customerArray.push({
        data: customer.data,
        path: customer.path,
        id: customer.id,
        profile: profile,
      });
    }
    setCustomerState((customerState) => {
      return {
        ...customerState,
        customers: customerState.customers.concat(customerArray),
        loading: false,
        loadingMore: false,
        ...(customers.size < FIREBASE_REQUEST_LIMIT && {
          moreItemsExist: false,
        }),
      };
    });
  };

  const handleOnClick = useCallback(
    (link: string, customer?: any) =>
      navigate(link, { state: { customer: customer } }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.CUSTOMER}
        query={customerCollectionRef(mid || "-")}
        updateQuery={updateCustomerQuery}
      />
      <LoadingComponent isLoading={customerState.loading}>
        <Table
          loadMoreItems={loadMoreItems}
          moreItemsExist={customerState.moreItemsExist}
          loadingMoreItems={customerState.loadingMore}
          itemsTitle={[
            t("components.customers.shop_name"),
            t("components.customers.name"),
            t("components.customers.mail"),
          ]}
        >
          {customerState.customers.map((customer) => {
            return (
              <TableBody
                key={customer.id}
                categoryTitle={`${t("components.customers.customer")}`}
                component={customer}
                componentPath={"/customer/" + customer.id}
                isClickable={true}
                onClick={handleOnClick}
                id={customer.id}
                rows={3}
                itemsBody={[
                  {
                    title: t("components.customers.shop_name"),
                    value: customer?.profile?.name || "n/a",
                  },
                  {
                    title: t("components.customers.name"),
                    value: customer?.data?.name || "n/a",
                  },
                  {
                    title: t("components.customers.mail"),
                    value: formatCustomerMail(customer?.data?.email || "n/a"),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

export default Customers;
