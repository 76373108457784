import { useEffect, useState } from "react";
// import { Link, useMatch } from "react-router-dom-v5-compat";
import { COLL_KEY, merchantRef } from "../../../utils/firestore";
import firebase from "firebase/compat/app";
import { Profile, ProfileStatus } from "../../../types/Profile";
import SettingsWrapper from "../../../components/SettingsWrapper";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import { Merchant } from "../../../types/Merchant";
import Button from "../../../components/Button";
import { translateKeyState } from "../../../utils/utils";
import { Link, useMatch } from "react-router-dom";

// type TokenAdminProps = {
//   history: any;
//   location: any;
//   match: any;
// };

type ITokenAdminState = {
  loading: boolean;
  tokenid: string;
  token: Profile | null | undefined;
  merchant: Merchant | null | undefined;
  merchantid: string;
};

const Token = () => {
  const match = useMatch("/atoken/:merchantid/:tokenid");
  const merchantParam = match?.params.merchantid || "n/a";
  const tokenParam = match?.params.tokenid || "n/a";

  const [tokenAdminState, setTokenAdminState] = useState<ITokenAdminState>({
    loading: true,
    tokenid: "",
    token: null,
    merchant: null,
    merchantid: "",
  });

  useEffect(() => {
    console.log("Test");
    const refreshData = async () => {
      const key = (await merchantRef(merchantParam)
        .collection(COLL_KEY)
        .doc(tokenParam)
        .get()) as firebase.firestore.DocumentSnapshot<Profile>;

      if (key.exists) {
        const merchant = (await merchantRef(
          merchantParam
        ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;
        setTokenAdminState((tokenAdminState) => {
          return {
            ...tokenAdminState,
            tokenid: key.id,
            token: key.data(),
            merchant: merchant.data(),
            merchantid: merchant.id,
            loading: false,
          };
        });
      } else {
        setTokenAdminState((tokenAdminState) => {
          return {
            ...tokenAdminState,
            tokenid: "",
            token: null,
            loading: false,
          };
        });
      }
    };
    refreshData();
  }, [merchantParam, tokenParam]);

  return (
    <>
      <SettingsWrapper title="Verwalte API-Schlüssel">
        <LoadingComponent isLoading={tokenAdminState.loading}>
          {tokenAdminState.token === null ? (
            <p className="w-full text-left mt-4">
              Key: {tokenParam} existiert nicht für Händler: {merchantParam}
            </p>
          ) : (
            <div className="bg-white rounded rounded-xl p-4 mt-4 flex flex-wrap gap-4 w-1/3 2xl:w-full">
              <div className="w-full flex flex-wrap gap-4 mb-4 content-center">
                <p className="font-bold text-2xl self-center">Schlüssel</p>
                <p className="font-bold  text-gray-400 self-center">
                  {tokenAdminState.tokenid}
                </p>
              </div>
              <div className="grid grid-cols-3 gap-2 text-left">
                <>
                  <p className="font-bold col-span-1">Name</p>
                  <p className="truncate col-span-2">
                    {tokenAdminState.token?.name}
                  </p>
                </>
                <>
                  <p className="font-bold col-span-1">Url</p>
                  <p className="truncate col-span-2">
                    {tokenAdminState.token?.url}
                  </p>
                </>
                <>
                  <p className="font-bold col-span-1">Key</p>
                  <p className="truncate col-span-2">
                    {tokenAdminState.token?.key}
                  </p>
                </>
                <>
                  <p className="font-bold col-span-1">Händler</p>
                  <div className="col-span-2 ">
                    <Link to={"../../acustomers/" + tokenAdminState.merchantid}>
                      <p className="truncate underline ">
                        {tokenAdminState.merchant?.name}
                      </p>
                    </Link>
                  </div>
                </>
                <>
                  <p className="font-bold col-span-1">Firma</p>
                  <p className="truncate col-span-2">
                    {tokenAdminState.merchant?.customerdata?.organisation ||
                      "n/a"}
                  </p>
                </>
                <>
                  <p className="font-bold col-span-1 mt-4">Status</p>
                  <p className="truncate col-span-2 mt-4">
                    {tokenAdminState.token?.status !== undefined
                      ? translateKeyState(
                          tokenAdminState.token?.status
                        ).toUpperCase()
                      : "n/a"}
                  </p>
                </>
              </div>
              <div className="w-full flex flex-wrap gap-4 justify-center">
                {tokenAdminState.token?.status === ProfileStatus.Requested ? (
                  <Button title="Freischalten" color="bg-green-800" />
                ) : (
                  <></>
                )}
                {tokenAdminState.token?.status === ProfileStatus.Valid ? (
                  <Button title="Deaktivieren" color="bg-gray-800" />
                ) : (
                  <></>
                )}
                {tokenAdminState.token?.status === ProfileStatus.Disabled ? (
                  <Button title="Aktivieren" color="bg-green-800" />
                ) : (
                  <></>
                )}
                <Button title="Löschen" color="bg-red-800" />
              </div>
            </div>
          )}
        </LoadingComponent>
      </SettingsWrapper>
    </>
  );
};

export default Token;
