// import { translateTransactionState } from "../../../../../../utils/utils";
import NoEntry from "../../../../../NoEntries";
import TransactionCard from "./TransactionCard";

interface ITransactionTabProps {
  transactions?: Array<Record<string, any>>;
  onClick?: (path: string, object: any) => void;
}

const TransactionTab = ({ onClick, transactions }: ITransactionTabProps) => {
  // const doClickTransaction = (path: string, transaction: any) => {
  //   if (onClick && transactions) {
  //     onClick(path, transaction);
  //   }
  // };
  if (!transactions || transactions === null || transactions.length === 0)
    return <NoEntry />;

  const sortedKeys = transactions.sort((a, b) => {
    return a['data'].createdAt - b['data'].createdAt
  });
  return (
    <>
      {sortedKeys?.map((transaction) => {
        return (
          // <div
          //   key={`transaction_${transaction.id}`}
          //   className="flex flex-wrap justify-between p-1 my-1 rounded rounded-xl border border-solid grid grid-cols-2 hover:bg-gray-300 hover:border-black"
          //   onClick={() =>
          //     doClickTransaction(
          //       "../transaction/" + transaction.id,
          //       transaction
          //     )
          //   }
          // >
          //   <div className="flex flex-wrap justify-between">
          //     <p className="flex truncate">{transaction.id}</p>
          //     <p className="flex truncate">
          //       {translateTransactionState(transaction.data.status)}
          //     </p>
          //   </div>
          // </div>
          <TransactionCard key={transaction.id} transaction={transaction} onClick={onClick} />
        );
      })}
    </>
  );
};

export default TransactionTab;
