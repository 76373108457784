import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { COLL_TARIFF } from "../../../utils/firestore";
import i18n from "../../../locales/i18n";
import { SessionContext } from "../../../context/SessionContext";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "@react-spring/web";

const TariffCard = ({ tid }: { tid: string }) => {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const { selectTariff, currentTariffRanking } = useContext(SessionContext);
  const [tariff, setTariff] = useState<Record<string, any> | null>(null);
  const currentLanguage = i18n.language;
  const tariffInfo: Array<string> = tariff?.details[currentLanguage] || [];
  const tariffColor = tariff?.color || "#000";
  const [canBeUpgraded, setCanBeUpgraded] = useState<boolean>(false);

  useEffect(() => {
    if (tariff === null || currentTariffRanking === null) return;
    setCanBeUpgraded(tariff?.ranking > currentTariffRanking);
  }, [currentTariffRanking, tariff]);

  useEffect(() => {
    firestore
      .collection(COLL_TARIFF)
      .doc(tid)
      .get()
      .then((tariffDoc) => {
        setTariff({ ...tariffDoc.data() });
      });
  }, [tid, firestore]);

  const chooseTariff = () => {
    selectTariff(tid || null);
  };
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });

  if (tariff === null) {
    return null;
  }

  return (
    <animated.div
      style={{
        width: 350,
        height: 520,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        alignItems: "flex-start",
        padding: 10,
        borderRadius: 8,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: tariffColor,
        ...springs,
      }}
    >
      <p
        style={{ fontSize: 39, fontWeight: "bold", color: tariffColor }}
      >{`${tariff.name}`}</p>
      <p
        style={{ fontSize: 14, fontWeight: "normal" }}
      >{`${tariff.description}`}</p>
      <p
        style={{
          fontSize: 59,
          fontWeight: "bold",
          color: tariffColor,
          width: "100%",
          textAlign: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {`${
          +tariff.price.value.split(".")[1] > 0
            ? `${tariff.price.value}`
            : `${tariff.price.value.split(".")[0]}`
        } €`}
      </p>
      <input
        onClick={chooseTariff}
        type="submit"
        id="choose"
        disabled={!canBeUpgraded}
        value={
          canBeUpgraded
            ? `${t("components.onboarding.tariff.choose_tariff")}`
            : `${t("components.onboarding.tariff.not_available")}`
        }
        style={{
          width: "100%",
          backgroundColor: tariffColor,
          borderRadius: 10,
          padding: 10,
          cursor: "pointer",
          opacity: canBeUpgraded ? 1 : 0.5,
          color: "#FFF",
        }}
      />
      <Grid container spacing={0} alignItems="center" style={{ marginTop: 30 }}>
        {tariffInfo.map((info, index) => {
          return (
            <Fragment key={`${index}_${info}_0`}>
              <Grid item xs={2} key={`${index}_${info}_1`}>
                <FontAwesomeIcon
                  icon={"check-circle"}
                  size="lg"
                  color={tariffColor}
                />
              </Grid>
              <Grid item xs={10} key={`${index}_${info}_2`}>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: 14,
                    textAlign: "left",
                  }}
                >
                  {info}
                </p>
              </Grid>
              {index < tariffInfo.length - 1 && (
                <Grid item xs={12} key={`${index}_${info}_divider`}>
                  <Divider />
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </animated.div>
  );
};

const Divider = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 3,
        marginTop: 4,
        marginBottom: 4,
      }}
    >
      <div
        style={{
          width: "90%",
          height: 1,
          borderColor: "#CCC",
          backgroundColor: "#CCC",
        }}
      />
    </div>
  );
};

export default TariffCard;
