import firebase from "firebase/compat/app";
import { Amount } from "../Amount";

export enum TransactionState {
  RefundedNotified = -4, //If Amount or PartialAmount of Transaction has been refunded and shop/system was successfully notified about it
  Refunded = -3, //If Amount or PartialAmount of Transaction has been refunded
  FailedNotified = -2, //If anything goes wrong and shop/system was successfully notified about it
  Failed = -1, // If anything goes wrong
  Created, // Transaction created =>
  Open, // Order created (externalId!) =>
  Pending, // Payment created (ppId!) =>
  Paid, // Payment successful =>
  Complete, // Webhook successfully noitfied
}

/**
 * 1. Transaction erstellen
 * 2. Hook: handle Transaktion
 */

export type Transaction = {
  /**
   * State of Transaction
   */
  status: TransactionState;
  amount?: Amount;

  /**
   * PaymentProvider ID of this Transaction
   */
  ppId?: string;

  ppMethod?: string;

  ppDetails?: string;

  /**
   * External ID of this Transaction, e.g. order id
   */
  externalId?: string;

  customerRef: firebase.firestore.DocumentReference;

  aboRef: firebase.firestore.DocumentReference;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
  profileRef: firebase.firestore.DocumentReference;
};
