import { Amount } from "../Amount";
import { Product } from "../Product";
import firebase from "firebase/compat/app";


export enum AboStatus {
  Created, // NO MANDATE
  Active, // MANDATE
  Paused,
  Finished, // times == count
  Error, // NO MANDATE
  Deleted, //Abo was canceled
}

export type Abo = {
  /**
   * Total Abo Amount
   */
  amount: Amount;

  /**
   * Next Execution Date
   */
  nextExecution?: firebase.firestore.Timestamp | null;

  /**
   * PaymentProvider's MandateID to book the Transactions and Payments on
   */
  ppMandateId?: string;

  /**
   * Use Test Mode?
   */
  test: boolean;

  /**
   * Current state of the Abo
   */
  status: AboStatus;

  /**
   * OPTIONAL
   * Total number of charges for the subscription to complete.
   * Leave undefined for an ongoing subscription.
   */
  times?: number;

  /**
   * Execution Counter
   */
  count: number;

  /**
   * Interval to wait between charges, for example 1 month or 14 days.
   *
   * Possible values: ... months ... weeks ... days
   *
   * The maximum interval is 1 year (12 months, 52 weeks or 365 days).
   */
  interval: string;

  /**
   * A description unique per subscription.
   * This will be included in the payment description.
   */
  description: string;

  /**
   * The start date of the subscription.
   * This is the first day on which your customer will be charged.
   * When this parameter is not provided, the current date will be used instead.
   */
  startDate: firebase.firestore.Timestamp;

  /**
   * Use this parameter to set a webhook URL for all subscription payments.
   */
  webhookUrl: string;

  /**
   * Use this parameter to set a redirect URL for all subscription checkouts.
   */
  redirectUrl: string;

  /**
   * Provide any data you like, and we will save the data alongside the subscription.
   * Whenever you fetch the subscription with our API, we will also include the metadata.
   * You can use up to METADATA_BYTE_LIMIT of JSON.
   */
  metadata?: string;

  /**
   * OPTIONAL
   * List of Products in this Abo
   */
  products?: Product[];

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;

  customerRef: firebase.firestore.DocumentReference;

  profileRef: firebase.firestore.DocumentReference;
};
