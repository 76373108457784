import NoEntry from "../../../../../NoEntries";
import ProductCard from "./ProductCard";

interface IProductTabProps {
  products?: Array<Record<string, any>>;
}

const ProductTab = ({ products }: IProductTabProps) => {
  if (!products || products === null || products.length === 0)
    return <NoEntry />;

  return (
    <div>
      {products.map((product) => {
        return <ProductCard key={product.name} product={product} />;
      })}
    </div>
  );
};

export default ProductTab;
