import firebase from "firebase/compat/app";
import { Timestamp } from "firebase/firestore";
import { Merchant } from "../types/Merchant";

export const COLL_MERCHANT = "merchants";
export const COLL_CUSTOMER = "customer";
export const COLL_ABO = "abos";
export const COLL_USER = "user";
export const COLL_TRANSACTION = "transactions";
export const COLL_PAYMENT = "payments";
export const COLL_KEY = "keys";
export const COLL_HOOKS_MOLLIE = "molliehooks";
export const COLL_HOOKS_ORDER = "orderhooks";
export const COLL_TARIFF = "tariff";
export const COLL_BOOKING = "booking";

/**
 * Returns the DocumentReference of the merchant
 * @param {string} merchant
 * @returns DocumentReference of the merchant
 */
export const merchantRef = (
  merchant: string
): firebase.firestore.DocumentReference => {
  return firebase.firestore().collection(COLL_MERCHANT).doc(merchant);
};

/**
 * Returns the CollectionReference of the customer collection
 * @param {string} merchant
 * @returns CollectionReference of the customer collection
 */
export const customerCollectionRef = (
  merchant: string
): firebase.firestore.CollectionReference => {
  return merchantRef(merchant).collection(COLL_CUSTOMER);
};

/**
 * Returns the CollectionReference of the abo collection
 * @param {string} merchant
 * @returns CollectionReference of the abo collection
 */
export const aboCollectionRef = (
  merchant: string
): firebase.firestore.CollectionReference => {
  return merchantRef(merchant).collection(COLL_ABO);
};

/**
 * Returns the CollectionReference of the transaction collection
 * @param {string} merchant
 * @returns CollectionReference of the transaction collection
 */
export const transactionCollectionRef = (
  merchant: string
): firebase.firestore.CollectionReference => {
  return merchantRef(merchant).collection(COLL_TRANSACTION);
};

/**
 * Returns the CollectionReference of the transaction collection
 * @param {string} merchant
 * @returns CollectionReference of the transaction collection
 */
export const paymentCollectionRef = (
  merchant: string
): firebase.firestore.CollectionReference => {
  return merchantRef(merchant).collection(COLL_PAYMENT);
};

/**
 * Returns the CollectionReference of the tariff collection
 * @returns CollectionReference of the tariff collection
 */
export const tariffCollectionRef =
  (): firebase.firestore.CollectionReference => {
    return firebase.firestore().collection(COLL_TARIFF);
  };

/**
 * Returns the CollectionReference of the key collection
 * @returns CollectionReference of the key collection
 */
export const keyCollectionRef = (
  merchant: string
): firebase.firestore.CollectionReference => {
  return merchantRef(merchant).collection(COLL_KEY);
};

/**
 * Returns a DocumentSnapshot of the merchant
 * @param {string} merchant
 * @returns DocumentSnapshot of the merchant
 */
export const merchant = async (
  merchant: string
): Promise<firebase.firestore.DocumentSnapshot<Merchant>> => {
  const merchantSnapshot = (await merchantRef(
    merchant
  ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;
  return new Promise<firebase.firestore.DocumentSnapshot<Merchant>>(
    (resolve) => {
      resolve(merchantSnapshot);
    }
  );
};

/**
 * Returns a QuerySnapshot of the customer collection
 * @param {string} merchant
 * @returns QuerySnapshot of the customer collection
 */
export const allCustomer = async (
  merchant: string
): Promise<firebase.firestore.QuerySnapshot> => {
  const customer = await customerCollectionRef(merchant).get();
  return new Promise<firebase.firestore.QuerySnapshot>((resolve) => {
    resolve(customer);
  });
};

/**
 * Returns a QuerySnapshot of the abo collection
 * @param {string} merchant
 * @returns QuerySnapshot of the abo collection
 */
export const allAbos = async (
  merchant: string
): Promise<firebase.firestore.QuerySnapshot> => {
  const abos = await aboCollectionRef(merchant).get();
  return new Promise<firebase.firestore.QuerySnapshot>((resolve) => {
    resolve(abos);
  });
};

/**
 * Returns a QuerySnapshot of the transaction collection
 * @param {string} merchant
 * @returns QuerySnapshot of the transaction collection
 */
export const allTransactions = async (
  merchant: string
): Promise<firebase.firestore.QuerySnapshot> => {
  const transactions = await transactionCollectionRef(merchant).get();
  return new Promise<firebase.firestore.QuerySnapshot>((resolve) => {
    resolve(transactions);
  });
};

/**
 * Returns a QuerySnapshot of the key collection
 * @param {string} merchant
 * @returns QuerySnapshot of the key collection
 */
export const allKeys = async (
  merchant: string
): Promise<firebase.firestore.QuerySnapshot> => {
  const keys = await keyCollectionRef(merchant).get();
  return new Promise<firebase.firestore.QuerySnapshot>((resolve) => {
    resolve(keys);
  });
};

//
//
//
//
//
// ADMIN QUERYS
//
//
//
//
//
//

/**
 * Returns the CollectionReference of the customer collection
 * @returns CollectionReference of the customer collection
 */
export const merchantCollectionGroupRef = (): firebase.firestore.Query => {
  return firebase.firestore().collectionGroup(COLL_MERCHANT);
};

/**
 * Returns the CollectionReference of the customer collection
 * @returns CollectionReference of the customer collection
 */
export const aboCollectionGroupRef = (): firebase.firestore.Query => {
  return firebase.firestore().collectionGroup(COLL_ABO);
};

/**
 * Returns the CollectionReference of the customer collection
 * @returns CollectionReference of the customer collection
 */
export const transactionCollectionGroupRef = (): firebase.firestore.Query => {
  return firebase.firestore().collectionGroup(COLL_TRANSACTION);
};

/**
 * Returns the CollectionReference of the customer collection
 * @returns CollectionReference of the customer collection
 */
export const profileCollectionGroupRef = (): firebase.firestore.Query => {
  return firebase.firestore().collectionGroup(COLL_KEY);
};

export const createTimestampFromObject = (
  obj: Record<string, any>
): Timestamp => {
  return new Timestamp(obj.seconds, obj.nanoseconds);
};
