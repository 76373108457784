import { useContext, useEffect, useState } from "react";
import { Functions } from "../../../firebase";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import PaymentCard from "./PaymentCard";
import MollieConnectContent from "./Mollie/MollieConnectContent/MollieConnectContent";
import MollieConnectedContent from "./Mollie/MollieConnectedContent/MollieConnectedContent";
import MollieChooseProfileContent from "./Mollie/MollieChooseProfileContent/MollieChooseProfileContent";
import { useFirestore } from "react-redux-firebase";
import { merchant, merchantRef } from "../../../utils/firestore";
import Button from "../../../components/Button";
import SettingsWrapper from "../../../components/SettingsWrapper";
import Modal from "../../../components/Modal";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom-v5-compat";

enum PaymentStates {
  INIT,
  CONNECTED,
  READY,
}

// type PaymentProps = {
//   history: any;
//   location: any;
//   match: any;
// };

interface IPaymentState {
  state: number;
  loading: boolean;
  currentMollieProfile: string;
  modalIsOpen: boolean;
}

const PaymentSetting = () => {
  const [paymentState, setPaymentState] = useState<IPaymentState>({
    state: PaymentStates.INIT,
    loading: false,
    currentMollieProfile: "",
    modalIsOpen: false,
  });

  const [connectionLoading, setConnectionLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();
  const location = useLocation();

  const deleteMollieAccount = async () => {
    closeModal();
    setPaymentState({ ...paymentState, loading: true });
    const merchantSnapshot = await merchant(mid || "-");
    if (merchantSnapshot.exists) {
      merchantRef(mid || "-")
        .update({
          "paymentprovider.mollie": {},
        })
        .then(() => {
          setPaymentState((paymentState) => {
            return {
              ...paymentState,
              loading: false,
              state: PaymentStates.INIT,
            };
          });
        });
    }
  };

  const storeMollieProfile = async (profile: string) => {
    setPaymentState({ ...paymentState, loading: true });
    const merchantSnapshot = await merchant(mid || "-");
    if (merchantSnapshot.exists) {
      merchantRef(mid || "-")
        .set(
          {
            paymentprovider: {
              mollie: { profileid: profile },
            },
          },
          { merge: true }
        )
        .then(() => {
          setPaymentState({
            ...paymentState,
            loading: false,
            currentMollieProfile: profile,
            state: PaymentStates.READY,
          });
        })
        .catch(() => {
          setPaymentState({
            ...paymentState,
            loading: false,
          });
        });
    } else {
      setPaymentState({ ...paymentState, loading: false });
    }
  };

  useEffect(() => {
    const refreshData = async () => {
      const params = new URLSearchParams(location.search);
      const accesstoken = params.get("code");
      const oauthState = params.get("state");
      if (!mid) {
        return;
      }
      setPaymentState((paymentState) => {
        return {
          ...paymentState,
          loading: true,
        };
      });
      if (accesstoken) {
        Functions()
          .httpsCallable("calls-oauth-validateState")({
            state: oauthState,
            application: `mollie`,
          })
          .then((result) => {
            if (result.data === true) {
              Functions()
                .httpsCallable("calls-mollie-getToken")({
                  authCode: accesstoken,
                })
                .then(() => {
                  setPaymentState((paymentState) => {
                    return {
                      ...paymentState,
                      state: PaymentStates.CONNECTED,
                      loading: false,
                    };
                  });
                });
              // const getMollieToken = Functions().httpsCallable(
              //   "func-mollie-getMollieToken"
              // );
              // getMollieToken({ mollieauthtoken: accesstoken }).then((value) => {
              //   if (value.data.success) {
              //     setPaymentState((paymentState) => {
              //       return {
              //         ...paymentState,
              //         state: PaymentStates.CONNECTED,
              //         loading: false,
              //       };
              //     });
              //   }
              // });
            }
          })
          .finally(() => {
            setPaymentState((paymentState) => {
              return {
                ...paymentState,
                loading: false,
              };
            });
          });
      } else {
        const merchantSnapshot = await merchant(mid || "-");
        const merchantDoc = merchantSnapshot.data();
        if (merchantSnapshot.exists && merchantDoc?.paymentprovider) {
          if (
            merchantDoc.paymentprovider.mollie &&
            merchantDoc.paymentprovider.mollie.access_token &&
            merchantDoc.paymentprovider.mollie.profileid
          ) {
            setPaymentState((paymentState) => {
              return {
                ...paymentState,
                state: PaymentStates.READY,
                currentMollieProfile:
                  merchantDoc.paymentprovider?.mollie.profileid!,
                loading: false,
              };
            });
            return;
          }
          if (
            merchantDoc.paymentprovider.mollie &&
            merchantDoc.paymentprovider.mollie.access_token
          ) {
            setPaymentState((paymentState) => {
              return {
                ...paymentState,
                state: PaymentStates.CONNECTED,
                loading: false,
              };
            });
            return;
          }
        }
        setPaymentState((paymentState) => {
          return {
            ...paymentState,
            state: PaymentStates.INIT,
            loading: false,
          };
        });
      }
    };
    refreshData();
  }, [location.search, mid, firestore]);

  const openModal = () => {
    setPaymentState((paymentState) => {
      return {
        ...paymentState,
        modalIsOpen: true,
      };
    });
  };
  const closeModal = () => {
    setPaymentState((paymentState) => {
      return {
        ...paymentState,
        modalIsOpen: false,
      };
    });
  };

  let mollieComponent;
  let mollieAction;
  switch (paymentState.state) {
    case PaymentStates.READY:
      mollieComponent = (
        <MollieConnectedContent profileId={paymentState.currentMollieProfile} />
      );
      mollieAction = (
        <div className="w-full m-auto">
          <div
            className="text-red-900 font-black px-6 py-4 cursor-pointer float-right rounded-xl hover:bg-red-900 hover:text-white w-max "
            onClick={() => openModal()}
          >
            {t("actions.delete")}
          </div>
        </div>
      );
      break;
    case PaymentStates.CONNECTED:
      mollieComponent = (
        <MollieChooseProfileContent storeProfile={storeMollieProfile} />
      );
      break;
    default:
      mollieComponent = (
        <MollieConnectContent setConnectionLoading={setConnectionLoading} />
      );
  }

  return (
    <SettingsWrapper title={t("components.settings.payments.mypayments")}>
      <Modal
        onRequestClose={closeModal}
        isOpen={paymentState.modalIsOpen}
        title={t("components.settings.payments.delete_account_title")}
        body={
          <>
            <p className="w-1/2">
              {t("components.settings.payments.delete_account_text")}
            </p>
            <div className="w-max">
              <Button
                title={t("components.settings.payments.delete_account_skip")}
                onClickListener={() => closeModal()}
              />
              <Button
                title={t("components.settings.payments.delete_account_delete")}
                color="bg-red-900"
                onClickListener={() => deleteMollieAccount()}
              />
            </div>
          </>
        }
      />
      <LoadingComponent isLoading={paymentState.loading}>
        <PaymentCard
          title="Mollie"
          smallTitle={
            paymentState.state === PaymentStates.READY ? "verbunden" : ""
          }
          actions={mollieAction}
          loading={connectionLoading}
        >
          {mollieComponent}
        </PaymentCard>
      </LoadingComponent>
    </SettingsWrapper>
  );
};

export default PaymentSetting;
