import { useFirestore } from "react-redux-firebase";
import TariffCard from "../Card";
import { useContext, useEffect, useState } from "react";
import { COLL_TARIFF } from "../../../utils/firestore";
import { AuthContext } from "../../Context/AuthContext";
import { Switch, SwitchProps, styled } from "@mui/material";
import { SessionContext } from "../../../context/SessionContext";
import { useTranslation } from "react-i18next";

const TariffSelection = ({ onComplete }: { onComplete: () => void }) => {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const { selectedTariff } = useContext(SessionContext);
  const [selectedInterval, setSelectedInterval] = useState<"month" | "year">(
    "month"
  );
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);
  const [tariffsMonth, setTariffsMonth] = useState<Array<string>>([]);
  const [tariffsYear, setTariffsYear] = useState<Array<string>>([]);

  useEffect(() => {
    if (switchChecked) {
      setSelectedInterval("year");
    } else {
      setSelectedInterval("month");
    }
  }, [switchChecked]);

  useEffect(() => {
    if (selectedTariff !== null) {
      onComplete();
    }
  }, [selectedTariff, onComplete]);

  useEffect(() => {
    firestore
      .collection(COLL_TARIFF)
      .get()
      .then((tariffDocs) => {
        const monthArray: Array<{ id: string; sort: number }> = [];
        const yearArray: Array<{ id: string; sort: number }> = [];
        tariffDocs.forEach((tariffDoc) => {
          const tariffData = tariffDoc.data();
          if (tariffData.public === true || tariffData.showFor.includes(mid)) {
            if (tariffData.interval === "1 months") {
              monthArray.push({ id: tariffData.id, sort: tariffData.sort });
            } else if (tariffData.interval === "12 months") {
              yearArray.push({ id: tariffData.id, sort: tariffData.sort });
            }
          }
          const monthArraySorted = monthArray
            .sort((t_1, t_2) => {
              if (t_1.sort > t_2.sort) {
                return 1;
              } else if (t_1.sort < t_2.sort) {
                return -1;
              } else {
                return 0;
              }
            })
            .map((tariff) => {
              return tariff.id;
            });
          const yearArraySorted = yearArray
            .sort((t_1, t_2) => {
              if (t_1.sort > t_2.sort) {
                return 1;
              } else if (t_1.sort < t_2.sort) {
                return -1;
              } else {
                return 0;
              }
            })
            .map((tariff) => {
              return tariff.id;
            });

          setTariffsMonth(monthArraySorted);
          setTariffsYear(yearArraySorted);
        });
      });
  }, [firestore, mid]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          marginTop: 30,
          marginBottom: 50,
        }}
      >
        <p>{`${t("components.onboarding.tariff.monthly")}`}</p>
        <div style={{ marginLeft: 15, marginRight: 15 }}>
          <IntervalSwitch
            value={switchChecked}
            onChange={() => {
              setSwitchChecked(!switchChecked);
            }}
          />
        </div>
        <p>{`${t("components.onboarding.tariff.yearly")}`}</p>
      </div>

      <div
        className="flex flex-row justify-around flex-wrap w-full w-min-full gap-2 lg:gap-8"
        style={{ justifyContent: "space-around" }}
      >
        {selectedInterval === "month"
          ? tariffsMonth.map((tid) => {
              return <TariffCard tid={tid} key={tid} />;
            })
          : tariffsYear.map((tid) => {
              return <TariffCard tid={tid} key={tid} />;
            })}
      </div>
    </div>
  );
};

const IntervalSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#000",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default TariffSelection;
