import { useEffect, useState } from "react";
import { Profile, ProfileStatus } from "../../../types/Profile";
import { profileCollectionGroupRef } from "../../../utils/firestore";
import firebase from "firebase/compat/app";
import { replaceReferencesWithPath } from "../../../utils/utils";
import { Merchant } from "../../../types/Merchant";
import { FaKey } from "react-icons/fa";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";

type IActionsState = {
  loading: boolean;
  profiles: any[];
};

const Dashboard = () => {
  const [actionsState, setActionsState] = useState<IActionsState>({
    profiles: [],
    loading: true,
  });
  useEffect(() => {
    const refreshData = async () => {
      try {
        const requestedProfiles = (await profileCollectionGroupRef()
          .where("status", "==", ProfileStatus.Requested)
          .get()) as firebase.firestore.QuerySnapshot<Profile>;
        let profileArray: any[] = [];
        requestedProfiles.forEach(async (profile) => {
          profileArray.push({
            data: replaceReferencesWithPath(profile.data()) as Profile,
            merchantid: "",
            merchant: null,
            path: profile.ref.path,
            id: profile.id,
          });
        });
        for (const profile of profileArray) {
          const merchant = (await firebase
            .firestore()
            .doc(profile.path)
            .parent.parent!.get()) as firebase.firestore.DocumentSnapshot<Merchant>;
          profile.merchant = merchant
            ? replaceReferencesWithPath(merchant.data() as Merchant)
            : null;
          profile.merchantid = merchant.id;
        }
        setActionsState((actionsState) => {
          return {
            ...actionsState,
            loading: false,
            profiles: profileArray,
          };
        });
      } catch {
        setActionsState((actionsState) => {
          return {
            ...actionsState,
            loading: false,
          };
        });
      }
    };

    refreshData();
  }, []);
  return (
    <div className="">
      <div className=" flex flex-wrap  w-full text-left mb-4">
        <p className="text-3xl font-bold w-full">Aktionen</p>
      </div>
      {actionsState.loading ? (
        <Loading />
      ) : actionsState.profiles.length >= 0 ? (
        <div className="flex flex-wrap lg:justify-center gap-4 ">
          <div className="w-1/2 h-1/3 flex flex-wrap gap-2 max-h-64 overflow-y-scroll">
            {actionsState.profiles.map((profile) => {
              return (
                <>
                  <Link
                    key={profile.id}
                    className="w-full"
                    to={`../atoken/${profile.merchantid}/${profile.id}`}
                  >
                    <div
                      className="bg-white rounded rounded-xl hover:bg-black hover:text-white p-3 px-2 text-left cursor-pointer flex flex-wrap gap-4"
                      key={profile.id}
                    >
                      <FaKey className="self-center text-2xl" />
                      <p className="self-center font-bold">
                        FREISCHALTUNG
                      </p>{" "}
                      <p className="self-center text-sm font-thin">
                        <b className="font-bold">{profile.merchant.name}</b>
                        {` hat die Freischaltung des Profiles `}
                        <b className="font-bold">{profile.data.name}</b>{" "}
                        {`(${profile.data.url}) beantragt`}
                      </p>
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <p className=" text-left text-sm font-thin">Keine Aktionen vorhanden</p>
      )}
    </div>
  );
};

export default Dashboard;
