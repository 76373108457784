import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import { transactionCollectionGroupRef } from "../../../utils/firestore";
import firebase from "firebase/compat/app";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import { Transaction as TransactionType } from "../../../types/Transaction";
import {
  formatDateFromFirebaseTimestamp,
  replaceReferencesWithPath,
  translateTransactionState,
} from "../../../utils/utils";
import Filter, { FilterTypes } from "../../../components/Filter";
import { useNavigate } from "react-router-dom";

interface ITransactionState {
  loading: boolean;
  transactions: any[];
  moreItemsExist: boolean;
  loadingMore: boolean;
  transactionQuery:
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query;
}

const Transaction = () => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const firestore = useFirestore();
  const navigate = useNavigate();

  const [transactionState, setTransactionState] = useState<ITransactionState>({
    loading: true,
    transactions: [],
    moreItemsExist: true,
    loadingMore: false,
    transactionQuery: transactionCollectionGroupRef().orderBy(
      "updateTime",
      "desc"
    ),
  });

  useEffect(() => {
    const refreshTransactions = async (
      transactionsParam?: firebase.firestore.QuerySnapshot
    ) => {
      let transactions: firebase.firestore.QuerySnapshot;
      if (transactionsParam) {
        transactions = transactionsParam;
      } else {
        transactions = (await transactionState.transactionQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get()) as firebase.firestore.QuerySnapshot;
      }
      let transactionArray: any[] = [];
      transactions.forEach((transaction) => {
        const merchantid = transaction.ref.parent.parent?.id;
        //Change Reference to Path because of serialization problems
        const data = replaceReferencesWithPath(transaction.data());
        transactionArray.push({
          data: data as TransactionType,
          path: transaction.ref.path,
          merchantid: merchantid,
          id: transaction.id,
        });
      });
      setTransactionState((transactionState) => {
        return {
          ...transactionState,
          transactions: transactionArray,
          loading: false,
          ...(transactions.size < FIREBASE_REQUEST_LIMIT && {
            moreItemsExist: false,
          }),
        };
      });
    };
    transactionState.transactionQuery
      .limit(FIREBASE_REQUEST_LIMIT)
      .onSnapshot((transactions) => {
        refreshTransactions(transactions);
      });
  }, [
    auth.authContextState.user.uid,
    firestore,
    transactionState.transactionQuery,
  ]);

  const updateTransactionQuery = (query: firebase.firestore.Query) => {
    setTransactionState((transactionState) => {
      return {
        ...transactionState,
        loading: true,
        transactions: [],
        transactionQuery: query,
      };
    });
  };

  const loadMoreItems = async () => {
    setTransactionState((transactionState) => {
      return { ...transactionState, loadingMore: true };
    });
    if (transactionState.transactions.length <= 0) {
      setTransactionState((transactionState) => {
        return { ...transactionState, loadingMore: false };
      });
      return;
    }
    const transactions = await transactionState.transactionQuery
      .limit(FIREBASE_REQUEST_LIMIT)
      .startAfter(
        transactionState.transactions[transactionState.transactions.length - 1]
          .data.created
      )
      .get();
    if (transactions.empty) {
      setTransactionState((transactionState) => {
        return { ...transactionState, loading: false, loadingMore: false };
      });
      return;
    }
    let transactionArray: any[] = [];
    transactions.forEach((transaction) => {
      const merchantid = transaction.ref.parent.parent?.id;
      //Change Reference to Path because of serialization problems
      const data = replaceReferencesWithPath(transaction.data());
      transactionArray.push({
        data: data as TransactionType,
        path: transaction.ref.path,
        merchantid: merchantid,
        id: transaction.id,
      });
    });
    setTransactionState((transactionState) => {
      return {
        ...transactionState,
        transactions: transactionState.transactions.concat(transactionArray),
        loading: false,
        ...(transactions.size < FIREBASE_REQUEST_LIMIT && {
          moreItemsExist: false,
        }),
      };
    });
  };

  const handleOnClick = useCallback(
    (link: string, transaction?: any) =>
      navigate(link, {
        state: { transaction: transaction },
      }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.TRANSACTION}
        query={transactionCollectionGroupRef()}
        updateQuery={updateTransactionQuery}
      />
      <LoadingComponent isLoading={transactionState.loading}>
        <Table
          loadMoreItems={loadMoreItems}
          moreItemsExist={transactionState.moreItemsExist}
          loadingMoreItems={transactionState.loadingMore}
          itemsTitle={[
            t("components.transactions.id"),
            t("components.transactions.created"),
            t("components.transactions.state"),
          ]}
        >
          {transactionState.transactions.map((transaction) => {
            return (
              <TableBody
                key={transaction.id}
                categoryTitle={`${t("components.abonnements.abonnement")}`}
                component={transaction}
                componentPath={
                  "atransactions/" +
                  transaction.merchantid +
                  "/" +
                  transaction.id
                }
                isClickable={true}
                onClick={handleOnClick}
                id={transaction.id}
                rows={3}
                itemsBody={[
                  {
                    title: t("components.transactions.id"),
                    value: transaction.id,
                  },
                  {
                    title: t("components.transactions.created"),
                    value: formatDateFromFirebaseTimestamp(
                      transaction.data.createTime
                    ),
                  },
                  {
                    title: t("components.transactions.state"),
                    value: translateTransactionState(transaction.data.status),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

export default Transaction;
