import { useTranslation } from "react-i18next";
import SettingsWrapper from "../../../components/SettingsWrapper";
import User from "./User";
import Documents from "./Documents";

const Organisation = () => {
  const { t } = useTranslation();
  return (
    <>
      <SettingsWrapper
        title={t("components.settings.organisation.organisation")}
      >
        <User />
        <div style={{ marginTop: 40 }} />
        <Documents />
      </SettingsWrapper>
    </>
  );
};

export default Organisation;
