import { useContext, useEffect } from "react";
import { SessionContext } from "../../context/SessionContext";
import Onboarding from "../Onboarding";
import { useLocation } from "react-router-dom";
import LoadingComponent from "../Loading/LoadingWrapper";

type OnboardingWrapperProps = {
  children: any;
};

const OnboardingWrapper = ({ children }: OnboardingWrapperProps) => {
  const { initialized, refreshMerchant } = useContext(SessionContext);

  useEffect(() => {
    refreshMerchant();
  }, []);
  const location = useLocation();
  const isExcludedPath = (): boolean => {
    const pathParts = location.pathname.split(`/`);
    if (pathParts.length > 2) {
      return [`settings/account`].includes(`${pathParts[1]}/${pathParts[2]}`);
    } else return false;
  };
  if (initialized === null) {
    return <LoadingComponent isLoading />;
  }

  return (
    <>{initialized === true || isExcludedPath() ? children : <Onboarding />}</>
  );
};

export default OnboardingWrapper;
