import { useContext, useEffect, useState } from "react";
import SettingCard from "../../../../components/SettingCard";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { Storage } from "../../../../firebase";
import firebase from "firebase/compat/app";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Documents = () => {
  const { mid } = useContext(AuthContext);
  const [update, setUpdate] = useState<boolean>(true);
  const [avvs, setAvvs] = useState<Array<{ name: string; url: string }> | null>(
    null
  );

  const storage = Storage();

  useEffect(() => {
    storage
      .ref()
      .child(`merchant/${mid}/avv/`)
      .listAll()
      .then(async (docs) => {
        const refArray: Array<firebase.storage.Reference> = [];
        const avvArray: Array<{ name: string; url: string }> = [];
        docs.items.forEach((item) => {
          refArray.push(item);
        });
        for (const item of refArray) {
          const name = `AV-Vertrag (${item.name
            .split("_")[1]
            .replace(".pdf", "")})`;
          const url = await item.getDownloadURL();
          avvArray.push({ name: name, url: url });
        }
        setAvvs(avvArray);
        console.log(docs.items.length);
      });
  }, [update, mid]);

  useEffect(() => {
    if (mid && mid !== null) {
      setUpdate(true);
    }
  }, [mid]);

  return (
    <SettingCard title="Dokumente">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "stretch",
          alignItems: "stretch",
          flexWrap: "wrap",
          gap: 2,
          width: "100%",
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="flex-start"
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
          style={{ width: "50%", marginTop: 10 }}
        >
          {avvs !== null &&
            avvs.map((avv) => {
              return (
                <>
                  <Grid item xs={1}>
                    <FontAwesomeIcon
                      icon={"file-pdf"}
                      size="1x"
                      color={"#000"}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    {avv.name}
                  </Grid>
                  <Grid item xs={2}>
                    <FontAwesomeIcon
                      icon={"arrow-up-right-from-square"}
                      style={{ cursor: "pointer" }}
                      size="1x"
                      color={"#000"}
                      onClick={() => {
                        window.open(avv.url, "_blank");
                      }}
                    />
                  </Grid>
                </>
              );
            })}
        </Grid>
      </div>
    </SettingCard>
  );
};

export default Documents;
