import firebase from "firebase/compat/app";
import { Amount } from "../Amount";
export enum PaymentState {
  Failed = -1, // If anything goes wrong
  Created, // Transaction created =>
  Open, // Order created (externalId!) =>
  Pending, // Payment created (ppId!) =>
  Paid, // Payment successful =>
  Complete, // Webhook successfully noitfied
}

export type Payment = {
  // /**
  //  * State of Paypment
  //  */
  // state: PaymentState

  // /**
  //  * PaymentProvider ID of this Payment
  //  */
  // ppId?: string

  // /**
  //  * Provide any data you like, and we will save the data alongside the subscription.
  //  * Whenever you fetch the subscription with our API, we will also include the metadata.
  //  * You can use up to METADATA_BYTE_LIMIT of JSON.
  //  */
  // metadata?: string

  // /**
  //  * Description of Payment
  //  */
  // description: string

  // /**
  //  * OPTIONAL
  //  * Webhook URL
  //  */
  // webhookUrl?: string

  // customerRef: firebase.firestore.DocumentReference

  // aboRef: firebase.firestore.DocumentReference

  /**
   * State of Transaction
   */
  status: PaymentState;

  /**
   * PaymentProvider ID of this Transaction
   */
  ppId?: string;

  amount?: Amount
  /**
   * External ID of this Transaction, e.g. order id
   */
  externalId?: string;

  customerRef: firebase.firestore.DocumentReference;

  aboRef: firebase.firestore.DocumentReference;

  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
  profileRef: firebase.firestore.DocumentReference;
};
