import Dropdown from "../Dropdown";
import firebase from "firebase/compat/app";
import { useRef, useState } from "react";
import Button from "../Button";
import { FaFilter } from "react-icons/fa";
import { useTranslation } from "react-i18next";

type FilterProps = {
  query: firebase.firestore.Query | firebase.firestore.CollectionReference;
  updateQuery: (
    query: firebase.firestore.Query | firebase.firestore.CollectionReference
  ) => void;
  filterType: FilterTypes;
};

export enum FilterTypes {
  CUSTOMER,
  ABONNEMENT,
  TRANSACTION,
}

enum Orders {
  DATE_DESC,
  DATE_ASC,
  NAME_DESC,
  NAME_ASC,
}

interface IFilterState {
  showFilter: boolean;
  status: number | null;
  order: Orders | null;
  name: string | null;
  mode: number | null;
  from: Date | null;
  to: Date | null;
  description: string | null; // Added description to the filter state
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();
  const statusDropdown = useRef<any>(null);
  const orderDropdown = useRef<any>(null);
  const modeDropdown = useRef<any>(null);
  const [filterState, setFilterState] = useState<IFilterState>({
    showFilter: false,
    status: null,
    order: null,
    name: null,
    mode: null,
    from: null,
    to: null,
    description: null, // Added description to the filter state
  });

  const resetFilter = () => {
    setFilterState({
      ...filterState,
      status: null,
      name: null,
      order: null,
      mode: null,
      from: null,
      to: null,
      description: null, // Reset description as part of the filter reset
    });
    try {
      statusDropdown.current.reset();
    } catch (e) {}
    try {
      orderDropdown.current.reset();
    } catch (e) {}
    try {
      modeDropdown.current.reset();
    } catch (e) {}
    if (props.filterType === FilterTypes.TRANSACTION) {
      props.updateQuery(props.query.orderBy("updateTime", "desc"));
    } else {
      props.updateQuery(props.query.orderBy("createTime", "desc"));
    }
  };

  const applyFilter = () => {
    let query: firebase.firestore.Query = props.query;
    if (filterState.name !== null) {
      query = query.where("name", "==", filterState.name);
    }
    if (filterState.status !== null) {
      query = query.where("status", "==", filterState.status);
    }
    if (filterState.mode !== null) {
      query = query.where("test", "==", Boolean(filterState.mode));
    }
    if (filterState.description !== null && filterState.description !== "") { // Added condition for description filtering
      query = query.where('description', '>=', filterState.description).where('description', '<=', filterState.description + '~');
    }
    if (filterState.from !== null && filterState.to !== null) {
      const timestampFrom = firebase.firestore.Timestamp.fromDate(
        new Date(filterState.from)
      );
      const timestampTo = firebase.firestore.Timestamp.fromDate(
        new Date(filterState.to)
      );
      query = query
        .orderBy("createTime", "desc")
        .where("createTime", ">=", timestampFrom)
        .where("createTime", "<=", timestampTo);
      props.updateQuery(query);
      return;
    }
    switch (filterState.order) {
      case 1:
        query = query.orderBy("createTime", "asc");
        break;
      case 2:
        query = query.orderBy("name", "desc");
        break;
      case 3:
        query = query.orderBy("name", "asc");
        break;
      case 0:
      default:
        query = query.orderBy("createTime", "desc");
        break;
    }
    props.updateQuery(query);
  };

  const onFilterChanged = (event: any, id: string) => {
    if (id === "status") {
      if (event.target.value === "") {
        setFilterState({
          ...filterState,
          status: null,
        });
      } else {
        setFilterState({
          ...filterState,
          status: Number(event.target.value),
        });
      }
    } else if (id === "order") {
      if (event.target.value === "") {
        setFilterState({
          ...filterState,
          order: null,
        });
      } else {
        setFilterState({
          ...filterState,
          order: Number(event.target.value),
        });
      }
    } else if (id === "mode") {
      if (event.target.value === "") {
        setFilterState({
          ...filterState,
          mode: null,
        });
      } else {
        setFilterState({
          ...filterState,
          mode: Number(event.target.value),
        });
      }
    } else if (id === "name") {
      if (event.target.value === "") {
        setFilterState({
          ...filterState,
          name: null,
        });
      } else {
        setFilterState({
          ...filterState,
          name: event.target.value,
        });
      }
    } else if (id === "from") {
      if (event.target.value === "") {
        setFilterState({
          ...filterState,
          from: null,
        });
      } else {
        setFilterState({
          ...filterState,
          from: event.target.value,
        });
      }
    } else if (id === "to") {
      if (event.target.value === "") {
        setFilterState({
          ...filterState,
          to: null,
        });
      } else {
        setFilterState({
          ...filterState,
          to: event.target.value,
        });
      }
    } else if (id === "description") { // Handle description change
      setFilterState({
        ...filterState,
        description: event.target.value,
      });
    }
  };

  const setFilter = (value: boolean) => {
    if (!value) {
      resetFilter();
    }
    setFilterState({
      ...filterState,
      showFilter: value,
    });
  };

  return (
    <div className="mx-12 flex flex-wrap justify-end gap-2 items-center">
      <div className="flex justify-end w-full mt-2">
        <button
          onClick={filterState.showFilter ? () => null : () => setFilter(true)}
          onFocus={() => null}
          style={{ borderWidth: 2 }}
          className={
            "flex p-1 m-0 mt-2 gap-1 justify-center text-black outline-hidden focus:outline-none " +
            (filterState.showFilter
              ? "bg-orange-600 text-white border-none cursor-default"
              : "border-solid border border-black hover:bg-black hover:text-white cursor-pointer")
          }
        >
          <div className="w-max h-max self-center">
            <FaFilter />
          </div>
          <p className="w-max ">{t("filter.filter")}</p>
          {filterState.showFilter ? (
            <div
              onClick={() => setFilter(false)}
              className="px-2 rounded-3xl hover:bg-white hover:text-orange-600 cursor-pointer"
            >
              X
            </div>
          ) : (
            <></>
          )}
        </button>
      </div>
      {filterState.showFilter ? (
        <div className="flex flex-wrap justify-end w-full gap-12 lg:gap-2">
          <div className="flex flex-wrap justify-end w-max gap-2 sm:w-full sm:justify-start">
          {[FilterTypes.ABONNEMENT, FilterTypes.TRANSACTION].includes(props.filterType) && (
              <div className="flex flex-wrap justify-center px-1 gap-2 w-max">
                <input
                  type="text"
                  placeholder={t("filter.description")}
                  value={filterState.description || ""}
                  onChange={(e) => onFilterChanged(e, "description")}
                  className="grow-0 p-1 rounded-xl text-base w-max box-border border-black border text-black"
                />
              </div>
            )}
            {[FilterTypes.ABONNEMENT].includes(props.filterType) ? (
              <div className="grow-0 w-max">
                <Dropdown
                  ref={statusDropdown}
                  onChangeListener={onFilterChanged}
                  className="rounded-xl w-full p-2 border-black border"
                  id="status"
                  options={[
                    { id: "status", value: "", name: "Status (alle)" },
                    {
                      id: "created",
                      value: "0",
                      name: t("states.abo.created"),
                    },
                    { id: "active", value: "1", name: t("states.abo.active") },
                    { id: "paused", value: "2", name: t("states.abo.paused") },
                    {
                      id: "finished",
                      value: "3",
                      name: t("states.abo.finished"),
                    },
                    { id: "error", value: "4", name: t("states.abo.error") },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
            {[FilterTypes.TRANSACTION].includes(props.filterType) ? (
              <div className="grow-0 w-max">
                <Dropdown
                  ref={statusDropdown}
                  onChangeListener={onFilterChanged}
                  className="rounded-xl w-full p-2 border-black border"
                  id="status"
                  options={[
                    { id: "status", value: "", name: "Status (alle)" },
                    {
                      id: "failed",
                      value: "-1",
                      name: t("states.transaction.failed"),
                    },
                    {
                      id: "created",
                      value: "0",
                      name: t("states.transaction.created"),
                    },
                    {
                      id: "open",
                      value: "1",
                      name: t("states.transaction.open"),
                    },
                    {
                      id: "pending",
                      value: "2",
                      name: t("states.transaction.pending"),
                    },
                    {
                      id: "paid",
                      value: "3",
                      name: t("states.transaction.paid"),
                    },
                    {
                      id: "complete",
                      value: "4",
                      name: t("states.transaction.complete"),
                    },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
            {[FilterTypes.ABONNEMENT].includes(props.filterType) ? (
              <div className="grow-0 w-max">
                <Dropdown
                  ref={modeDropdown}
                  onChangeListener={onFilterChanged}
                  className="rounded-xl w-full p-2 border-black border"
                  id="mode"
                  options={[
                    { id: "mode", value: "", name: t("filter.mode_all") },
                    { id: "live", value: "0", name: "LIVE" },
                    { id: "test", value: "1", name: "TEST" },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
            {[FilterTypes.CUSTOMER].includes(props.filterType) ? (
              <div className="grow-0 w-max">
                <Dropdown
                  ref={orderDropdown}
                  onChangeListener={onFilterChanged}
                  className="rounded-xl w-full p-2 border-black border"
                  id="order"
                  options={[
                    {
                      id: "order",
                      value: "",
                      name: t("filter.order.order_by"),
                    },
                    {
                      id: "date_desc",
                      value: "0",
                      name: t("filter.order.date_desc"),
                    },
                    {
                      id: "date_asc",
                      value: "1",
                      name: t("filter.order.date_asc"),
                    },
                    {
                      id: "name_desc",
                      value: "2",
                      name: t("filter.order.name_desc"),
                    },
                    {
                      id: "name_asc",
                      value: "3",
                      name: t("filter.order.name_asc"),
                    },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
            {[FilterTypes.ABONNEMENT, FilterTypes.TRANSACTION].includes(
              props.filterType
            ) ? (
              <div className="grow-0 w-max">
                <Dropdown
                  ref={orderDropdown}
                  onChangeListener={onFilterChanged}
                  className="rounded-xl w-full p-2 border-black border"
                  id="order"
                  options={[
                    {
                      id: "order",
                      value: "",
                      name: t("filter.order.order_by"),
                    },
                    {
                      id: "date_desc",
                      value: "0",
                      name: t("filter.order.date_desc"),
                    },
                    {
                      id: "date_asc",
                      value: "1",
                      name: t("filter.order.date_asc"),
                    },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
           
            {[
              FilterTypes.CUSTOMER,
              FilterTypes.ABONNEMENT,
              FilterTypes.TRANSACTION,
            ].includes(props.filterType) ? (
              <div className="flex flex-wrap justify-center px-1 gap-2 w-max bg-white border border-solid border-black rounded rounded-xl">
                <div className="flex flex-wrap gap-1 w-max">
                  <p className="self-center w-max">{t("filter.from")}</p>
                  <input
                    type="date"
                    onChange={(e) => onFilterChanged(e, "from")}
                    value={filterState.from?.toLocaleString() || ""}
                    className="grow-0  rounded-lg text-base w-max m-1 box-border border-black border text-black"
                  />
                </div>
                <div className="flex flex-wrap gap-1 w-max">
                  <p className="self-center w-max">{t("filter.to")}</p>
                  <input
                    type="date"
                    onChange={(e) => onFilterChanged(e, "to")}
                    value={filterState.to?.toLocaleString() || ""}
                    className="grow-0 m-1 rounded-lg text-base w-max box-border border-black border text-black"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex justify-end w-max gap-2 sm:w-full sm:justify-start">
            <Button
              title={t("filter.apply")}
              onClickListener={applyFilter}
              className="hover:bg-black_hover rounded-xl p-1 px-2 text-white border-0 m-0 cursor-pointer bg-black"
            />
            <Button
              title={t("filter.reset")}
              onClickListener={resetFilter}
              className="hover:bg-black_hover hover:text-white focus:outline-none m-0 rounded-xl p-1 px-2 text-black border-solid border-black border border-0 cursor-pointer"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Filter;
