import { useContext, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { AuthContext } from "../../../../components/Context/AuthContext";
import LoadingComponent from "../../../../components/Loading/LoadingWrapper";
import { COLL_KEY, merchantRef } from "../../../../utils/firestore";
import { Merchant } from "../../../../types/Merchant";
import { CLASS_NAME_MAIN } from "../../../../constants";
import DetailviewCustomer from "../../../../components/Detailview/Admin/Customer";
import firebase from "firebase/compat/app";
import { Profile } from "../../../../types/Profile";
import { replaceReferencesWithPath } from "../../../../utils/utils";
import { useLocation, useMatch } from "react-router-dom";
// import { useLocation, useMatch } from "react-router-dom-v5-compat";
// type ACustomerdetailProps = {
//   history: any;
//   location: any;
//   match: any;
// };
type IACustomerdetailState = {
  loading: boolean;
  merchantid: string;
  merchant: Merchant | null;
  keys: any[];
};

const CustomerDetail = () => {
  const [aCustomerdetailState, setACustomerdetailState] =
    useState<IACustomerdetailState>({
      loading: true,
      merchantid: "",
      merchant: null,
      keys: [],
    });
  const match = useMatch("/acustomers/*");
  const merchantid = match?.params[0];
  const auth = useContext(AuthContext);
  const location = useLocation();
  const firestore = useFirestore();
  useEffect(() => {
    const getAboDetails = async () => {
      setACustomerdetailState((aCustomerdetailState) => {
        return {
          ...aCustomerdetailState,
          loading: true,
        };
      });
      if (location.state && location.state.customer) {
        const keys = (await merchantRef(location.state.customer.id)
          .collection(COLL_KEY)
          .get()) as firebase.firestore.QuerySnapshot<Profile>;
        let keyArray: any[] = [];
        keys.docs.forEach((key) => {
          keyArray.push({
            data: replaceReferencesWithPath(key.data()) as Profile,
            path: key.ref.path,
            id: key.id,
          });
        });
        setACustomerdetailState((aCustomerdetailState) => {
          return {
            ...aCustomerdetailState,
            merchant: location.state.customer.data,
            merchantid: location.state.customer.id,
            keys: keyArray,
            loading: false,
          };
        });
        return;
      } else if (auth.authContextState.user) {
        const merchant = await merchantRef(merchantid).get();
        if (merchant.exists) {
          const keys = (await merchantRef(merchantid)
            .collection(COLL_KEY)
            .get()) as firebase.firestore.QuerySnapshot<Profile>;
          let keyArray: any[] = [];
          keys.docs.forEach((key) => {
            keyArray.push({
              data: replaceReferencesWithPath(key.data()) as Profile,
              path: key.ref.path,
              id: key.id,
            });
          });
          setACustomerdetailState((aCustomerdetailState) => {
            return {
              ...aCustomerdetailState,
              merchant: merchant.data() as Merchant,
              merchantid: merchant.id,
              keys: keyArray,
              loading: false,
            };
          });
        } else {
          setACustomerdetailState((aCustomerdetailState) => {
            return {
              ...aCustomerdetailState,
              loading: false,
            };
          });
        }
      }
    };
    getAboDetails();
  }, [auth.authContextState.user, merchantid, firestore, location.state]);

  return (
    <div className={CLASS_NAME_MAIN}>
      <LoadingComponent isLoading={aCustomerdetailState.loading}>
        <DetailviewCustomer
          id={merchantid}
          merchant={aCustomerdetailState.merchant}
          keys={aCustomerdetailState.keys}
        />
      </LoadingComponent>
    </div>
  );
};

export default CustomerDetail;
