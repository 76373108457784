import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { useFirestore } from "react-redux-firebase";
import { transactionCollectionRef } from "../../../../utils/firestore";
import { CLASS_NAME_MAIN } from "../../../../constants";
import LoadingComponent from "../../../../components/Loading/LoadingWrapper";
import DetailviewTransaction from "../../../../components/Detailview/Main/Transaction";
import { Transaction } from "../../../../types/Transaction";
import { Abo } from "../../../../types/Abo";
import firebase from "firebase/compat/app";
import { useLocation, useMatch } from "react-router-dom";

type ITransactiondetailState = {
  loading: boolean;
  hasError: boolean;
  transactionid: string;
  transaction: any;
  abo: any;
  aboid: string | undefined;
};

const TransactionDetail = () => {
  const [transactiondetailState, setTransactiondetailState] =
    useState<ITransactiondetailState>({
      loading: true,
      transactionid: "",
      hasError: false,
      transaction: undefined,
      abo: undefined,
      aboid: "",
    });
  const match = useMatch("/transaction/:id");
  const location = useLocation();
  const transactionid = match?.params.id || "n/a";
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();

  /*
  const reTryTransaction = (
    merchantid: string,
    customerid: string,
    aboid: string,
    transactionid: string
  ) => {
    setTransactiondetailState((transactiondetailState) => {
      return {
        ...transactiondetailState,
        hasError: false,
      };
    });
    const reDoTransaction = Functions().httpsCallable(
      "func-mollie-retryTransaction"
    );
    reDoTransaction({
      merchantid: merchantid,
      customerid: customerid,
      aboid: aboid,
      transactionid: transactionid,
    });
  };*/

  useEffect(() => {
    const getTransaction = async () => {
      if (location.state && location.state.transaction) {
        const aboRef: firebase.firestore.DocumentReference = firestore.doc(
          location.state.transaction.data.aboPath
        );
        const abo = await aboRef.get();
        setTransactiondetailState((transactiondetailState) => {
          return {
            ...transactiondetailState,
            transactionid: location.state.transaction.id,
            transaction: location.state.transaction.data as Transaction,
            abo: abo.data() as Abo,
            aboid: abo.id,
            loading: false,
          };
        });
      } else if (mid) {
        const transaction = await transactionCollectionRef(mid || "-")
          .doc(transactionid)
          .get();

        if (transaction.exists) {
          const abo = await transaction.data()?.aboRef.get();
          setTransactiondetailState((transactiondetailState) => {
            return {
              ...transactiondetailState,
              transactionid: transaction.id,
              transaction: transaction.data() as Transaction,
              abo: abo.data() as Abo,
              aboid: abo.id,
              loading: false,
            };
          });
        }

        setTransactiondetailState((transactiondetailState) => {
          return {
            ...transactiondetailState,
            loading: false,
          };
        });
      }
    };
    getTransaction();
  }, [
    location.state,
    transactionid,
    mid,
    firestore,
    transactiondetailState.transaction,
  ]);
  return (
    <div className={CLASS_NAME_MAIN}>
      <LoadingComponent isLoading={transactiondetailState.loading}>
        <DetailviewTransaction
          transaction={transactiondetailState.transaction}
          id={transactionid}
          abo={transactiondetailState.abo}
          aboid={transactiondetailState.aboid}
        />
      </LoadingComponent>
    </div>
  );
};

export default TransactionDetail;
