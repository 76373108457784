import Dinero from "dinero.js";
import { Amount } from "../types/Amount";

/**
 * Vat amount for 'de'
 */
export const VAT_AMOUNT_IN_PERCENT_DEFAULT = 19;

/**
 * Vat amount for EU
 */
export const VAT_AMOUNT_IN_PERCENT_EU = 0;

/**
 * Vat amount for non EU
 */
export const VAT_AMOUNT_IN_PERCENT_NOT_EU = 0;

export const calculateMwSt = (
  amount: Amount,
  countryCode = "de"
): { amount: Amount; mwst: Amount; totalAmount: Amount } => {
  const vatPercentage = getVatPercentage(countryCode);
  const amountDinero = Dinero({
    amount: parseInt(amount.value.replace(".", "")),
  });
  const mwst = amountDinero.percentage(vatPercentage);
  const totalAmount = amountDinero.add(mwst);
  return {
    amount: { currency: "EUR", value: amountDinero.toFormat("0,0.00") },
    mwst: { currency: "EUR", value: mwst.toFormat("0,0.00") },
    totalAmount: { currency: "EUR", value: totalAmount.toFormat("0,0.00") },
  };
};

export const getVatPercentage = (countryCode: string): number => {
  const eu = [
    "be",
    "bg",
    "cz",
    "dk",
    "ee",
    "ie",
    "el",
    "es",
    "fr",
    "hr",
    "it",
    "cy",
    "lv",
    "lt",
    "lu",
    "hu",
    "mt",
    "nl",
    "at",
    "pl",
    "pt",
    "ro",
    "si",
    "sk",
    "fi",
    "se",
  ];

  if (countryCode.toLowerCase() !== "de") {
    if (eu.includes(countryCode.toLowerCase())) {
      return VAT_AMOUNT_IN_PERCENT_EU;
    }
    return VAT_AMOUNT_IN_PERCENT_NOT_EU;
  }
  return VAT_AMOUNT_IN_PERCENT_DEFAULT;
};

export const getVatRate = (countryCode: string): string =>
  `${Number(getVatPercentage(countryCode)).toFixed(2)}`;
