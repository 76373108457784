import firebase from "firebase/compat/app";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BadgeAmount from "../../../../components/Badge/BadgeAmount";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { Abo, AboStatus } from "../../../../types/Abo";
import { aboCollectionRef } from "../../../../utils/firestore";
import { formatDateFromFirebaseTimestamp } from "../../../../utils/utils";
import { SessionContext } from "../../../../context/SessionContext";

interface INextTransactionState {
  abos: Abo[] | null;
}

const NextTransactions = () => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);

  const [nextTransactionState, setNextTransactionState] =
    useState<INextTransactionState>({
      abos: null,
    });

  useEffect(() => {
    const refreshData = async (
      data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    ) => {
      // const date = new Date().setHours(24, 0);
      // const timestamp = firebase.firestore.Timestamp.fromDate(new Date(date));
      // const abos = (await aboCollectionRef(auth.authContextState.user.uid)
      //   .where("nextExecution", "<", timestamp)
      //   .orderBy("nextExecution", "asc")
      //   .limit(5)
      //   .get()) as firebase.firestore.QuerySnapshot<Abo>;
      const abos = data as firebase.firestore.QuerySnapshot<Abo>;
      if (abos.empty) {
        setNextTransactionState((nextTransactionState) => {
          return {
            ...nextTransactionState,
            abos: null,
          };
        });
        return;
      }
      const aboArray = Array<Abo>();
      abos.forEach((abo) => {
        aboArray.push(abo.data());
      });
      setNextTransactionState((nextTransactionState) => {
        return {
          ...nextTransactionState,
          abos: aboArray,
        };
      });
    };
    const date = new Date().setHours(24, 0);
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date(date));
    aboCollectionRef(mid || "-")
      .where("nextExecution", "<", timestamp)
      .where(
        "profileRef",
        "==",
        firebase.firestore().doc(currentProfile?.path || "-/-")
      )
      .where("status", "in", [
        AboStatus.Active,
        AboStatus.Created,
        AboStatus.Finished,
      ])
      .orderBy("nextExecution", "asc")
      .limit(5)
      .onSnapshot((data) => {
        refreshData(data);
      });
  }, [mid, currentProfile?.path]);

  return !nextTransactionState.abos ? (
    <></>
  ) : (
    <div className="bg-white rounded rounded-xl p-4 flex flex-wrap">
      <p className="font-bold w-full text-left mb-4">
        {t("components.dashboard.shortly")}
      </p>
      <div className="flex flex-wrap gap-2">
        {nextTransactionState.abos?.map((abo) => {
          return (
            <div
              key={`${abo.createTime}`}
              className="w-full grid grid-cols-6 text-left transition ease-in-out delay-150"
            >
              <p className="w-max text-sm font-thin col-span-1">
                {formatDateFromFirebaseTimestamp(abo.nextExecution!, "HH:mm")}
              </p>
              <p className="w-full text-sm font-bold col-span-2 truncate ">
                {abo.description}
              </p>
              <p className="w-full text-sm font-bold col-span-2 truncate ">
                {abo.status}
              </p>
              <div className="col-span-1">
                <BadgeAmount amount={abo.amount} operator="+" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NextTransactions;
