import "./Main.css";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import SidebarFooterContent from "./SidebarFooterContent";
import logo from "./../../../assets/abocloud_logo.svg";
import {
  FaCog,
  FaMoneyBill,
  FaSync,
  FaTachometerAlt,
  FaUsers,
  FaBars,
} from "react-icons/fa";
import AdminWrapper from "../../../components/AdminWrapper";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Screens from "../../../components/Screens";
import ProfileSelector from "./ProfileSelector";
import RoleWrapper from "../../../components/RoleWrapper";
import { UserRole } from "../../../types/User";
import OnboardingWrapper from "../../../components/OnboardingWrapper";

interface IMainState {
  toggled: boolean;
}

type MainProps = {
  children: any;
};

const Main = (props: MainProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  // const path = location.pathname.split("/")[1];
  const mainPath = location.pathname.split("/")[1];
  const path = location.pathname;
  const [mainState, setMainState] = useState<IMainState>({
    toggled: false,
  });

  const toggleSidebar = () => {
    setMainState((mainState) => {
      return {
        ...mainState,
        toggled: !mainState.toggled,
      };
    });
  };
  return (
    <>
      <div className="bg-background">
        <ProSidebar
          className="sidebar "
          breakPoint="lg"
          toggled={mainState.toggled}
          onToggle={() => toggleSidebar()}
        >
          <div className="flex flex-col h-screen">
            <SidebarContent>
              <img className="m-auto mt-8 mb-8" src={logo} alt="Logo" />
              <AdminWrapper>
                <div className="w-full flex justify-center">
                  <p className="w-max font-thin">Adminbereich</p>
                </div>
              </AdminWrapper>
              <ProfileSelector />
              <Menu iconShape="round">
                <MenuItem className={path === "/" ? "item-selected" : ""}>
                  <FaTachometerAlt className="text-2xl mr-4 pb-1 inline-block " />{" "}
                  {t("components.overview")} <Link to="/" />
                </MenuItem>
                <AdminWrapper noAdmin>
                  <MenuItem
                    className={mainPath === "customer" ? "item-selected" : ""}
                  >
                    <FaUsers className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.customers.customers")}{" "}
                    <Link to="/customer" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper>
                  <MenuItem
                    className={
                      path === "/admin/customer" ? "item-selected" : ""
                    }
                  >
                    <FaUsers className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.customers.customers")}{" "}
                    <Link to="/admin/customer" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper noAdmin>
                  <MenuItem
                    className={mainPath === "abo" ? "item-selected" : ""}
                  >
                    <FaSync className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.abonnements.abonnements")} <Link to="/abo" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper>
                  <MenuItem
                    className={path === "/admin/abo" ? "item-selected" : ""}
                  >
                    <FaSync className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.abonnements.abonnements")}{" "}
                    <Link to="/admin/abo" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper noAdmin>
                  <MenuItem
                    className={
                      mainPath === "transaction" ? "item-selected" : ""
                    }
                  >
                    <FaMoneyBill className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.transactions.transactions")}{" "}
                    <Link to="/transaction" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper>
                  <MenuItem
                    className={
                      path === "/admin/transaction" ? "item-selected" : ""
                    }
                  >
                    <FaMoneyBill className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.transactions.transactions")}{" "}
                    <Link to="/admin/transaction" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper noAdmin>
                  <MenuItem
                    className={mainPath === "payment" ? "item-selected" : ""}
                  >
                    <FaMoneyBill className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.payments.payments")} <Link to="/payment" />
                  </MenuItem>
                </AdminWrapper>
                <AdminWrapper>
                  <MenuItem
                    className={path === "admin/payment" ? "item-selected" : ""}
                  >
                    <FaMoneyBill className="text-2xl mr-4 pb-1 inline-block " />
                    {t("components.payments.payments")}{" "}
                    <Link to="/admin/payment" />
                  </MenuItem>
                </AdminWrapper>
                <SubMenu
                  icon={<FaCog className="text-2xl mr-4 pb-1 inline-block " />}
                  title={`${t("components.settings.settings")}`}
                  className={path.split("/")[1] === "settings" ? "open" : ""}
                >
                  <AdminWrapper noAdmin>
                    <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                      <MenuItem
                        className={
                          path === "/settings/organisation"
                            ? "item-selected"
                            : ""
                        }
                      >
                        {t("components.settings.organisation.organisation")}
                        <Link to="/settings/organisation" />
                      </MenuItem>
                    </RoleWrapper>
                  </AdminWrapper>
                  <AdminWrapper noAdmin>
                    <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                      <MenuItem
                        className={
                          path === "/settings/payment" ? "item-selected" : ""
                        }
                      >
                        {t("components.settings.payments.payments")}
                        <Link to="/settings/payment" />
                      </MenuItem>
                    </RoleWrapper>
                  </AdminWrapper>
                  <AdminWrapper noAdmin>
                    <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                      <MenuItem
                        className={
                          path === "/settings/token" ? "item-selected" : ""
                        }
                      >
                        {t("components.settings.profiles.profiles")}
                        <Link to="/settings/token" />
                      </MenuItem>
                    </RoleWrapper>
                  </AdminWrapper>
                  <AdminWrapper noAdmin>
                    <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                      <MenuItem
                        className={
                          path === "/settings/tariff" ? "item-selected" : ""
                        }
                      >
                        {t("components.settings.tariff.tariff")}
                        <Link to="/settings/tariff" />
                      </MenuItem>
                    </RoleWrapper>
                  </AdminWrapper>
                  <AdminWrapper noAdmin>
                    <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                      <MenuItem
                        className={
                          path === "/settings/profile" ? "item-selected" : ""
                        }
                      >
                        {t("components.settings.mydata.mydata")}
                        <Link to="/settings/profile" />
                      </MenuItem>
                    </RoleWrapper>
                  </AdminWrapper>
                  <MenuItem
                    className={
                      path === "/settings/account" ? "item-selected" : ""
                    }
                  >
                    {t("components.settings.account.account")}
                    <Link to="/settings/account" />
                  </MenuItem>
                </SubMenu>
              </Menu>
            </SidebarContent>
            <div className="flex-grow"></div>
            <SidebarFooterContent />
          </div>
        </ProSidebar>
        <div className="hidden lg:flex flex-wrap w-full overflow-hidden bg-white rounded-b-2xl sticky top-0 sm:text-sm gap-0">
          <FaBars
            className="text-3xl ml-6 my-6 pb-1 inline-block "
            onClick={() => toggleSidebar()}
          />
          <p className="m-4 my-6 m-auto text-xl font-bold">abocloud</p>
        </div>
        <Screens />

        <div className="text-center min-h-screen max-h-screen flex overflow-scroll flex-col items-center bg-background">
          <OnboardingWrapper>{props.children}</OnboardingWrapper>
        </div>
      </div>
    </>
  );
};
export default Main;
