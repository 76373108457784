import { useContext, useEffect, useState } from "react";
import SettingCard from "../../../../components/SettingCard";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { useFirestore } from "react-redux-firebase";
import UserCard from "./UserCard";

const User = () => {
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();
  const [user, setUser] = useState<Array<Record<string, any>>>([]);
  const [update, setUpdate] = useState<boolean>(true);

  useEffect(() => {
    if (update === true) {
      firestore
        .collection(`merchants/${mid}/user`)
        .orderBy("role", "asc")
        .get()
        .then((_user) => {
          const userArray: Array<Record<string, any>> = [];
          _user.forEach((_userDoc) => {
            userArray.push({ ..._userDoc.data(), id: _userDoc.id });
          });
          setUser(userArray);
        })
        .finally(() => {
          setUpdate(false);
        });
    }
  }, [update]);

  useEffect(() => {
    if (mid && mid !== null) {
      setUpdate(true);
    }
  }, [mid]);

  return (
    <SettingCard title="User">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "stretch",
          alignItems: "stretch",
          flexWrap: "wrap",
          gap: 2,
          width: "100%",
        }}
      >
        {user.map((_user) => {
          return <UserCard user={_user} setUpdate={setUpdate} />;
        })}
      </div>
    </SettingCard>
  );
};

export default User;
