type ErrorProps = {
  errorMessage: string;
};

const Error = (props: ErrorProps) => {
  return (
      <div className="text-base p-1 border-error  border rounded-xl text-error">
        <p data-testid="error">{props.errorMessage}</p>
      </div>
  );
};
export default Error;
