import { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { AuthContext } from "../../../../components/Context/AuthContext";
import {
  getWeekStatisticsSales,
  ValueAtDay,
} from "../../../../utils/statistics";
import Loading from "../../../../components/Loading";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../../../context/SessionContext";

interface ISalesChartState {
  data: Array<ValueAtDay>;
  loading: boolean;
  ttleft: number;
  tttop: number;
  ttshow: boolean;
  ttvalue: string;
}

const SalesChart = () => {
  const tooltipRef = useRef<any>(null);
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);
  const { t } = useTranslation();
  const [salesChartState, setSalesChartState] = useState<ISalesChartState>({
    data: Array<ValueAtDay>(),
    loading: true,
    ttleft: 0,
    tttop: 0,
    ttshow: false,
    ttvalue: "",
  });

  useEffect(() => {
    const refreshData = async () => {
      try {
        const data = await getWeekStatisticsSales(
          mid || "-",
          currentProfile?.path || "-/-"
        );

        setSalesChartState((salesChartState) => {
          return {
            ...salesChartState,
            data: data,
            loading: false,
          };
        });
      } catch {
        setSalesChartState((salesChartState) => {
          return {
            ...salesChartState,
            loading: false,
          };
        });
      }
    };
    /*
    transactionCollectionRef(auth.authContextState.user.uid).onSnapshot(() => {
      refreshData();
    });*/
    refreshData();
  }, [mid, currentProfile?.path]);

  return (
    <div className="bg-white rounded rounded-2xl h-max w-full p-4 pr-8">
      {salesChartState.loading ? (
        <Loading />
      ) : (
        <>
          <p className="font-bold">
            {t("components.dashboard.statistic.sales.title")}
          </p>
          <Line
            key={Math.random()}
            data={{
              datasets: [
                {
                  backgroundColor: "black",
                  borderColor: "black",
                  label: `${t(
                    "components.dashboard.statistic.sales.tooltip_text"
                  )}`,
                  data: salesChartState.data,
                  tension: 0.2,
                  borderWidth: 5,
                },
              ],
            }}
            options={{
              parsing: { xAxisKey: "day", yAxisKey: "value" },
              scales: {
                y: {
                  type: "linear",
                  position: "left",
                  title: {
                    text: `${t("components.dashboard.statistic.sales.y_axis", {
                      currency: "€",
                    })}`,
                    display: true,
                  },
                },
                x: {
                  reverse: true,
                },
              },
              plugins: {
                legend: {
                  labels: {
                    font: {
                      size: 32,
                      family: "Comfortaa",
                      weight: "bold",
                    },
                  },
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  titleFont: {
                    size: 1,
                    family: "Comfortaa",
                    weight: "bold",
                  },
                  bodyFont: {
                    size: 18,
                    family: "Comfortaa",
                    weight: "bold",
                  },
                  displayColors: false,
                  callbacks: {
                    title: function () {
                      return "";
                    },
                    beforeLabel: () => {
                      return "";
                    },
                    beforeBody: () => {
                      return `${t(
                        "components.dashboard.statistic.sales.tooltip_text"
                      )}`;
                    },
                    label: (context) => {
                      return `${context.formattedValue} €`;
                    },
                  },
                },
              },
              layout: {
                padding: 0,
              },
            }}
          />
          {salesChartState.ttshow ? (
            <div ref={tooltipRef} className="bg-red-400 w-max">
              Test
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
export default SalesChart;
