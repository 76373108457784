import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Abo } from "../../../../types/Abo";
import { Transaction } from "../../../../types/Transaction";
import {
  calculateNextExecution,
  capitalize,
  formatDate,
  formatDateFromFirebaseTimestamp,
  translateAboState,
  translateTransactionState,
} from "../../../../utils/utils";
import BadgeAmount from "../../../Badge/BadgeAmount";
import BadgeMode from "../../../Badge/BadgeMode";

type DetailviewTransactionProps = {
  id: string;
  transaction: Transaction | undefined;
  abo: Abo | undefined;
  aboid: string | undefined;
  onClick?: (path: string, object: any) => {};
};

const TransactionDetailView = (props: DetailviewTransactionProps) => {
  const { t } = useTranslation();
  const getNextExecution = (date: any, abo: Abo): string => {
    if (date === undefined) {
      return (
        t("components.abonnement_details.execution_probably_at") +
        " " +
        formatDate(calculateNextExecution(abo), "DD.MM.YYYY")
      );
    } else if (date === null) {
      return t("components.abonnement_details.execution_date_now");
    } else {
      return formatDateFromFirebaseTimestamp(date);
    }
  };
  return (
    <>
      {props.transaction !== undefined ? (
        <div className="xl:p-4 ">
          <div className="text-left m-8 p-4 rounded rounded-xl bg-white w-1/2 xl:w-full xl:mx-auto">
            <div>
              <div className="flex flex-wrap justify-between">
                <p className="font-light text-xl">
                  {t("components.transaction_details.transaction_details")}
                </p>
                <p className="font-thin text-sm text-gray-700 w-max truncate">
                  {props.id}
                </p>
              </div>
              <hr className=" rounded rounded-2xl border-solid border-black" />
              <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                <>
                  <p className="font-light">
                    {t("components.transaction_details.created")}
                  </p>
                  <div className="font-bold col-span-2">
                    {formatDateFromFirebaseTimestamp(
                      props.transaction.createTime
                    )}
                  </div>
                </>
                <>
                  <p className="font-light">
                    {t("components.transaction_details.external_id")}
                  </p>
                  <div className="font-bold col-span-2">
                    {props.transaction.externalId || "n/a"}
                  </div>
                </>
                <>
                  <p className="font-light">
                    {t("components.transaction_details.pp_id")}
                  </p>
                  <div className="font-bold col-span-2">
                    {props.transaction.ppId || "n/a"}
                  </div>
                </>
                <>
                  <p className="font-light">
                    {t("components.transaction_details.payment_method")}
                  </p>
                  <div className="font-bold col-span-2">
                    {props.transaction.ppMethod &&
                    props.transaction.ppMethod !== null
                      ? capitalize(props.transaction.ppMethod)
                      : "n/a"}
                  </div>
                </>
                <>
                  <p className="font-light">
                    {t("components.transaction_details.state")}
                  </p>
                  <div className="font-bold col-span-2">
                    {translateTransactionState(props.transaction.status)}
                  </div>
                </>
              </div>
              <p className="font-light text-xl">
                {t("components.abonnement_details.abonnement_details")}
              </p>
              <hr className=" rounded rounded-2xl border-solid border-black" />
              {props.abo !== undefined ? (
                <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.id")}
                    </p>
                    <>
                      <Link
                        className="underline font-bold col-span-2"
                        to={"/abo/" + props.aboid}
                      >
                        <div className="font-bold  hover:font-black hover:cursor-pointer">
                          {props.aboid || "n/a"}
                        </div>
                      </Link>
                    </>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.description")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.description}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.start")}
                    </p>
                    <div className="font-bold col-span-2">
                      {formatDateFromFirebaseTimestamp(props.abo.startDate)}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.interval")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.interval}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.repeatitions")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.times
                        ? `${props.abo.count} ${t(
                            "components.abonnement_details.of"
                          )} ${props.abo.times}`
                        : `${props.abo.count}`}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.amount_products")}
                    </p>
                    <div className="font-bold col-span-2">
                      {props.abo.products?.length}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.amount")}
                    </p>
                    <div className="font-bold col-span-2">
                      <BadgeAmount amount={props.abo.amount} />
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.next_execution")}
                    </p>
                    <div className="font-bold col-span-2">
                      {getNextExecution(props.abo.nextExecution, props.abo)}
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.mode")}
                    </p>
                    <div className="font-bold col-span-2">
                      <BadgeMode isTest={props.abo.test} />
                    </div>
                  </>
                  <>
                    <p className="font-light">
                      {t("components.abonnement_details.state")}
                    </p>
                    <div className="font-bold col-span-2">
                      {translateAboState(props.abo.status)}
                    </div>
                  </>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-max m-auto p-4 mt-8">
          {t("components.abonnement_details.not_found", { id: props.id })}
        </div>
      )}
    </>
  );
};

export default TransactionDetailView;
