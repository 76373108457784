import { useEffect, useState } from "react";
import TariffSelection from "../../../Tariff/Selection";

const Tariff = ({ nextStep }: { nextStep: () => void }) => {
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (completed === true) {
      nextStep();
    }
  }, [completed, nextStep]);
  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <TariffSelection
        onComplete={() => {
          setCompleted(true);
        }}
      />
    </div>
  );
};

export default Tariff;
