import { createContext, useEffect, useState } from "react";
import { Auth, App, EmailAuthProvider } from "../../firebase";
import Loading from "../Loading";
import firebase from "firebase/compat/app";

type AuthContextProps = {
  children: any;
};

interface IAuthContext {
  signIn: (username: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signUp: (username: string, password: string) => Promise<any>;
  sendPasswordResetEmail: (email: string) => Promise<any>;
  updateProfileName: (name: string) => void;
  updateProfileEmail: (email: string) => void;
  isAdmin: () => boolean;
  getClaims: () => Promise<firebase.auth.IdTokenResult> | undefined;
  reauthenticateWithCredential: (
    email: string,
    password: string
  ) => Promise<any> | undefined;
  signOut: () => void;
  authContextState: { user: any; authPending: boolean };
  currentUser: string | null;
  mid: string | null;
}

interface AuthContextState {
  user: firebase.User | null;
  authPending: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  signIn: (_username: string, _password: string) => new Promise(() => {}),
  signInWithGoogle: () => new Promise(() => {}),
  signUp: (_username: string, _password: string) => new Promise(() => {}),
  sendPasswordResetEmail: (_email: string) => new Promise(() => {}),
  updateProfileName: (_name: string) => {},
  updateProfileEmail: (_email: string) => {},
  isAdmin: () => false,
  getClaims: () => new Promise(() => {}),
  reauthenticateWithCredential: (_email: string, password: string) =>
    new Promise(() => {}),
  signOut: () => {},
  authContextState: { user: null, authPending: false },
  currentUser: null,
  mid: null,
});

export const AuthProvider = (props: AuthContextProps) => {
  const [authContextState, setAuthContextState] = useState<AuthContextState>({
    user: null,
    authPending: true,
  });
  const googleProvider = new App.auth.GoogleAuthProvider();
  const [idTokenResult, setIdTokenResult] =
    useState<firebase.auth.IdTokenResult | null>(null);

  const signIn = (username: string, password: string) => {
    return Auth().signInWithEmailAndPassword(username, password);
  };
  const signInWithGoogle = () => {
    return Auth().signInWithPopup(googleProvider);
  };

  const signUp = (username: string, password: string) => {
    return Auth().createUserWithEmailAndPassword(username, password);
  };

  const sendPasswordResetEmail = (email: string) => {
    return Auth().sendPasswordResetEmail(email);
  };

  const updateProfileName = (name: string) => {
    return Auth().currentUser?.updateProfile({ displayName: name });
  };

  const updateProfileEmail = (email: string) => {
    return Auth()
      .currentUser?.updateEmail(email)
      .catch(() => {});
  };

  const isAdmin = () => {
    // return Auth().currentUser?.getIdTokenResult();
    return idTokenResult?.claims.admin === true;
  };

  const getClaims = () => {
    return Auth().currentUser?.getIdTokenResult();
  };

  const reauthenticateWithCredential = (email: string, password: string) => {
    const credential = EmailAuthProvider().credential(email, password);
    return Auth().currentUser?.reauthenticateWithCredential(credential);
  };

  const signOut = () => Auth().signOut();

  useEffect(() => {
    return Auth().onAuthStateChanged((userAuth) => {
      setAuthContextState({ user: userAuth, authPending: false });
      userAuth?.getIdTokenResult(true).then((result) => {
        setIdTokenResult(result);
      });
    });
  }, []);

  if (authContextState.authPending) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signIn: signIn,
        signInWithGoogle: signInWithGoogle,
        signUp: signUp,
        sendPasswordResetEmail: sendPasswordResetEmail,
        updateProfileName: updateProfileName,
        updateProfileEmail: updateProfileEmail,
        isAdmin: isAdmin,
        getClaims: getClaims,
        reauthenticateWithCredential: reauthenticateWithCredential,
        signOut: signOut,
        authContextState: authContextState,
        currentUser: authContextState?.user?.uid || null,
        mid: idTokenResult?.claims["mid"] || null,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
