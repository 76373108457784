import { SxProps, Tab, Tabs, Theme } from "@mui/material";
import { useState } from "react";
import { a11yProps } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import TabPanel from "../TabPanel";
import CompanyData from "./Tabs/CompanyData";
import Tariff from "./Tabs/Tariff";
import Summary from "./Tabs/Summary";
import logo from "./../../assets/abocloud_icon.svg";

const Onboarding = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const customTabStyle: SxProps<Theme> = {
    fontFamily: "Comfortaa",
    width: "33%",
    minWidth: "33%",
    textAlign: "left",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        className=""
        src={logo}
        alt="Logo"
        style={{ width: 100, marginTop: 30, marginBottom: 20 }}
      />
      <p style={{ fontSize: 30, fontWeight: "bold" }}>
        {value === 0
          ? t("components.onboarding.title")
          : value === 1
          ? t("components.onboarding.tariff.title")
          : t("components.onboarding.summary.title")}
      </p>
      {value === 0 && (
        <p style={{ fontSize: 20, fontWeight: "bold" }}>
          {t("components.onboarding.subtitle")}
        </p>
      )}
      <div
        style={{
          marginTop: 30,
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Tabs
          value={value}
          style={{
            marginBottom: 10,
            width: "100%",
          }}
          onChange={handleChange}
          sx={{
            "& .MuiButtonBase-root": {
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              float: "left",
              color: "black",
            },
            "& button.Mui-selected": {
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              float: "left",
              color: "black",
              fontWeight: "bold",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
              height: 6,
              borderRadius: 7,
              color: "black",
            },
          }}
        >
          <Tab
            sx={customTabStyle}
            disabled
            label={`1. ${t("components.onboarding.stepper.step_data")}`}
            {...a11yProps(0)}
          />
          <Tab
            sx={customTabStyle}
            disabled
            label={`2. ${t("components.onboarding.stepper.step_tariff")}`}
            {...a11yProps(1)}
          />
          <Tab
            sx={customTabStyle}
            disabled
            label={`3. ${t("components.onboarding.stepper.step_finalize")}`}
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel index={0} value={value}>
          <CompanyData nextStep={() => setValue(1)} />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <Tariff nextStep={() => setValue(2)} />
        </TabPanel>
        <TabPanel index={2} value={value}>
          <Summary previousStep={() => setValue(1)} />
        </TabPanel>
      </div>
    </div>
  );
};

export default Onboarding;
