import { Grid } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { DB, Functions } from "../../../../firebase";
import { AuthContext } from "../../../../components/Context/AuthContext";
import i18n from "../../../../locales/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSpring, animated } from "@react-spring/web";
import { BookingStatus } from "../../../../types/Management/Booking";
import { translateBookingState } from "../../../../utils/utils";

const CurrentTariff = () => {
  const { mid } = useContext(AuthContext);
  const { t } = useTranslation();
  const [tariff, setTariff] = useState<Record<string, any> | null>(null);
  const [booking, setBooking] = useState<Record<string, any> | null>(null);
  const currentLanguage = i18n.language;
  const tariffInfo: Array<string> = tariff?.details[currentLanguage] || [];
  const cancelBooking = Functions().httpsCallable(
    "management-calls-booking-cancel"
  );

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });

  const cancel = async () => {
    const response = await cancelBooking({ bid: booking?.id });
    console.log(response.data);
  };

  useEffect(() => {
    DB()
      .collection(`merchants/${mid}/booking`)
      .get()
      .then((bookings) => {
        if (bookings.size > 0) {
          setBooking({ ...bookings.docs[0].data(), id: bookings.docs[0].id });
          bookings.docs[0]
            .data()
            .tariffRef.get()
            .then((tariff) => {
              setTariff(tariff.data());
            });
        } else {
          DB()
            .collection(`tariff`)
            .doc(`default`)
            .get()
            .then((tariff) => {
              setTariff(tariff.data() || null);
            });
        }
      });
  }, [mid]);

  if (tariff === null) return null;

  return (
    <animated.div
      style={{
        // width: "45%",
        // borderRadius: 15,
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: "#000",
        // backgroundColor: "#FFF",
        // minHeight: 200,
        // marginTop: 30,
        // flexDirection: "column",
        // justifyContent: "flex-start",
        maxWidth: "100%",
        ...springs,
      }}
      className="rounded-xl border border-black border-solid bg-white min-h-20 mt-18 flex flex-col justify-start w-1/2 sm:w-full"
    >
      <div
        style={{
          display: "flex",
          flex: "wrap",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: "65%",
            margin: 15,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "baseline",
            alignItems: "baseline",
          }}
        >
          <div
            style={{
              marginBottom: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: tariff.color,
                textAlign: "left",
                fontSize: 39,
                fontWeight: "bold",
              }}
            >
              {`${tariff.name}`}
            </p>
            {booking?.status && (
              <div
                style={{
                  marginLeft: 15,
                  borderColor: "#000",
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 0.5,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              >
                <p
                  style={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: 12,
                    fontWeight: "lighter",
                  }}
                >
                  {translateBookingState(
                    booking?.status || BookingStatus.Deleted
                  ).toLowerCase()}
                </p>
              </div>
            )}
          </div>
          <p
            style={{
              color: "#000",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "lighter",
            }}
          >
            {tariff.description}
          </p>
          <div style={{ marginTop: 15 }}>
            <Grid
              container
              spacing={0}
              alignItems="center"
              style={{ marginTop: 30 }}
            >
              {tariffInfo.map((info, index) => {
                return (
                  <Fragment key={`${index}_${info}_0`}>
                    <Grid item xs={2} lg={1} key={`${index}_${info}_1`}>
                      <FontAwesomeIcon
                        icon={"check-circle"}
                        size="lg"
                        color={tariff.color}
                      />
                    </Grid>
                    <Grid item xs={10} lg={5} key={`${index}_${info}_2`}>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontSize: 14,
                          textAlign: "left",
                        }}
                      >
                        {info}
                      </p>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "flex-end",
            alignItems: "flex-end",
            width: "30%",
            margin: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "baseline",
              alignItems: "baseline",
              width: "30%",
            }}
          >
            <p
              style={{ color: tariff.color, fontSize: 55, fontWeight: "bold" }}
            >
              {`${
                +tariff.price.value.split(".")[1] > 0
                  ? `${tariff.price.value}`
                  : `${tariff.price.value.split(".")[0]}`
              }€`}
            </p>
          </div>
          <Grid
            container
            spacing={0}
            alignItems="flex-end"
            justifyContent={"flex-end"}
            alignContent={"flex-end"}
            style={{ marginTop: 30 }}
          >
            <Grid item lg={7} sm={12}>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {`${t("components.settings.tariff.payment_type")}`}
              </p>
            </Grid>
            <Grid item lg={5} sm={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {booking?.interval === "12 months"
                  ? `${t("components.settings.tariff.yearly")}`
                  : `${t("components.settings.tariff.monthly")}`}
              </p>
            </Grid>
            <Grid item lg={7} sm={12}>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {`${t("components.settings.tariff.payment_method")}`}
              </p>
            </Grid>
            <Grid item lg={5} sm={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {booking?.meta.ppMethod.charAt(0).toUpperCase() +
                  booking?.meta.ppMethod.slice(1)}
              </p>
            </Grid>
            <Grid item lg={7} sm={12}>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {`${t("components.settings.tariff.booked")}`}
              </p>
            </Grid>
            <Grid item lg={5} sm={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {`${moment(booking?.createTime.toDate()).format("DD.MM.YYYY")}`}
              </p>
            </Grid>
            <Grid item lg={7} sm={12}>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {[BookingStatus.Active].includes(booking?.status)
                  ? `${t("components.settings.tariff.next_payment")}`
                  : `${t("components.settings.tariff.valid_until")}`}
              </p>
            </Grid>
            <Grid item lg={5} sm={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {booking?.validUntil
                  ? `${moment(booking?.validUntil.toDate()).format(
                      "DD.MM.YYYY"
                    )}`
                  : `-`}
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ height: 0.5, width: "100%", backgroundColor: "#AAA" }} />
      <div
        style={{
          minHeight: 60,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        {tariff.canBeBooked === true &&
          booking?.status === BookingStatus.Active && (
            <div
              style={{
                backgroundColor: "#000",
                width: 100,
                padding: 5,
                borderRadius: 10,
                cursor: "pointer",
              }}
              onClick={cancel}
            >
              <p
                style={{
                  color: "#FFF",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Kündigen
              </p>
            </div>
          )}
      </div>
    </animated.div>
  );
};

export default CurrentTariff;
