import { useContext, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
// import { DB, Functions } from "../../../../firebase";
import Error from "../../../../components/Error";
// import { COLL_USER } from "../../../../utils/firestore";

interface ILoginState {
  email: string;
  password: string;
  errorComponent: any;
}

type LoginProps = {
  setForgotPasswordState: (state: boolean) => void;
};

const LoginComponent = (props: LoginProps) => {
  // const initializeMerchant = Functions().httpsCallable(
  //   "calls-merchant-initialize"
  // );
  const [loginState, setLoginState] = useState<ILoginState>({
    email: "",
    password: "",
    errorComponent: null,
  });
  const auth = useContext(AuthContext);

  const onLoginDataChanged = (event: any, id: string) => {
    if (id === "email") {
      setLoginState({ ...loginState, email: event.target.value });
    } else if (id === "password") {
      setLoginState({ ...loginState, password: event.target.value });
    } else {
      return;
    }
  };

  const login = () => {
    auth
      .signIn(loginState.email, loginState.password)
      .then(() => {
        setLoginState({ ...loginState, errorComponent: null });
      })
      .catch((error) => {
        setLoginState({
          ...loginState,
          errorComponent: <Error errorMessage={error.message} />,
        });
      });
    return false;
  };

  // const loginWithGoogle = async () => {
  //   auth.signInWithGoogle().then(async (credentials) => {
  //     const user = credentials.user;
  //     if (user) {
  //       const userSnapshot = await DB().collectionGroup(COLL_USER).get();
  //       if (userSnapshot.size === 0) {
  //         initializeMerchant({
  //           firstname: `${user.displayName.split(" ")[0]}`,
  //           lastname: `${user.displayName.split(" ")[1]}`,
  //         });
  //         // DB()
  //         //   .collection("merchants")
  //         //   .doc(user.uid)
  //         //   .set({
  //         //     userid: user.uid,
  //         //     firstname: user.displayName.split(" ")[0],
  //         //     lastname: user.displayName.split(" ")[1],
  //         //   });
  //       }
  //     }
  //   });
  // };

  return (
    <div>
      <p className=" mt-16 text-3xl mb-32 font-bold">Willkommen bei abocloud</p>
      {loginState.errorComponent}
      <div className="block w-full my-2 box-border text-center">
        <input
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              login();
            }
          }}
          onChange={(event) => onLoginDataChanged(event, "email")}
          type="email"
          id="email"
          placeholder="E-Mail"
          className="block mx-auto rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
        />
        <input
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              login();
            }
          }}
          onChange={(event) => onLoginDataChanged(event, "password")}
          type="password"
          id="password"
          placeholder="Passwort"
          className="block mx-auto rounded-lg w-full text-base p-2 my-2 box-border border-black border text-black"
        />
        <input
          onClick={() => login()}
          type="submit"
          id="login"
          data-testid="login"
          value="einloggen"
          className="block mx-auto rounded-lg p-4 w-full text-base my-8 box-border text-white bg-black cursor-pointer"
        />

        {/* <input
          onClick={() => loginWithGoogle()}
          type="submit"
          id="loginGoogle"
          value="mit Google einloggen"
          className="block mx-auto rounded-lg p-4 w-full text-base my-8  box-border text-white bg-black cursor-pointer"
        /> */}
        <p
          onClick={() => props.setForgotPasswordState(true)}
          className="cursor-pointer"
        >
          Passwort vergessen?
        </p>
      </div>
    </div>
  );
};

export default LoginComponent;
