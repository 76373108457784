import { FaExternalLinkAlt } from "react-icons/fa";
type ExternalLinkProps = {
  link: string;
};

const ExternalLink = (props: ExternalLinkProps) => {
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      onClick={() => openInNewTab(props.link)}
      className="w-max hover:bg-gray-300 rounded rounded-xl cursor-pointer text-sm p-1"
    >
      <FaExternalLinkAlt />
    </div>
  );
};

export default ExternalLink;
