type ModalProps = {
  isOpen: boolean;
  title: any;
  onRequestClose: () => void;
  body?: any;
  footer?: any;
};

const Modal = (props: ModalProps) => {
  const handleChildClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  return props.isOpen ? (
    <div
      onClick={() => props.onRequestClose()}
      className="block h-screen w-auto z-50 inset-0 bg-black_rgba fixed"
    >
      <div className="flex w-full h-screen justify-center content-center fixed">
        <div
          onClick={(e) => handleChildClick(e)}
          className="flex flex-col justify-start flex-wrap rounded rounded-xl bg-white text-black p-5 m-2 self-center w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-full"
        >
          <div className="text-xl font-bold w-max mb-2">{props.title}</div>
          <div className="w-max">{props.body}</div>
          <div className="w-max">{props.footer}</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
