import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import {
  aboCollectionRef,
  transactionCollectionGroupRef,
} from "../../../../utils/firestore";
import { useFirestore } from "react-redux-firebase";

import firebase from "firebase/compat/app";
import { CLASS_NAME_MAIN } from "../../../../constants";
import LoadingComponent from "../../../../components/Loading/LoadingWrapper";
import { replaceReferencesWithPath } from "../../../../utils/utils";
import DetailviewAbo from "../../../../components/Detailview/Admin/Abo";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

type IAbodetailState = {
  loading: boolean;
  aboid: string;
  abo: any;
  merchantid: any;
  shopName: string;
  shopUrl: string;
  transactions: any[];
  transactions_loading: boolean;
};

const AboDetail = () => {
  const [abodetailState, setAbodetailState] = useState<IAbodetailState>({
    loading: true,
    aboid: "",
    abo: undefined,
    merchantid: undefined,
    shopName: "",
    shopUrl: "",
    transactions: [],
    transactions_loading: true,
  });
  const match = useMatch("/aabos/:merchantid/:aboid");
  const merchantParam = match?.params.merchantid || "n/a";
  const aboParam = match?.params.aboid || "n/a";
  const auth = useContext(AuthContext);
  const firestore = useFirestore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const getAboDetails = async () => {
      if (location.state && location.state.abo) {
        const aboPath: string = location.state.abo.path;
        const aboRef: firebase.firestore.DocumentReference =
          firestore.doc(aboPath);
        const merchantid = aboRef.parent.parent?.id;
        setAbodetailState((abodetailState) => {
          return {
            ...abodetailState,
            abo: location.state.abo.data,
            aboid: location.state.abo.id,
            merchantid: merchantid,
            loading: false,
          };
        });
        transactionCollectionGroupRef()
          .where("aboRef", "==", aboRef)
          .orderBy("updateTime", "asc")
          .get()
          .then((transactions) => {
            let transactionArray: any[] = [];
            transactions.docs.forEach((transaction) => {
              transactionArray.push({
                data: replaceReferencesWithPath(transaction.data()),
                path: transaction.ref.path,
                id: transaction.id,
              });
            });
            setAbodetailState((abodetailState) => {
              return {
                ...abodetailState,
                transactions: transactionArray,
                transactions_loading: false,
              };
            });
          });
        return;
      } else if (auth.authContextState.user) {
        const abo = await aboCollectionRef(merchantParam).doc(aboParam).get();

        const merchantid = abo.ref.parent.parent?.id;

        if (abo.exists) {
          setAbodetailState((abodetailState) => {
            return {
              ...abodetailState,
              abo: abo.data(),
              aboid: abo.id,
              merchantid: merchantid,
              loading: false,
            };
          });
          const transactions = await transactionCollectionGroupRef()
            .where("aboRef", "==", abo.ref)
            .orderBy("updateTime", "asc")
            .get();

          let transactionArray: any[] = [];
          transactions.forEach((transaction) => {
            transactionArray.push({
              data: replaceReferencesWithPath(transaction.data()),
              path: transaction.ref.path,
              id: transaction.id,
            });
          });
          setAbodetailState((abodetailState) => {
            return {
              ...abodetailState,
              transactions: transactionArray,
              transactions_loading: false,
            };
          });
        }

        setAbodetailState((abodetailState) => {
          return {
            ...abodetailState,
            loading: false,
          };
        });
      }
    };
    getAboDetails();
  }, [
    auth.authContextState.user,
    aboParam,
    merchantParam,
    firestore,
    location.state,
  ]);

  const handleOnClick = useCallback(
    (link: string, transaction?: any) =>
      navigate(link, {
        state: { transaction: transaction },
      }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <LoadingComponent isLoading={abodetailState.loading}>
        <DetailviewAbo
          merchantid={abodetailState.merchantid}
          abo={abodetailState.abo}
          id={aboParam}
          onClick={handleOnClick}
          transactions={abodetailState.transactions}
        ></DetailviewAbo>
      </LoadingComponent>
    </div>
  );
};

export default AboDetail;
