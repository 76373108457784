import "./App.css";
import Routes from "./routes/Router";
import "./locales/i18n";

const App = () => {
  //############ Adds Freshworks Support-Widget ##################
  //##############################################################
  const scriptCode = document.createElement("script");
  scriptCode.innerHTML = `window.fwSettings = {
    widget_id: 44000004396,
  };
  !(function () {
    if ("function" != typeof window.FreshworksWidget) {
      var n = function () {
        n.q.push(arguments);
      };
      (n.q = []), (window.FreshworksWidget = n);
    }
  })();`;
  document.body.appendChild(scriptCode);

  const script = document.createElement("script");
  script.src = `https://widget.freshworks.com/widgets/44000004396.js`;
  script.type = `text/javascript`;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
  //##############################################################
  //##############################################################

  return <Routes />;
};

export default App;
