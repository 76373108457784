import firebase from "firebase/compat/app";

export enum ProfileStatus {
  Requested, // Needs to be activated by AboCloud Admin
  Valid, // Key can be used
  Disabled, // Key was disabled by AboCloud Admin
}

export type Profile = {
  key: string;
  name: string;
  status: ProfileStatus;
  url: string;
  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
};
