import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { useFirestore } from "react-redux-firebase";
import { transactionCollectionRef } from "../../../../utils/firestore";
import { CLASS_NAME_MAIN } from "../../../../constants";
import LoadingComponent from "../../../../components/Loading/LoadingWrapper";
import { Transaction } from "../../../../types/Transaction";
import { Abo } from "../../../../types/Abo";
import firebase from "firebase/compat/app";
import DetailviewTransaction from "../../../../components/Detailview/Admin/Transaction";
import { useLocation, useMatch } from "react-router-dom";

type ITransactiondetailState = {
  loading: boolean;
  hasError: boolean;
  transactionid: string;
  transaction: any;
  abo: any;
  aboid: string | undefined;
  merchantid: string | undefined;
};

const TransactionDetail = () => {
  const [transactiondetailState, setTransactiondetailState] =
    useState<ITransactiondetailState>({
      loading: true,
      transactionid: "",
      hasError: false,
      transaction: undefined,
      abo: undefined,
      aboid: "",
      merchantid: "",
    });
  const location = useLocation();
  const match = useMatch("/atransactions/:merchantid/:transactionid");
  const transactionid = match?.params.transactionid || "n/a";
  const auth = useContext(AuthContext);
  const firestore = useFirestore();

  useEffect(() => {
    const getTransaction = async () => {
      if (location.state && location.state.transaction) {
        const aboRef: firebase.firestore.DocumentReference = firestore.doc(
          location.state.transaction.data.aboPath
        );
        const merchantid = aboRef.parent.parent?.id;
        const abo = await aboRef.get();
        setTransactiondetailState((transactiondetailState) => {
          return {
            ...transactiondetailState,
            transactionid: location.state.transaction.id,
            transaction: location.state.transaction.data as Transaction,
            abo: abo.data() as Abo,
            aboid: abo.id,
            merchantid: merchantid,
            loading: false,
          };
        });
      } else if (auth.authContextState.user) {
        const transaction = await transactionCollectionRef(
          auth.authContextState.user.uid
        )
          .doc(transactionid)
          .get();

        if (transaction.exists) {
          const abo = await transaction.data()?.aboRef.get();
          setTransactiondetailState((transactiondetailState) => {
            return {
              ...transactiondetailState,
              transactionid: transaction.id,
              transaction: transaction.data() as Transaction,
              abo: abo.data() as Abo,
              aboid: abo.id,
              loading: false,
            };
          });
        }

        setTransactiondetailState((transactiondetailState) => {
          return {
            ...transactiondetailState,
            loading: false,
          };
        });
      }
    };
    getTransaction();
  }, [
    location.state,
    transactionid,
    auth.authContextState.user,
    firestore,
    transactiondetailState.transaction,
  ]);
  return (
    <div className={CLASS_NAME_MAIN}>
      <LoadingComponent isLoading={transactiondetailState.loading}>
        <DetailviewTransaction
          merchantid={transactiondetailState.merchantid}
          transaction={transactiondetailState.transaction}
          id={transactionid}
          abo={transactiondetailState.abo}
          aboid={transactiondetailState.aboid}
        />
      </LoadingComponent>
    </div>
  );
};

export default TransactionDetail;
