import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/performance";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/remote-config";

import firebaseConfig from "./firebaseConfig.json";

let app: firebase.app.App;
const Firebase = (): firebase.app.App => {
  if (!firebase.apps.length || !app) {
    app = firebase.initializeApp(firebaseConfig);
    app.firestore();
    app.storage();
    app.auth();
    // firebase.storage();
    if (process.env.NODE_ENV === "production") {
      app.analytics();
      app.performance();
    } else {
      if (false) {
        app.functions().useEmulator("127.0.0.1", 5001);
        app.auth().useEmulator("http://localhost:9099");
        app.firestore().settings({ host: "localhost:8081", ssl: false });
      }
    }
  }
  return app;
};
export const DB = () => Firebase().firestore();
export const Auth = () => Firebase().auth();
export const Storage = () => Firebase().storage();
export const App = firebase;
export const EmailAuthProvider = () => firebase.auth.EmailAuthProvider;
export const Functions = () =>
  process.env.NODE_ENV === "production"
    ? Firebase().functions("europe-west3")
    : //cloudfunction emulator has no region parameter to prevent using global cloudfunction
      Firebase().functions("europe-west3");

/*let _remoteConfig: firebase.remoteConfig.RemoteConfig | null = null;
export const RemoteConfig = (): firebase.remoteConfig.RemoteConfig => {
    if (!_remoteConfig) {
        _remoteConfig = Firebase().remoteConfig();
        _remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    }
    return _remoteConfig;
};
*/

export default Firebase;

export const uiConfig = {
  signInSuccessUrl: "/",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: "/tos",
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign("/pp");
  },
};
