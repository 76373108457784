export type CountryType = {
  id: number;
  name: string;
};

const countryCode = {
  4: { code: "af", name: "Afghanistan" },
  1383: { code: "eg", name: "Ägypten" },
  1468: { code: "ax", name: "Åland" },
  1347: { code: "al", name: "Albanien" },
  1458: { code: "dz", name: "Algerien" },
  1469: { code: "as", name: "Amerikanisch-Samoa" },
  1559: { code: "vi", name: "Amerikanische Jungferninseln" },
  1418: { code: "ad", name: "Andorra" },
  1466: { code: "ao", name: "Angola" },
  1467: { code: "ai", name: "Anguilla" },
  1470: { code: "aq", name: "Antarktis" },
  1449: { code: "ag", name: "Antigua und Barbuda" },
  1503: { code: "gq", name: "Äquatorialguinea" },
  5: { code: "ar", name: "Argentinien" },
  1348: { code: "am", name: "Armenien" },
  1465: { code: "aw", name: "Aruba" },
  33: { code: "az", name: "Aserbaidschan" },
  1399: { code: "et", name: "Äthiopien" },
  38: { code: "au", name: "Australien" },
  1426: { code: "bs", name: "Bahamas" },
  1427: { code: "bh", name: "Bahrain" },
  1440: { code: "bd", name: "Bangladesch" },
  1445: { code: "bb", name: "Barbados" },
  6: { code: "be", name: "Belgien" },
  46: { code: "bz", name: "Belize" },
  1437: { code: "ben", name: "Benin" },
  1472: { code: "bj", name: "Benin" },
  1476: { code: "bm", name: "Bermuda" },
  1350: { code: "bt", name: "Bhutan" },
  1477: { code: "bo", name: "Bolivien" },
  53: { code: "ba", name: "Bosnien" },
  1396: { code: "ba", name: "Bosnien-Herzegowina" },
  1481: { code: "bw", name: "Botswana" },
  1480: { code: "bv", name: "Bouvetinsel" },
  73: { code: "br", name: "Brasilien" },
  1391: { code: "vg", name: "Britische Jungferninseln" },
  1514: { code: "io", name: "Britisches Territorium im Indischen Ozean" },
  1479: { code: "bn", name: "Brunei" },
  7: { code: "bg", name: "Bulgarien" },
  1473: { code: "bf", name: "Burkina Faso" },
  1404: { code: "bi", name: "Burundi" },
  49: { code: "cl", name: "Chile" },
  1486: { code: "ck", name: "Cookinseln" },
  1421: { code: "cr", name: "Costa Rica" },
  1455: { code: "cw", name: "Curacao" },
  8: { code: "dk", name: "Dänemark" },
  1: { code: "de", name: "Deutschland" },
  1491: { code: "dm", name: "Dominica" },
  1447: { code: "do", name: "Dominikanische Republik" },
  1405: { code: "dj", name: "Dschibuti" },
  67: { code: "du", name: "Dubai " },
  1492: { code: "ec", name: "Ecuador" },
  1434: { code: "EC", name: "Ekuador" },
  1450: { code: "sv", name: "El Salvador" },
  1381: { code: "ci", name: "Elfenbeinküste" },
  74: { code: "england", name: "England" },
  1406: { code: "er", name: "Eritrea" },
  72: { code: "ee", name: "Estland" },
  1390: { code: "ee", name: "Estland" },
  1495: { code: "fk", name: "Falklandinseln" },
  1496: { code: "fo", name: "Färöer-Inseln" },
  1494: { code: "fj", name: "Fidschi" },
  10: { code: "fi", name: "Finnland" },
  11: { code: "fr", name: "Frankreich" },
  1507: { code: "gf", name: "Französisch Guyana" },
  1351: { code: "pf", name: "Französisch-Polynesien" },
  1471: { code: "tf", name: "Französische Süd-und Antarktisgebiete" },
  1498: { code: "ga", name: "Gabun" },
  1501: { code: "gm", name: "Gambia" },
  1394: { code: "ge", name: "Georgien" },
  1382: { code: "gh", name: "Ghana" },
  1377: { code: "gib", name: "Gibraltar" },
  1500: { code: "gi", name: "Gibraltar" },
  1402: { code: "wg", name: "Grenada" },
  1504: { code: "gd", name: "Grenada" },
  12: { code: "gr", name: "Griechenland" },
  1505: { code: "gl", name: "Grönland" },
  9: { code: "gb", name: "Großbritannien" },
  1438: { code: "gp", name: "Guadeloupe" },
  1508: { code: "gu", name: "Guam" },
  1506: { code: "gt", name: "Guatemala" },
  1499: { code: "gg", name: "Guernsey" },
  1376: { code: "gn", name: "Guinea" },
  1502: { code: "gw", name: "Guinea-Bissau" },
  1509: { code: "gy", name: "Guyana" },
  1512: { code: "ht", name: "Haiti" },
  1510: { code: "hm", name: "Heard und die McDonaldinseln" },
  1511: { code: "hn", name: "Honduras" },
  59: { code: "hk", name: "Hong Kong" },
  62: { code: "in", name: "India" },
  1375: { code: "in", name: "Indien" },
  1374: { code: "id", name: "Indonesien" },
  1513: { code: "im", name: "Insel Man" },
  1419: { code: "iq", name: "Irak" },
  1373: { code: "ir", name: "Iran" },
  13: { code: "ie", name: "Irland" },
  51: { code: "is", name: "Island" },
  36: { code: "il", name: "Israel" },
  14: { code: "it", name: "Italien" },
  15: { code: "jm", name: "Jamaika" },
  40: { code: "jp", name: "Japan" },
  1459: { code: "ye", name: "Jemen" },
  1441: { code: "jey", name: "Jersey" },
  1515: { code: "je", name: "Jersey" },
  1344: { code: "jo", name: "Jordanien" },
  1460: { code: "ky", name: "Kaimaninseln" },
  1401: { code: "kh", name: "Kambodscha" },
  1400: { code: "cm", name: "Kamerun" },
  39: { code: "ca", name: "Kanada" },
  1423: { code: "ca", name: "Kanada" },
  1487: { code: "cv", name: "Kap Verde" },
  1478: { code: "bq", name: "Karibische Niederlande" },
  56: { code: "kz", name: "Kasachstan" },
  1371: { code: "qa", name: "Katar" },
  1389: { code: "ke", name: "Kenia" },
  1430: { code: "kg", name: "Kirgisistan" },
  1516: { code: "ki", name: "Kiribati" },
  1555: {
    code: "um",
    name: "Kleinere Inselbesitzungen der Vereinigten Staaten",
  },
  1483: { code: "cc", name: "Kokosinseln" },
  1403: { code: "co", name: "Kolumbien" },
  1407: { code: "km", name: "Komoren" },
  1485: { code: "cg", name: "Kongo" },
  1484: { code: "cd", name: "Kongo (Dem. Rep.)" },
  1397: { code: "xk", name: "Kosovo " },
  1422: { code: "xk", name: "Kosovo" },
  1517: { code: "xk", name: "Kosovo" },
  48: { code: "hr", name: "Kroatien" },
  1488: { code: "cu", name: "Kuba" },
  1369: { code: "kw", name: "Kuwait" },
  1518: { code: "la", name: "Laos" },
  1520: { code: "ls", name: "Lesotho" },
  16: { code: "lv", name: "Lettland" },
  1428: { code: "lb", name: "Libanon" },
  1519: { code: "lr", name: "Liberia" },
  1454: { code: "ly", name: "Libyen" },
  42: { code: "li", name: "Liechtenstein" },
  1346: { code: "lt", name: "Litauen " },
  17: { code: "lu", name: "Luxemburg" },
  61: { code: "mo", name: "Macao" },
  1395: { code: "mg", name: "Madagaskar" },
  1408: { code: "mw", name: "Malawi" },
  1387: { code: "my", name: "Malaysia" },
  1435: { code: "MV", name: "Malediven" },
  1457: { code: "MV", name: "Malediven" },
  1522: { code: "mv", name: "Malediven" },
  1461: { code: "ml", name: "Mali" },
  37: { code: "mt", name: "Malta" },
  1365: { code: "ma", name: "Marokko" },
  1523: { code: "mh", name: "Marshallinseln" },
  1385: { code: "mq", name: "Martinique" },
  1364: { code: "mr", name: "Mauretanien" },
  1363: { code: "ms", name: "Mauritius" },
  1525: { code: "mu", name: "Mauritius" },
  1409: { code: "yt", name: "Mayotte" },
  1367: { code: "mk", name: "Mazedonien" },
  64: { code: "mx", name: "Mexiko" },
  1497: { code: "fm", name: "Mikronesien" },
  1362: { code: "md", name: "Moldawien" },
  43: { code: "mc", name: "Monaco" },
  1360: { code: "mn", name: "Mongolei" },
  1343: { code: "me", name: "Montenegro" },
  1398: { code: "moc", name: "Mosambik" },
  1410: { code: "mz", name: "Mosambik" },
  1359: { code: "mm", name: "Myanmar" },
  1429: { code: "na", name: "Namibia" },
  1531: { code: "nr", name: "Nauru" },
  1462: { code: "np", name: "Nepal" },
  1358: { code: "fr", name: "Neukaledonien" },
  1526: { code: "nc", name: "Neukaledonien" },
  50: { code: "nz", name: "Neuseeland" },
  1433: { code: "kn", name: "Nevis" },
  1529: { code: "ni", name: "Nicaragua" },
  18: { code: "nl", name: "Niederlande" },
  1527: { code: "ne", name: "Niger" },
  32: { code: "ng", name: "Nigeria" },
  1530: { code: "nu", name: "Niue" },
  1453: { code: "kp", name: "Nordkorea" },
  1524: { code: "mp", name: "Nördliche Marianen" },
  1528: { code: "nf", name: "Norfolkinsel" },
  19: { code: "no", name: "Norwegen " },
  1357: { code: "om", name: "Oman" },
  3: { code: "at", name: "Österreich" },
  55: { code: "pk", name: "Pakistan" },
  1464: { code: "ps", name: "Palästina" },
  1533: { code: "pw", name: "Palau" },
  1446: { code: "pa", name: "Panama" },
  1456: { code: "pa", name: "Panama" },
  1534: { code: "pg", name: "Papua-Neuguinea" },
  1393: { code: "py", name: "Paraguay" },
  69: { code: "pe", name: "Peru" },
  1392: { code: "ph", name: "Philippinen" },
  1532: { code: "pn", name: "Pitcairn" },
  21: { code: "pl", name: "Polen" },
  22: { code: "pt", name: "Portugal" },
  1436: { code: "pr", name: "Puerto Rico" },
  1535: { code: "re", name: "Réunion" },
  1411: { code: "rw", name: "Ruanda" },
  23: { code: "ro", name: "Rumänien" },
  24: { code: "ru", name: "Russland" },
  1521: { code: "mf", name: "Saint Martin" },
  1557: { code: "vc", name: "Saint Vincent und die Grenadinen" },
  1474: { code: "bl", name: "Saint-Barthélemy" },
  1540: { code: "pm", name: "Saint-Pierre und Miquelon" },
  1539: { code: "sb", name: "Salomonen" },
  1412: { code: "zm", name: "Sambia" },
  1562: { code: "ws", name: "Samoa" },
  1451: { code: "sm", name: "San Marino" },
  1541: { code: "st", name: "São Tomé und Príncipe" },
  57: { code: "sa", name: "Saudi Arabien" },
  25: { code: "se", name: "Schweden" },
  2: { code: "ch", name: "Schweiz" },
  1439: { code: "sn", name: "Senegal" },
  1379: { code: "rs", name: "Serbien" },
  1425: { code: "rs", name: "Serbien" },
  1388: { code: "sc", name: "Seychellen" },
  1378: { code: "sl", name: "Sierra Leone" },
  1413: { code: "zw", name: "Simbabwe" },
  75: { code: "sg", name: "Singapur" },
  1544: { code: "sx", name: "Sint Maarten" },
  27: { code: "sk", name: "Slowakei" },
  28: { code: "si", name: "Slowenien" },
  1414: { code: "so", name: "Somalia" },
  29: { code: "es", name: "Spanien" },
  1538: { code: "sj", name: "Spitzbergen" },
  1356: { code: "lk", name: "Sri Lanka" },
  1475: { code: "sh", name: "St. Helena, Ascension und Tristan da Cunha" },
  1448: { code: "lc", name: "St. Lucia" },
  47: { code: "za", name: "Südafrika" },
  1536: { code: "sd", name: "Sudan" },
  1537: { code: "gs", name: "Südgeorgien und die Südlichen Sandwichinseln" },
  41: { code: "kr", name: "Südkorea" },
  1452: { code: "kr", name: "Südkorea" },
  1415: { code: "ss", name: "Südsudan" },
  1542: { code: "sr", name: "Suriname" },
  1543: { code: "sz", name: "Swasiland" },
  1545: { code: "sy", name: "Syrien" },
  1431: { code: "syr", name: "Syrien" },
  1548: { code: "tj", name: "Tadschikistan" },
  68: { code: "tw", name: "Taiwan" },
  1424: { code: "tw", name: "Taiwan " },
  1416: { code: "tz", name: "Tansania" },
  1380: { code: "th", name: "Thailand " },
  1551: { code: "tl", name: "Timor-Leste" },
  1463: { code: "tg", name: "Togo" },
  1549: { code: "tk", name: "Tokelau" },
  1552: { code: "to", name: "Tonga" },
  1553: { code: "tt", name: "Trinidad und Tobago" },
  1547: { code: "td", name: "Tschad" },
  30: { code: "cz", name: "Tschechien" },
  1354: { code: "tn", name: "Tunesien" },
  34: { code: "tr", name: "Türkei" },
  1550: { code: "tm", name: "Turkmenistan" },
  1546: { code: "tc", name: "Turks-und Caicosinseln" },
  1554: { code: "tv", name: "Tuvalu" },
  1417: { code: "ug", name: "Uganda" },
  66: { code: "ua", name: "Ukraine" },
  1337: { code: "unknown", name: "Unbekannt" },
  31: { code: "hu", name: "Ungarn" },
  1384: { code: "uy", name: "Uruguay" },
  35: { code: "us", name: "USA" },
  1342: { code: "uz", name: "Usbekistan " },
  1560: { code: "vu", name: "Vanuatu" },
  1556: { code: "va", name: "Vatikanstadt" },
  1558: { code: "ve", name: "Venezuela" },
  44: { code: "ae", name: "Vereinigte Arabische Emirate" },
  1353: { code: "us", name: "Vereinigte Staaten" },
  1352: { code: "vn", name: "Vietnam" },
  60: { code: "cn", name: "Volksrepublik China" },
  45: { code: "tw", name: "Volksrepublik China (Taiwan)" },
  1561: { code: "wf", name: "Wallis und Futuna" },
  1489: { code: "cx", name: "Weihnachtsinsel" },
  76: { code: "by", name: "Weißrussland " },
  1493: { code: "eh", name: "Westsahara" },
  1482: { code: "cf", name: "Zentralafrikanische Republik" },
  1386: { code: "cyp", name: "Zypern" },
  1490: { code: "cy", name: "Zypern" },
};

export const getStaticCountryFromLocal = () => {
  let countries: CountryType[] = [];
  for (let country in countryCode) {
    countries.push({ id: +country, name: countryCode[country].name });
  }
  const countriesSorted = countries.sort((c1, c2) => {
    if (
      c1.name
        .toLocaleLowerCase()
        .replace("ä", "a")
        .replace("ö", "o")
        .replace("å", "a") >
      c2.name
        .toLocaleLowerCase()
        .replace("ä", "a")
        .replace("ö", "o")
        .replace("å", "a")
    ) {
      return 1;
    }
    return -1;
  });
  return { objects: countriesSorted };
};

export const getCountryNameFromId = (id: number): string => {
  if (countryCode[id]) {
    return countryCode[id].name;
  }
  return countryCode[1337].name;
};

export const getCountryCodeFromId = (id: number): string => {
  if (countryCode[id]) {
    return countryCode[id].code;
  }
  return countryCode[1337].code;
};
