import {
  BrowserRouter,
  Routes as RouterRoutes,
  Route,
  Navigate,
} from "react-router-dom";

//Base Imports
import Main from "../modules/Base/Main";
import RedirectPayment from "../modules/Base/RedirectPayment";

//Admin Imports
import AdminCustomerDetail from "../modules/Admin/Customer/CustomerDetail";
import AdminCustomer from "../modules/Admin/Customer";
import AdminAboDetail from "../modules/Admin/Abo/AboDetail";
import AdminAbo from "../modules/Admin/Abo";
import AdminTransactionDetail from "../modules/Admin/Transaction/TransactionDetail";
import AdminTransaction from "../modules/Admin/Transaction";
import AdminToken from "../modules/Admin/Token";

//Main Imports
import CustomerDetail from "../modules/Main/Customer/Customerdetail";
import Customer from "../modules/Main/Customer";
import AboDetail from "../modules/Main/Abo/Abodetail";
import Abo from "../modules/Main/Abo";
import TransactionDetail from "../modules/Main/Transaction/Transactiondetail";
import Transaction from "../modules/Main/Transaction";
import PaymentDetail from "../modules/Main/Payment/PaymentDetail";
import Payment from "../modules/Main/Payment";
import Dashboard from "../modules/Main/Dashboard";

//Setting Imports
import OrganisationSetting from "../modules/Settings/Organisation";
import AccountSetting from "../modules/Settings/Account";
import PaymentSetting from "../modules/Settings/Payment";
import ProfileSetting from "../modules/Settings/Profile";
import TariffSetting from "../modules/Settings/Tariff";
import TokenSetting from "../modules/Settings/Token";

//Route Imports
import AdminRoute from "./AdminRoute";
import ProtectedRoute from "./ProtectedRoute";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faXmarkCircle,
  faWandMagicSparkles,
  faArrowUpRightFromSquare,
  faFilePdf,
} from "@fortawesome/pro-solid-svg-icons";
import RoleWrapper from "../components/RoleWrapper";
import { UserRole } from "../types/User";
import Checkout from "../modules/Settings/Tariff/Checkout";

library.add(
  faCheckCircle,
  faXmarkCircle,
  faWandMagicSparkles,
  faArrowUpRightFromSquare,
  faFilePdf
);

const Routes = () => (
  <BrowserRouter>
    <RouterRoutes>
      <Route path="/redirect/:id" element={<RedirectPayment />} />
      <Route element={<ProtectedRoute />}>
        <Route
          element={<Main children={<CustomerDetail />} />}
          path="/customer/*"
        />
        <Route element={<Main children={<Customer />} />} path="/customer" />
        <Route element={<Main children={<Abo />} />} path="/abo" />
        <Route element={<Main children={<AboDetail />} />} path="/abo/*" />
        <Route
          element={
            <Main
              children={
                <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                  <OrganisationSetting />
                </RoleWrapper>
              }
            />
          }
          path="/settings/organisation"
        />
        <Route
          element={
            <Main
              children={
                <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                  <PaymentSetting />
                </RoleWrapper>
              }
            />
          }
          path="/settings/payment"
        />
        <Route
          element={
            <Main
              children={
                <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                  <TariffSetting />
                </RoleWrapper>
              }
            />
          }
          path="/settings/tariff"
        />
        <Route
          element={
            <Main
              children={
                <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                  <Checkout />
                </RoleWrapper>
              }
            />
          }
          path="/settings/tariff/checkout/:tid"
        />
        <Route
          element={
            <Main
              children={
                <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                  <TokenSetting />
                </RoleWrapper>
              }
            />
          }
          path="/settings/token"
        />
        <Route
          element={<Main children={<AccountSetting />} />}
          path="/settings/account"
        />
        <Route
          element={
            <Main
              children={
                <RoleWrapper roles={[UserRole.Owner, UserRole.Admin]}>
                  <ProfileSetting />
                </RoleWrapper>
              }
            />
          }
          path="/settings/profile"
        />
        <Route
          element={<Main children={<TransactionDetail />} />}
          path="/transaction/*"
        />
        <Route
          element={<Main children={<Transaction />} />}
          path="/transaction"
        />
        <Route
          element={<Main children={<PaymentDetail />} />}
          path="/payment/*"
        />
        <Route element={<Main children={<Payment />} />} path="/payment" />
        <Route element={<Main children={<Dashboard />} />} path="/" />
        <Route element={<Navigate to="/" />} path="/*" />
      </Route>

      <Route element={<AdminRoute />}>
        <Route
          element={<Main children={<AdminCustomerDetail />} />}
          path="/admin/customer/*"
        />
        <Route
          element={<Main children={<AdminCustomer />} />}
          path="/admin/customer"
        />
        <Route
          element={<Main children={<AdminAboDetail />} />}
          path="/admin/abo/:merchantid/:aboid"
        />
        <Route element={<Main children={<AdminAbo />} />} path="/aabos" />
        <Route
          element={<Main children={<AdminToken />} />}
          path="/admin/token/:merchantid/:tokenid"
        />
        <Route
          element={<Main children={<AdminTransactionDetail />} />}
          path="/admin/transaction/:merchantid/:transactionid"
        />
        <Route
          element={<Main children={<AdminTransaction />} />}
          path="/admin/transaction"
        />
      </Route>
    </RouterRoutes>
  </BrowserRouter>
);
export default Routes;
