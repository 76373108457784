import {useTranslation} from "react-i18next";

type MollieConnectedContentProps = {
  profileId: string;
};
const MollieConnectedContent = (props: MollieConnectedContentProps) => {
  const {t} = useTranslation();
  return (
      <div className="m-4 text-left">
        <table className="bg-none  text ">
          <tbody>
          <tr>
            <td>
              <b>{t("components.settings.payments.profile_id")}:</b>
            </td>
            <td className="pl-4">{props.profileId}</td>
          </tr>
          </tbody>
        </table>
      </div>
  );
};

export default MollieConnectedContent;
