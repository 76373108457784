import firebase from "firebase/compat/app";
import { Abo } from "../types/Abo";
import { Customer } from "../types/Customer";
import { Merchant } from "../types/Merchant";
import { Payment, PaymentState } from "../types/Payment";
import { Tariff } from "../types/Tariff";
import { Transaction, TransactionState } from "../types/Transaction";
import {
  aboCollectionRef,
  customerCollectionRef,
  merchantRef,
  paymentCollectionRef,
  transactionCollectionRef,
} from "./firestore";
import { formatDate } from "./utils";

export type ValueAtDay = {
  day: string;
  value: string;
};

export type ContingentForMonth = {
  contingent: number;
  current: number;
  percentReached: number;
  percentLeft: number;
};

export type TariffPeriodDates = {
  start: Date;
  end: Date;
  startAsString: string;
  endAsString: string;
};

type TransactionArray = {
  date: string;
  value: string;
};

type PaymentArray = {
  date: string;
  value: string;
};

export const getWeekStatisticsAbos = async (
  merchant: string,
  profilePath?: string
): Promise<Array<ValueAtDay>> => {
  const dateWeek = new Date().setDate(new Date().getDate() - 7);
  const timestamp = firebase.firestore.Timestamp.fromDate(new Date(dateWeek));
  const abos = profilePath
    ? ((await aboCollectionRef(merchant)
        .where("profileRef", "==", firebase.firestore().doc(profilePath))
        .where("createTime", ">=", timestamp)
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Abo>)
    : ((await aboCollectionRef(merchant)
        .where("createTime", ">=", timestamp)
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Abo>);
  const array = Array<ValueAtDay>(7);
  fillArray(array);
  abos.forEach((abo) => {
    const date: Date = abo.data().createTime.toDate();
    const index = array.findIndex(
      (obj) => obj.day === formatDate(date, "DD.MM.YYYY")
    );
    if (index !== -1) {
      let item = {
        ...array[index],
        value: (Number(array[index].value) + 1).toString(),
      };
      array[index] = item;
    }
  });
  return array;
};

export const getWeekStatisticsCustomer = async (
  merchant: string,
  profilePath?: string
): Promise<Array<ValueAtDay>> => {
  const dateWeek = new Date().setDate(new Date().getDate() - 7);
  const timestamp = firebase.firestore.Timestamp.fromDate(new Date(dateWeek));
  const customers = profilePath
    ? ((await customerCollectionRef(merchant)
        .where("profileRef", "==", firebase.firestore().doc(profilePath))
        .where("createTime", ">=", timestamp)
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Customer>)
    : ((await customerCollectionRef(merchant)
        .where("createTime", ">=", timestamp)
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Customer>);
  const array = Array<ValueAtDay>(7);
  fillArray(array);
  customers.forEach((customer) => {
    const date: Date = customer.data().createTime.toDate();
    const index = array.findIndex(
      (obj) => obj.day === formatDate(date, "DD.MM.YYYY")
    );
    if (index !== -1) {
      let item = {
        ...array[index],
        value: (Number(array[index].value) + 1).toString(),
      };
      array[index] = item;
    }
  });
  return array;
};

// export const getWeekStatisticsSales = async (
//   merchant: string
// ): Promise<Array<ValueAtDay>> => {
//   const dateWeek = new Date().setDate(new Date().getDate() - 7);
//   const timestamp = firebase.firestore.Timestamp.fromDate(new Date(dateWeek));
//   const transactions = (await transactionCollectionRef(merchant)
//     .where("createTime", ">=", timestamp)
//     .where("status", "in", [TransactionState.Complete, TransactionState.Paid])
//     .orderBy("createTime")
//     .get()) as firebase.firestore.QuerySnapshot<Transaction>;
//   const array = Array<ValueAtDay>(7);
//   //Fill array with last 7 days and value=0
//   fillArray(array);

//   let transactionArray: Array<TransactionArray> = [];
//   transactions.forEach((transaction) => {
//     const date: Date = transaction.data().createTime.toDate();
//     const aboRef = transaction.data().aboRef;
//     transactionArray.push({
//       date: formatDate(date, "DD.MM.YYYY"),
//       aboRef: aboRef,
//     });
//   });

//   for (const transaction of transactionArray) {
//     const abo =
//       (await transaction.aboRef.get()) as firebase.firestore.DocumentSnapshot<Abo>;
//     const index = array.findIndex((obj) => obj.day === transaction.date);
//     if (index !== -1) {
//       let item = {
//         ...array[index],
//         value: (
//           Number(array[index].value) +
//           Number(abo.data()?.amount.value.replace(".", "")) / 100
//         ).toFixed(2),
//       };
//       array[index] = item;
//     }
//   }
//   return array;
// };

export const getWeekStatisticsSales = async (
  merchant: string,
  profilePath?: string
): Promise<Array<ValueAtDay>> => {
  const dateWeek = new Date().setDate(new Date().getDate() - 7);
  const timestamp = firebase.firestore.Timestamp.fromDate(new Date(dateWeek));

  const transactions = profilePath
    ? ((await transactionCollectionRef(merchant)
        .where("createTime", ">=", timestamp)
        .where("profileRef", "==", firebase.firestore().doc(profilePath))
        .where("status", "in", [
          TransactionState.Complete,
          TransactionState.Paid,
        ])
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Transaction>)
    : ((await transactionCollectionRef(merchant)
        .where("createTime", ">=", timestamp)
        .where("status", "in", [
          TransactionState.Complete,
          TransactionState.Paid,
        ])
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Transaction>);

  const payments = profilePath
    ? ((await paymentCollectionRef(merchant)
        .where("createTime", ">=", timestamp)
        .where("profileRef", "==", firebase.firestore().doc(profilePath))
        .where("status", "in", [PaymentState.Complete, PaymentState.Paid])
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Payment>)
    : ((await paymentCollectionRef(merchant)
        .where("createTime", ">=", timestamp)
        .where("status", "in", [PaymentState.Complete, PaymentState.Paid])
        .orderBy("createTime")
        .get()) as firebase.firestore.QuerySnapshot<Payment>);

  const array = Array<ValueAtDay>(7);
  //Fill array with last 7 days and value=0
  fillArray(array);

  const transactionArray: Array<TransactionArray> = [];
  const paymentArray: Array<PaymentArray> = [];
  transactions.forEach((transaction) => {
    const date: Date = transaction.data().createTime.toDate();
    const value = transaction.data()?.amount?.value || "0.00";
    transactionArray.push({
      date: formatDate(date, "DD.MM.YYYY"),
      value: value,
    });
  });
  payments.forEach((payment) => {
    const date: Date = payment.data().createTime.toDate();
    const value = payment.data()?.amount?.value || "0.00";
    paymentArray.push({
      date: formatDate(date, "DD.MM.YYYY"),
      value: value,
    });
  });

  transactionArray.map((transaction) => {
    const index = array.findIndex((obj) => obj.day === transaction.date);
    if (index !== -1) {
      const item = {
        ...array[index],
        value: (
          Number(array[index].value) +
          Number(transaction.value.replace(".", "")) / 100
        ).toFixed(2),
      };
      array[index] = item;
    }
    return transaction;
  });

  paymentArray.map((payment) => {
    const index = array.findIndex((obj) => obj.day === payment.date);
    if (index !== -1) {
      const item = {
        ...array[index],
        value: (
          Number(array[index].value) +
          Number(payment.value.replace(".", "")) / 100
        ).toFixed(2),
      };
      array[index] = item;
    }
    return payment;
  });
  return array;
};

const fillArray = (array: Array<ValueAtDay>) => {
  for (let i = 0; i < 7; i++) {
    const day = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i)),
      "DD.MM.YYYY"
    );
    array[i] = { day: day, value: "0" };
  }
};

export const getAboContingent = async (
  merchant: string
): Promise<ContingentForMonth> => {
  const merchantDoc = (await merchantRef(
    merchant
  ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;

  if (
    merchantDoc.data()?.tariff &&
    merchantDoc.data()?.tariff?.tariffRef &&
    merchantDoc.data()?.tariff?.validuntil
  ) {
    const endDate: Date = new Date(
      merchantDoc.data()!.tariff!.validuntil.toDate()
    );
    const startDate = new Date(
      new Date(endDate).setMonth(endDate.getMonth() - 1)
    );
    const timestamp = firebase.firestore.Timestamp.fromDate(
      new Date(startDate)
    );
    const abos = await aboCollectionRef(merchant)
      .where("createTime", ">", timestamp)
      .get();
    const tariff = (await merchantDoc
      .data()
      ?.tariff?.tariffRef.get()) as firebase.firestore.DocumentSnapshot<Tariff>;
    const percentReached = Math.round(
      (abos.size * 100) / Number(tariff.data()?.abos)
    );

    return {
      contingent: Number(tariff.data()?.abos),
      current: abos.size,
      percentReached: percentReached,
      percentLeft: 100 - percentReached,
    };
  } else {
    return {
      contingent: 0,
      current: 0,
      percentReached: 100,
      percentLeft: 0,
    };
  }
};


export const getCustomerContingent = async (
  merchant: string
): Promise<ContingentForMonth> => {
  const merchantDoc = (await merchantRef(
    merchant
  ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;

  if (
    merchantDoc.data()?.tariff &&
    merchantDoc.data()?.tariff?.tariffRef &&
    merchantDoc.data()?.tariff?.validuntil
  ) {
    const endDate: Date = new Date(
      merchantDoc.data()!.tariff!.validuntil.toDate()
    );
    const startDate = new Date(
      new Date(endDate).setMonth(endDate.getMonth() - 1)
    );
    const timestamp = firebase.firestore.Timestamp.fromDate(
      new Date(startDate)
    );
    const customer = await customerCollectionRef(merchant)
      .where("createTime", ">", timestamp)
      .get();
    const tariff = (await merchantDoc
      .data()
      ?.tariff?.tariffRef.get()) as firebase.firestore.DocumentSnapshot<Tariff>;
    const percentReached = Math.round(
      (customer.size * 100) / Number(tariff.data()?.customer)
    );

    return {
      contingent: Number(tariff.data()?.customer),
      current: customer.size,
      percentReached: percentReached,
      percentLeft: 100 - percentReached,
    };
  } else {
    return {
      contingent: 0,
      current: 0,
      percentReached: 100,
      percentLeft: 0,
    };
  }
};

export const getTransactionContingent = async (
  merchant: string
): Promise<ContingentForMonth> => {
  const merchantDoc = (await merchantRef(
    merchant
  ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;

  if (
    merchantDoc.data()?.tariff &&
    merchantDoc.data()?.tariff?.tariffRef &&
    merchantDoc.data()?.tariff?.validuntil
  ) {
    const endDate: Date = new Date(
      merchantDoc.data()!.tariff!.validuntil.toDate()
    );
    const startDate = new Date(
      new Date(endDate).setMonth(endDate.getMonth() - 1)
    );
    const timestamp = firebase.firestore.Timestamp.fromDate(
      new Date(startDate)
    );
    const transactions = await transactionCollectionRef(merchant)
      .where("createTime", ">", timestamp)
      .get();
    const tariff = (await merchantDoc
      .data()
      ?.tariff?.tariffRef.get()) as firebase.firestore.DocumentSnapshot<Tariff>;
    const percentReached = Math.round(
      (transactions.size * 100) / Number(tariff.data()?.transactions)
    );

    return {
      contingent: Number(tariff.data()?.transactions),
      current: transactions.size,
      percentReached: percentReached,
      percentLeft: 100 - percentReached,
    };
  } else {
    return {
      contingent: 0,
      current: 0,
      percentReached: 100,
      percentLeft: 0,
    };
  }
};

export const getTariffPeriodDates = async (
  merchant: string
): Promise<TariffPeriodDates> => {
  const merchantDoc = (await merchantRef(
    merchant
  ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;
  if (
    merchantDoc.data()?.tariff &&
    merchantDoc.data()?.tariff?.tariffRef &&
    merchantDoc.data()?.tariff?.validuntil
  ) {
    const endDate: Date = new Date(
      merchantDoc.data()!.tariff!.validuntil.toDate()
    );
    let startDate = new Date(endDate);
    startDate = new Date(startDate.setMonth(startDate.getMonth() - 1));
    return {
      start: startDate,
      end: endDate,
      startAsString: formatDate(startDate, "DD.MM.YYYY"),
      endAsString: formatDate(endDate, "DD.MM.YYYY"),
    };
  } else {
    const date = new Date();
    return {
      start: date,
      end: date,
      startAsString: formatDate(date, "DD.MM.YYYY"),
      endAsString: formatDate(date, "DD.MM.YYYY"),
    };
  }
};
