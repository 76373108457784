import AdminWrapper from "../../../components/AdminWrapper";
import { CLASS_NAME_MAIN } from "../../../constants";
import Actions from "../../Admin/Dashboard";
import Contingent from "./Contingent/Contingent";
// import Forecast from "./Forecast";
import SalesVolume from "./SalesVolume/SalesVolume";
import SetUpWorkflow from "./SetUpWorkflow/SetUpWorkflow";

const Dashboard = () => {
  return (
    <div className={CLASS_NAME_MAIN}>
      <div className="p-16 lg:p-8 sm:p-1">
        <AdminWrapper>
          <Actions />
        </AdminWrapper>
        <AdminWrapper noAdmin>
          <SetUpWorkflow />
        </AdminWrapper>
        <AdminWrapper noAdmin>
          <Contingent />
        </AdminWrapper>
        <AdminWrapper noAdmin>
          <SalesVolume />
        </AdminWrapper>
        {/* <AdminWrapper noAdmin>
          <Forecast />
        </AdminWrapper> */}
      </div>
    </div>
  );
};

export default Dashboard;
