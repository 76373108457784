import { useTranslation } from "react-i18next";
import { Customer } from "../../../../../../types/Customer";
import { formatDateFromFirebaseTimestamp } from "../../../../../../utils/utils";

interface IOverviewTabProps {
  id: string;
  customer: Customer | undefined;
}

const OverviewTab = ({ id, customer }: IOverviewTabProps) => {
  const { t } = useTranslation();

  if (!customer) return null;

  return (
    <>
      {customer !== undefined ? (
        <>
            <div>
              <div className="flex flex-wrap justify-between">
                <p className="font-light text-xl w-max">
                  {t("components.customers_details.customer")}
                </p>
                <p className="font-thin text-sm text-gray-700 w-max truncate">
                  {id}
                </p>
              </div>
              <hr className=" rounded rounded-2xl border-solid border-black" />
              <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
                <>
                  <p className="font-light">
                    {t("components.customers_details.name")}
                  </p>
                  <div className="font-bold col-span-2">{customer.name}</div>
                </>
                <>
                  <p className="font-light">
                    {t("components.customers_details.mail")}
                  </p>
                  <div className="font-bold col-span-2">{customer.email}</div>
                </>
                <>
                  <p className="font-light">
                    {t("components.customers_details.locale")}
                  </p>
                  <div className="font-bold col-span-2">
                    {customer.locale || "n/a"}
                  </div>
                </>
                {customer?.ppId?.live && (
                  <>
                    <p className="font-light">
                      {t("components.customers_details.ppid")}
                    </p>
                    <div className="font-bold col-span-2">
                      {customer.ppId?.live}
                    </div>
                  </>
                )}
                {customer?.ppId?.test && (
                  <>
                    <p className="font-light">
                      {`${t("components.customers_details.ppid")}(Test)`}
                    </p>
                    <div className="font-bold col-span-2">
                      {customer.ppId?.test}
                    </div>
                  </>
                )}
                {customer?.ppId?.live && (
                  <>
                    <p className="font-light">
                      {t("components.customers_details.created")}
                    </p>
                    <div className="font-bold col-span-2">
                      {formatDateFromFirebaseTimestamp(customer.createTime)}
                    </div>
                  </>
                )}
                <>
                  <p className="font-light">
                    {t("components.customers_details.metadata")}
                  </p>
                  <div className="font-bold col-span-2">
                    <div className="h-min w-full">
                      <pre>
                        <code>
                          {customer.metadata
                            ? JSON.stringify(customer.metadata, null, 2)
                            : "n/a"}
                        </code>
                      </pre>
                    </div>
                  </div>
                </>
              </div>
            </div>
        </>
      ) : (
        <div className="w-max m-auto p-4 mt-8">
          {t("components.customers_details.not_found", { id: id })}
        </div>
      )}
    </>
  );
};

export default OverviewTab;
