import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "@react-spring/web";

type TokenCardProps = {
  title: string;
  children: any;
  apikey: string;
  url: string;
  actions?: any;
};

interface ITokenCardState {
  showToken: boolean;
}

const TokenCard = (props: TokenCardProps) => {
  const { t } = useTranslation();
  const [tokenCardState, setTokenCardState] = useState<ITokenCardState>({
    showToken: false,
  });

  const showToken = () => {
    setTokenCardState({
      ...tokenCardState,
      showToken: true,
    });
  };
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });

  return (
    <animated.div
      className="w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-11/12 rounded-lg mt-6 pb-6 bg-white "
      style={{ ...springs }}
    >
      <div className="flex justify-start inline-block text-left h-max bg-white rounded-t-xl overflow-hidden align-middle">
        <p className="h-full m-4 text-xl font-bold flex">{props.title}</p>
        <p className="h-full m-4 my-auto text-sm flex text-gray-500 font-bold">
          {props.url}
        </p>
      </div>
      <div className="grid grid-flow-col auto-cols-max xl:grid-flow-row xl:grid-cols-2 sm:grid-cols-1 m-4 text-left  flex items-center">
        <div className="mr-8 font-bold flex flex-shrink">
          {t("components.settings.token.key")}
        </div>
        <div className="mr-12 flex flex-shrink">
          <input
            className="w-72 focus:outline-none bg-gray-100 p-1 rounded"
            type={tokenCardState.showToken ? "text" : "password"}
            readOnly={true}
            value={props.apikey}
          />
        </div>
        <div className="grid grid-flow-col auto-cols-max xl:mt-4 font-bold flex items-center">
          <div
            className={
              tokenCardState.showToken
                ? "hidden"
                : "mr-4 text-sm flex flex-shrink cursor-pointer"
            }
            onClick={() => showToken()}
          >
            {t("components.settings.token.show")}
          </div>
          <div className="mr-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-700">
            <CopyToClipboard text={props.apikey}>
              <p>{t("components.settings.token.copy")}</p>
            </CopyToClipboard>
          </div>
          {props.actions}
        </div>
      </div>
      <div>{props.children}</div>
    </animated.div>
  );
};

export default TokenCard;

/*
<div className="text-right h-max p-2 pr-4 rounded-b-xl overflow-hidden align-middle mb-2">
        {props.actions}
      </div>

*/
