// import ReactDOM from "react-dom";
import { StrictMode } from "react";
import "./index.css";
import "./output_tw.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { AuthProvider } from "./components/Context/AuthContext";
import store from "./store/index";
import {
  ReactReduxFirebaseProvider,
  ReduxFirestoreProvider,
} from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { createRoot } from "react-dom/client";
import Firebase from "./firebase";
import SessionProvider from "./context/SessionContext";
import ThemeLanguageWrapper from "./components/ThemeLanguageWrapper";

const rrfConfig = {
  userProfile: "user",
  useFirestoreForProfile: true,
  enableLogging: true,
  attachAuthIsReady: true,
  autoPopulateProfile: true,
  logErrors: true,
};

const firebase = Firebase();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ReduxFirestoreProvider {...rrfProps}>
          <AuthProvider>
            <SessionProvider>
              <ThemeLanguageWrapper>
                <App />
              </ThemeLanguageWrapper>
            </SessionProvider>
          </AuthProvider>
        </ReduxFirestoreProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </StrictMode>
);

// ReactDOM.render(
//   <StrictMode>
//     <Provider store={store}>
//       <ReduxFirestoreProvider {...rrfProps}>
//         <AuthProvider>
//           <App />
//         </AuthProvider>
//       </ReduxFirestoreProvider>
//     </Provider>
//   </StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
