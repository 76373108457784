// import { translateTransactionState } from "../../../../../../utils/utils";
import NoEntry from "../../../../../NoEntries";
import PaymentCard from "./PaymentCard";

interface ITransactionTabProps {
  payments?: Array<Record<string, any>>;
  onClick?: (path: string, object: any) => void;
}

const PaymentTab = ({ onClick, payments }: ITransactionTabProps) => {
  // const doClickTransaction = (path: string, payment: any) => {
  //   if (onClick && payments) {
  //     onClick(path, payment);
  //   }
  // };
  if (!payments || payments === null || payments.length === 0)
    return <NoEntry />;

  return (
    <>
      {payments?.map((payment) => {
        return (
          // <div
          //   key={`payment_${payment.id}`}
          //   className="flex flex-wrap justify-between p-1 my-1 rounded rounded-xl border border-solid grid grid-cols-2 hover:bg-gray-300 hover:border-black"
          //   onClick={() =>
          //     doClickTransaction("../payment/" + payment.id, payment)
          //   }
          // >
          //   <div className="flex flex-wrap justify-between">
          //     <p className="flex truncate">{payment.id}</p>
          //     <p className="flex truncate">
          //       {translateTransactionState(payment.data.status)}
          //     </p>
          //   </div>
          // </div>
          <PaymentCard payment={payment} onClick={onClick} key={payment.id} />
        );
      })}
    </>
  );
};

export default PaymentTab;
