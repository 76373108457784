import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import { firestoreReducer } from "redux-firestore";
import { withExtraArgument } from "redux-thunk";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import { getFirebase } from "react-redux-firebase";
import { Languages } from "../types/language";

// const ffirebase: any = Firebase;
// const createStoreWithFirebase = compose(
//     reduxFirestore(ffirebase, {})
// )(createStore);

type LanguageState = {
  language: Languages;
};

const initialLanguageState: LanguageState = { language: Languages.DE };

const languageSlice = createSlice({
  name: "language",
  initialState: initialLanguageState,
  reducers: {
    changeLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

const rootReducer = combineReducers({
  language: languageSlice.reducer,
  firestore: firestoreReducer,
});
// const middlewares = [withExtraArgument(getFirebase)];
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      withExtraArgument(getFirebase)
    ),
  devTools:
    process.env.NODE_ENV !== "production"
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
});

export type AppDispatch = typeof store.dispatch;
// const store = createStoreWithFirebase(rootReducer);
/*
const store = configureStore({
  reducer: {
    language: languageSlice.reducer,
    firestore: firestoreReducer,
  },
});
*/

export default store;
export const languageActions = languageSlice.actions;
export type { LanguageState };
