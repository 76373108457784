import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../components/Context/AuthContext";
import Login from "../modules/Base/Login";

const ProtectedRoute = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  if (!auth.authContextState.user) {
    if (location.pathname.startsWith("/register")) {
      return <Login isRegister={true} />;
    } else {
      return <Login />;
    }
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
