import { useTranslation } from "react-i18next";
import AboChart from "./AboChart";
import CustomerChart from "./CustomerChart";
import NextTransactions from "./NextTransactions";

import SalesChart from "./SalesChart";

const SalesVolume = () => {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="w-full text-left mb-4">
        <p className="text-3xl font-bold">
          {t("components.dashboard.your_abocloud")}
        </p>
        <p className="font-thin">
          {t("components.dashboard.your_abocloud_text")}
        </p>
      </div>
      <div className="flex flex-wrap gap-10 ">
        <div className="w-3/6 3xl:w-2/3 2xl:w-2/3 xl:w-full">
          <SalesChart />
        </div>
        <div className="w-2/6 3xl:w-full flex flex-wrap gap-4 items-center">
          <div className="w-2/3 3xl:w-1/3 2xl:w-2/3 xl:w-full ">
            <AboChart />
          </div>
          <div className="w-2/3 3xl:w-1/3 2xl:w-2/3 xl:w-full ">
            <CustomerChart />
          </div>
        </div>
        <div className="w-full flex flex-wrap xl:justify-center">
          <div className="w-1/5 3xl:w-1/3 2xl:w-2/3 xl:w-full sm:w-full">
            <NextTransactions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesVolume;
