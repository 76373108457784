// import ReactLoading from "react-loading";
import Lottie from "react-lottie";
import loadingCloud from "../../assets/animations/loading_cloud.json";

type LoadingProps = {
  color?: string;
};

// const Loading = (props: LoadingProps) => {
//   return (
//     <ReactLoading
//       className="w-full m-auto"
//       type="bubbles"
//       color={props.color ? props.color : "black"}
//       height={"3%"}
//       width={"3%"}
//     />
//   );
// };

const Loading = (props: LoadingProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCloud,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={90} width={90} />
      {/* <p style={{ marginTop: -15 }}>{`Lade...`}</p> */}
    </div>
  );
};

export default Loading;
