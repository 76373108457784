import { useState } from "react";
import {
  FaExclamationTriangle,
  FaInfoCircle,
  FaTimesCircle,
} from "react-icons/fa";

interface IBannerState {
  visible: boolean;
}
enum BannerTypes {
  ERROR,
  WARNING,
  INFO,
}

type BannerProps = {
  text: string;
  textMd?: string;
  type: BannerTypes;
  cancelable: boolean;
};

const Banner = (props: BannerProps) => {
  const [bannerState, setBannerState] = useState<IBannerState>({
    visible: true,
  });

  const setVisible = (visible: boolean) => {
    setBannerState((bannerState) => {
      return {
        ...bannerState,
        visible: visible,
      };
    });
  };

  if (!bannerState.visible) {
    return <></>;
  } else {
    return (
      <div
        className={
          props.type === BannerTypes.INFO
            ? "p-0 w-full text-center bg-green-800 rounded-2xl"
            : "p-0 w-full text-center bg-red-800 rounded-2xl"
        }
      >
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              {props.type === BannerTypes.INFO ? (
                <FaInfoCircle color="white" />
              ) : (
                <FaExclamationTriangle color="white" />
              )}

              <p className="ml-3 font-medium text-white truncate">
                <span className="md:inline hidden">
                  {props.textMd ? props.textMd : props.text}
                </span>
                <span className="md:hidden">{props.text}</span>
              </p>
            </div>
            <div
              className={
                props.cancelable
                  ? "order-2 flex-shrink-0 sm:order-3 sm:ml-3"
                  : "hidden"
              }
            >
              <button
                type="button"
                onClick={() => setVisible(false)}
                className="-mr-1 flex p-2 rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>
                <FaTimesCircle color="white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Banner;
