type BadgeModeProps = {
  isTest: boolean;
};

const BadgeMode = (props: BadgeModeProps) => {
  return (
    <div
      className={
        "rounded rounded-xl font-black w-max text-sm " +
        (props.isTest ? "text-orange-800" : "text-green-900")
      }
    >
      {props.isTest ? "TEST" : "LIVE"}
    </div>
  );
};

export default BadgeMode;
