interface ITabPanelProps {
  children?: React.ReactNode | React.ReactElement | JSX.Element;
  index: number;
  value: number;
}

const TabPanel = ({ index, value, children }: ITabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      style={{ width: "100%" }}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
