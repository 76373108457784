import { useTranslation } from "react-i18next";
import { Abo } from "../../../../../../types/Abo";

import {
  formatDateFromFirebaseTimestamp,
  translateAboState,
} from "../../../../../../utils/utils";
import BadgeAmount from "../../../../../Badge/BadgeAmount";
import BadgeMode from "../../../../../Badge/BadgeMode";
import Button from "../../../../../Button";
import { AboStatus } from "../../../../../../types/Abo";
import { Functions } from "../../../../../../firebase";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useFirestore } from "react-redux-firebase";
import { COLL_ABO, COLL_MERCHANT } from "../../../../../../utils/firestore";
import { SessionContext } from "../../../../../../context/SessionContext";
import { Timestamp } from "firebase/firestore";

interface IOverviewTabProps {
  id: string;
  abo: Abo | undefined;
  onClick?: (path: string, object: any) => void;
}

const OverviewTab = ({ id, abo, onClick }: IOverviewTabProps) => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { currentProfile } = useContext(SessionContext);
  const [updatingAboState, setUpdatingAboState] = useState<boolean>(false);
  const [isEditingNextExecution, setIsEditingNextExecution] =
    useState<boolean>(false);
  const [nextExecutionDate, setNextExecutionDate] = useState<string>();
  const allowUpdateNextExecutionDate =
    abo?.nextExecution === undefined ? false : true;

  const pauseAboCloudFunction = Functions().httpsCallable(
    "calls-actions-abo-pauseAbo"
  );

  const activateAboCloudFunction = Functions().httpsCallable(
    "calls-actions-abo-continueAbo"
  );
  const deleteAboCloudFunction = Functions().httpsCallable(
    "calls-actions-abo-deleteAbo"
  );

  const pauseAbo = () => {
    setUpdatingAboState(true);
    pauseAboCloudFunction({ aboId: id })
      .then(() => {})
      .finally(() => {
        setUpdatingAboState(false);
      });
  };

  const deleteAbo = () => {
    deleteAboCloudFunction({ aboId: id })
      .then(() => {})
      .finally(() => {
        setUpdatingAboState(false);
      });
  };
  const activateAbo = () => {
    activateAboCloudFunction({ aboId: id })
      .then(() => {})
      .finally(() => {
        setUpdatingAboState(false);
      });
  };

  const setNextExecutionDateOfUser = (
    newDate: string | undefined,
    aboToUpdate: Abo
  ) => {
    if (newDate === undefined) return;
    console.log(currentProfile);
    console.log(aboToUpdate);
    // make sure newDate is in "DD.MM.YYYY"
    const dateRegex =
      /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.((19|20)\d\d)$/;
    if (!dateRegex.test(newDate)) {
      setIsEditingNextExecution(false);
      console.error("Date is not in the correct format (DD.MM.YYYY)");
      return;
    }

    // Convert newDate to Date object
    const parts = newDate.split(".");
    const formattedDate = new Date(
      parseInt(parts[2]),
      parseInt(parts[1]) - 1,
      parseInt(parts[0])
    );

    // Check if the date is in the future
    if (formattedDate <= new Date()) {
      console.error("Please select a future date.");
      return;
    }

    //getmerchantId
    const merchantId = currentProfile?.path.split("/")[1] ?? "";

    // Assuming you have a utility function to convert the date to Firebase Timestamp format
    const firebaseTimestamp = new Timestamp(formattedDate.getTime() / 1000, 0);
    console.log(firebaseTimestamp, merchantId);

    // Update Firebase  abo object

    const aboRef = firestore
      .collection(`${COLL_MERCHANT}/${merchantId}/${COLL_ABO}`)
      .doc(id);
    aboRef.get().then((doc) => {
      const abo = doc.data();
      //only allow changing nextExecution if abo has been executed once
      if (!abo?.nextExecution) {
        console.error("No first execution  found");
        return;
      }
      aboRef.update({
        nextExecution: firebaseTimestamp,
      });
    });
  };
  if (!abo) return null;

  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <p className="font-light text-xl">
          {t("components.abonnement_details.abonnement_details")}
        </p>
        <p className="font-thin text-sm text-gray-700 w-max truncate">{id}</p>
      </div>
      <hr className=" rounded rounded-2xl border-solid border-black" />
      <div className="m-2 grid grid-cols-3 gap-1.5 xs:grid-cols-1">
        <>
          <p className="font-light">
            {t("components.abonnement_details.description")}
          </p>
          <div className="font-bold col-span-2">{abo?.description}</div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.created")}
          </p>
          <div className="font-bold col-span-2">
            {formatDateFromFirebaseTimestamp(abo.createTime)}
          </div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.interval")}
          </p>
          <div className="font-bold col-span-2">{abo.interval}</div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.repeatitions")}
          </p>
          <div className="font-bold col-span-2">
            {abo.times
              ? `${abo.count} ${t("components.abonnement_details.of")} ${
                  abo.times
                }`
              : `${abo.count}`}
          </div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.amount_products")}
          </p>
          <div className="font-bold col-span-2">{abo.products?.length}</div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.amount")}
          </p>
          <div className="font-bold col-span-2">
            <BadgeAmount amount={abo.amount} />
          </div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.next_execution")}
          </p>
          <div className="font-bold col-span-2 flex items-center">
            {!isEditingNextExecution ? (
              <div>
                {formatDateFromFirebaseTimestamp(
                  abo?.nextExecution ??
                    new Timestamp(new Date().getTime() / 1000, 0),
                  "DD.MM.YYYY"
                )}
                {allowUpdateNextExecutionDate && (
                  <button
                    onClick={() => {
                      setIsEditingNextExecution(true);
                      const nextExecution = abo?.nextExecution 
                      const isFutureDate =
                        nextExecution &&
                        new Date(nextExecution.seconds * 1000) > new Date();

                      if (!isFutureDate || !nextExecution) return;

                      const formattedDate = formatDateFromFirebaseTimestamp(
                        nextExecution,
                        "DD.MM.YYYY"
                      );

                      setNextExecutionDate(formattedDate);
                    }}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                )}
              </div>
            ) : (
              <div>
                <input
                  type="text"
                  value={nextExecutionDate}
                  onChange={(e) => setNextExecutionDate(e.target.value)}
                  placeholder={"DD.MM.YYYY"}
                  style={{
                    outline: "2px solid black",
                    borderRadius: "8px",
                    padding: "2px",
                  }}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Button
                    onClickListener={() => {
                      setNextExecutionDateOfUser(nextExecutionDate, abo);
                      setIsEditingNextExecution(false);
                    }}
                    title={t("actions.save")}
                  />
                  <Button
                    onClickListener={() => setIsEditingNextExecution(false)}
                    title={t("actions.cancel")}
                  />
                </div>
              </div>
            )}
          </div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.mode")}
          </p>
          <div className="font-bold col-span-2">
            <BadgeMode isTest={abo.test} />
          </div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.state")}
          </p>
          <div className="font-bold col-span-2">
            {translateAboState(abo.status)}
          </div>
        </>
        <>
          <p className="font-light">
            {t("components.abonnement_details.metadata")}
          </p>
          <div className="font-bold col-span-2">
            <div className="h-min w-full">
              <pre>
                <code>
                  {abo.metadata ? JSON.stringify(abo.metadata, null, 2) : ""}
                </code>
              </pre>
            </div>
          </div>
        </>
      </div>
      <p className="font-light text-xl">
        {t("components.abonnement_details.payment_details")}
      </p>
      <hr className=" rounded rounded-2xl border-solid border-black" />
      <div className="m-2 grid grid-cols-3 xs:grid-cols-1">
        <>
          <p className="font-light">
            {t("components.abonnement_details.mandat_id")}
          </p>
          <div className="font-bold col-span-2">{abo.ppMandateId || "n/a"}</div>
        </>
      </div>

      <p className="font-light text-xl">
        {t("components.abonnement_details.payment_actions")}
      </p>
      <hr className=" rounded rounded-2xl border-solid border-black" />
      <div className="m-2 grid grid-cols-3 xs:grid-cols-1">
        {abo.status === AboStatus.Paused && (
          <Button
            title={t("states.abo.activate")}
            onClickListener={activateAbo}
            disabled={updatingAboState}
          />
        )}
        {abo.status === AboStatus.Active && (
          <Button
            title={t("states.abo.pause")}
            onClickListener={pauseAbo}
            disabled={updatingAboState}
          />
        )}
        {abo.status !== AboStatus.Deleted && (
          <Button
            title={t("states.abo.delete")}
            onClickListener={deleteAbo}
            disabled={updatingAboState}
          />
        )}
      </div>
    </div>
  );
};

export default OverviewTab;
