import firebase from "firebase/compat/app";
import { Amount } from "../../Amount";

type Addons = {
  "general.abo": number;
  "general.transaction": number;
  "general.customer": number;
  "general.profile": number;
};

type BookingMeta = {
  ppMethod?: string;
  ppDetails?: {
    paypalReference?: string;
  };
  ppId?: string;
  aboId?: string;
  netPrice?: Amount;
  totalPrice?: Amount;
  vatAmount?: Amount;
  test?: boolean;
};

export enum BookingStatus {
  Open,
  Active,
  Canceled,
  Deleted,
}

export type Booking = {
  addons: Addons;
  id: string;
  interval: string;
  name: string;
  price: Amount;
  status: BookingStatus;
  tariffRef: firebase.firestore.DocumentReference;
  meta?: BookingMeta;
  validUntil: firebase.firestore.Timestamp;
  createTime: firebase.firestore.Timestamp;
};
