import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFirestore } from "react-redux-firebase";
import { AuthContext } from "../components/Context/AuthContext";
import { Addons } from "../types/Merchant";
import { UserRole, UserStatus } from "../types/User";
import { Languages } from "../types/language";
import {
  COLL_BOOKING,
  COLL_KEY,
  COLL_MERCHANT,
  COLL_USER,
} from "../utils/firestore";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
// import { ProfileStatus } from "../types/Profile";

type Profile = {
  name: string;
  status: string;
  url: string;
  path: string;
};
type Preferences = {
  language?: Languages;
};

export const SessionContext = React.createContext<SessionContextValueType>({
  currentProfile: null,
  profiles: [],
  authorizations: {
    "general.abo": 0,
    "general.customer": 0,
    "general.profile": 0,
    "general.transaction": 0,
  },
  initialized: null,
  userRole: 2,
  userStatus: 0,
  selectedTariff: null,
  preferences: null,
  currentTariffRanking: null,
  selectProfile: (_) => null,
  selectTariff: (_) => null,
  refreshMerchant: () => null,
});

// TODO: MOVE TO GLOBAL FIREBASE TYPES

export type SessionContextValueType = {
  currentProfile: Profile | null;
  profiles: Array<Profile>;
  authorizations: Addons;
  initialized: boolean | null;
  userRole: UserRole;
  userStatus: UserStatus;
  selectedTariff: string | null;
  preferences: Preferences | null;
  currentTariffRanking: number | null;
  selectProfile: (profile: string) => void;
  selectTariff: (tariff: string | null) => void;
  refreshMerchant: () => void;
};

const SessionProvider = ({ children }: PropsWithChildren<unknown>) => {
  const firestore = useFirestore();
  const { mid, currentUser } = useContext(AuthContext);
  const [userRole, setUserRole] = useState<UserRole>(2);
  const [userStatus, setUserStatus] = useState<UserStatus>(0);
  const [currentTariffRanking, setCurrentTariffRanking] = useState<
    number | null
  >(null);
  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);
  const [initialized, setInitialized] = useState<boolean | null>(null);
  const [preferences, setPreferences] = useState<Preferences | null>(null);
  const [selectedTariff, setSelectedTariff] = useState<string | null>(null);
  const [profiles, setProfiles] = useState<Array<Profile>>([]);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(true);
  const [addons, setAddons] = useState<Addons>({
    "general.abo": 0,
    "general.customer": 0,
    "general.profile": 0,
    "general.transaction": 0,
  });

  const selectProfile = (path: string) => {
    setCurrentProfile(
      profiles.filter((profile) => {
        return profile.path === path;
      })[0] || undefined
    );
  };

  const selectTariff = (tariff: string | null) => {
    setSelectedTariff(tariff);
  };

  const refreshMerchant = () => {
    setNeedsRefresh(true);
  };
  useEffect(() => {
    if (mid === null) return;
    firestore
      .collection(`${COLL_MERCHANT}/${mid}/${COLL_BOOKING}`)
      .get()
      .then((bookings) => {
        if (bookings.size === 0) {
          setCurrentTariffRanking(-1);
        } else {
          bookings.docs[0]
            .data()
            .tariffRef.get()
            .then(
              (tariff: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
                const tariffRanking = tariff.data().ranking;
                setCurrentTariffRanking(+tariffRanking || 0);
              }
            );
        }
      });
  }, [mid, firestore]);

  useEffect(() => {
    if (mid === null) return;
    if (needsRefresh === true) {
      setNeedsRefresh(false);
      firestore
        .collection(COLL_MERCHANT)
        .doc(`${mid}`)
        .get()
        .then((merchant) => {
          const data = merchant.data();
          if (data?.authorizations) setAddons(data?.authorizations);
          if (data?.initialized && data.initialized === true) {
            setInitialized(true);
          } else {
            setInitialized(false);
          }
        });
    }
  }, [mid, firestore, needsRefresh]);

  useEffect(() => {
    if (mid === null) return;
    firestore
      .collection(`${COLL_MERCHANT}/${mid}/${COLL_KEY}`)
      // .where("status", "==", ProfileStatus.Valid) TODO: Enable after deployment
      .get()
      .then((keys) => {
        const profileArray: Array<Profile> = [];
        keys.forEach((key) => {
          const data = key.data();
          profileArray.push({
            name: data.name,
            path: key.ref.path,
            status: data.status,
            url: data.url,
          });
        });
        setProfiles(profileArray);
        setCurrentProfile(profileArray[0] || null);
      });
  }, [mid, firestore]);

  useEffect(() => {
    firestore
      .collection(`${COLL_MERCHANT}/${mid}/${COLL_USER}`)
      .doc(currentUser || "-")
      .get()
      .then((user) => {
        if (!user.data()) return;
        if (user.data()?.preferences) setPreferences(user.data()?.preferences);
        setUserRole(user.data()?.role);
        setUserStatus(user.data()?.status);
      });
  }, [currentUser, mid, firestore]);

  return (
    <SessionContext.Provider
      value={{
        currentProfile: currentProfile,
        profiles: profiles,
        authorizations: addons,
        initialized: initialized,
        userRole: userRole,
        userStatus: userStatus,
        selectedTariff: selectedTariff,
        preferences: preferences,
        currentTariffRanking: currentTariffRanking,
        selectProfile: selectProfile,
        selectTariff: selectTariff,
        refreshMerchant: refreshMerchant,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
