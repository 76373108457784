import { useContext } from "react";
import { AuthContext } from "../../components/Context/AuthContext";
import useCollection from "../firestore/useCollection";
import moment from "moment";
import { SessionContext } from "../../context/SessionContext";
import { Quota } from "./types";

const useAboQuota = (): Quota => {
  const FIRST_DAY_MOTNH = moment().startOf("month").toDate();
  const LAST_DAY_MOTNH = moment().endOf("month").toDate();
  const { mid } = useContext(AuthContext);
  const { authorizations } = useContext(SessionContext);

  const currentAbo = useCollection(
    `merchants/${mid}/abos`,
    [
      ["createTime", ">", FIRST_DAY_MOTNH],
      ["createTime", "<=", LAST_DAY_MOTNH],
    ],
    ["createTime", "desc"],
    10000
  );
  const aboCount = Object.keys(currentAbo || {}).length || 0;
  const percentReached =
    authorizations["general.abo"] === 0
      ? 100
      : Math.round((aboCount * 100) / Number(authorizations["general.abo"]));

  return {
    current: aboCount,
    quoata: authorizations["general.abo"],
    percent: percentReached > 100 ? 100 : percentReached,
    percentLeft: 100 - (percentReached > 100 ? 100 : percentReached),
  };
};

export default useAboQuota;
