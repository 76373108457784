import firebase from "firebase/compat/app";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import { AuthContext } from "../../../components/Context/AuthContext";
import Filter, { FilterTypes } from "../../../components/Filter";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import { Merchant } from "../../../types/Merchant";
import { merchantCollectionGroupRef } from "../../../utils/firestore";
import { replaceReferencesWithPath } from "../../../utils/utils";
import {
  FaMinusCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import CustomerTooltip from "./CustomerTooltip";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom-v5-compat";

interface IACustomerState {
  loading: boolean;
  merchants: any[];
  moreItemsExist: boolean;
  loadingMore: boolean;
  merchantQuery:
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query;
}

// type ACustomerProps = {
//   history: any;
//   location: any;
//   match: any;
// };
const Customer = () => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const firestore = useFirestore();
  const navigate = useNavigate();
  const [aCustomerState, setACustomerState] = useState<IACustomerState>({
    merchants: [],
    loading: true,
    moreItemsExist: true,
    loadingMore: false,
    merchantQuery: merchantCollectionGroupRef().orderBy("createTime", "desc"),
  });

  useEffect(() => {
    const refreshACustomer = async (
      merchantsParam?: firebase.firestore.QuerySnapshot
    ) => {
      let merchants: firebase.firestore.QuerySnapshot;
      if (merchantsParam) {
        merchants = merchantsParam;
      } else {
        merchants = await aCustomerState.merchantQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get();
      }
      if (merchants.empty) {
        setACustomerState((aCustomerState) => {
          return {
            ...aCustomerState,
            loading: false,
            ...(merchants.size < FIREBASE_REQUEST_LIMIT && {
              moreItemsExist: false,
            }),
          };
        });
        return;
      }
      let merchantArray: any[] = [];
      merchants.forEach((merchant) => {
        merchantArray.push({
          data: replaceReferencesWithPath(merchant.data()) as Merchant,
          path: merchant.ref.path,
          id: merchant.id,
        });
        setACustomerState((aCustomerState) => {
          return {
            ...aCustomerState,
            merchants: merchantArray,
            loading: false,
            ...(merchants.size < FIREBASE_REQUEST_LIMIT && {
              moreItemsExist: false,
            }),
          };
        });
      });
    };

    aCustomerState.merchantQuery
      .limit(FIREBASE_REQUEST_LIMIT)
      .onSnapshot((merchants) => {
        refreshACustomer(merchants);
      });
  }, [auth.authContextState.user, firestore, aCustomerState.merchantQuery]);

  const updateACustomerQuery = (query: firebase.firestore.Query) => {
    setACustomerState((aCustomerState) => {
      return {
        ...aCustomerState,
        loading: true,
        merchants: [],
        merchantQuery: query,
      };
    });
  };

  const handleOnClick = useCallback(
    (link: string, customer?: any) =>
      navigate(link, { state: { customer: customer } }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.CUSTOMER}
        query={merchantCollectionGroupRef()}
        updateQuery={updateACustomerQuery}
      />
      <LoadingComponent isLoading={aCustomerState.loading}>
        <Table
          //loadMoreItems={loadMoreItems}
          moreItemsExist={aCustomerState.moreItemsExist}
          loadingMoreItems={aCustomerState.loadingMore}
          itemsTitle={[
            t("admin.customers.id"),
            t("admin.customers.name"),
            t("admin.customers.organisation"),
            t("admin.customers.customerdata"),
            t("admin.customers.sevdesk"),
            t("admin.customers.paymentprovider"),
          ]}
        >
          {aCustomerState.merchants.map((merchant) => {
            return (
              <TableBody
                key={merchant.id}
                categoryTitle={`${t("admin.customers.customers")}`}
                component={merchant}
                componentPath={"acustomers/" + merchant.id}
                isClickable={true}
                onClick={handleOnClick}
                id={merchant.id}
                rows={6}
                itemsBody={[
                  {
                    title: t("admin.customers.id"),
                    value: merchant.id,
                  },
                  {
                    title: t("admin.customers.name"),
                    value: merchant.data.name,
                  },
                  {
                    title: t("admin.customers.organisation"),
                    value: merchant.data.customerdata?.organisation ? (
                      merchant.data.customerdata?.organisation
                    ) : (
                      <p className="font-thin italic">n/a</p>
                    ),
                  },
                  {
                    title: t("admin.customers.email"),
                    value: merchant.data.customerdata ? (
                      <>
                        <FaCheckCircle
                          data-tip=""
                          data-for={"customerdata_exist"}
                          className="text-xl text-black-600"
                        />
                        <CustomerTooltip
                          id="customerdata_exist"
                          text="Händlerdaten wurden hinterlegt"
                        />
                      </>
                    ) : (
                      <>
                        <FaMinusCircle
                          data-tip=""
                          data-for={"customerdata"}
                          className="text-xl text-red-600"
                        />
                        <CustomerTooltip
                          id="customerdata"
                          text="Händlerdaten wurden noch nicht hinterlegt"
                        />
                      </>
                    ),
                  },
                  {
                    title: t("admin.customers.email"),
                    value: merchant.data.customerdata?.sevdesk ? (
                      <>
                        <FaCheckCircle
                          data-tip=""
                          data-for={"sevdesk_exist"}
                          className="text-xl text-black-600"
                        />
                        <CustomerTooltip
                          id="sevdesk_exist"
                          text="Daten wurden an Sevdesk übertragen"
                        />
                      </>
                    ) : (
                      <>
                        <FaMinusCircle
                          data-tip=""
                          data-for={"sevdesk"}
                          className="text-xl text-red-600"
                        />
                        <CustomerTooltip
                          id="sevdesk"
                          text="Daten wurden noch nicht an Sevdesk übergeben"
                        />
                      </>
                    ),
                  },
                  {
                    title: t("admin.customers.email"),
                    value: merchant.data.paymentprovider ? (
                      merchant.data.paymentprovider?.mollie?.profileid ? (
                        <>
                          <FaCheckCircle
                            data-tip=""
                            data-for={"mollie_exist"}
                            className="text-xl text-black-600"
                          />
                          <CustomerTooltip
                            id="mollie_exist"
                            text="Mollie wurde mit Account verbunden"
                          />
                        </>
                      ) : (
                        <>
                          <FaExclamationCircle
                            data-tip=""
                            data-for={"mollie_profile_missing"}
                            className="text-xl text-orange-600"
                          />
                          <CustomerTooltip
                            id="mollie_profile_missing"
                            text="Es wurde noch kein Mollie-Profil ausgewählt"
                          />
                        </>
                      )
                    ) : (
                      <FaMinusCircle className="text-xl text-red-600" />
                    ),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

export default Customer;
