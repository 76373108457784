import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { useFirestore } from "react-redux-firebase";
import { paymentCollectionRef } from "../../../../utils/firestore";
import { CLASS_NAME_MAIN } from "../../../../constants";
import LoadingComponent from "../../../../components/Loading/LoadingWrapper";
import { Abo } from "../../../../types/Abo";
import firebase from "firebase/compat/app";
import { useLocation, useMatch } from "react-router-dom";
import DetailViewPayment from "../../../../components/Detailview/Main/Payment";
import { Payment } from "../../../../types/Payment";

type IPayemntDetailState = {
  loading: boolean;
  hasError: boolean;
  paymentId: string;
  payment: any;
  abo: any;
  aboid: string | undefined;
};

const PaymentDetail = () => {
  const [paymentDetailState, setPaymentDetailState] =
    useState<IPayemntDetailState>({
      loading: true,
      paymentId: "",
      hasError: false,
      payment: undefined,
      abo: undefined,
      aboid: "",
    });
  const match = useMatch("/payment/:id");
  const location = useLocation();
  const paymentId = match?.params.id || "n/a";
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();

  useEffect(() => {
    const getPayment = async () => {
      if (location.state && location.state.payment) {
        const aboRef: firebase.firestore.DocumentReference = firestore.doc(
          location.state.payment.data.aboPath
        );
        const abo = await aboRef.get();
        setPaymentDetailState((paymentDetailState) => {
          return {
            ...paymentDetailState,
            paymentId: location.state.payment.id,
            payment: location.state.payment.data as Payment,
            abo: abo.data() as Abo,
            aboid: abo.id,
            loading: false,
          };
        });
      } else if (mid) {
        const payment = await paymentCollectionRef(mid || "-")
          .doc(paymentId)
          .get();

        if (payment.exists) {
          const abo = await payment.data()?.aboRef.get();
          setPaymentDetailState((paymentDetailState) => {
            return {
              ...paymentDetailState,
              paymentId: payment.id,
              payment: payment.data() as Payment,
              abo: abo.data() as Abo,
              aboid: abo.id,
              loading: false,
            };
          });
        }

        setPaymentDetailState((transactiondetailState) => {
          return {
            ...transactiondetailState,
            loading: false,
          };
        });
      }
    };
    getPayment();
  }, [location.state, paymentId, mid, firestore, paymentDetailState.payment]);
  return (
    <div className={CLASS_NAME_MAIN}>
      <LoadingComponent isLoading={paymentDetailState.loading}>
        <DetailViewPayment
          payment={paymentDetailState.payment}
          id={paymentId}
          abo={paymentDetailState.abo}
          aboid={paymentDetailState.aboid}
        />
      </LoadingComponent>
    </div>
  );
};

export default PaymentDetail;
