import firebase from "firebase/compat/app";

export enum UserStatus {
  Disabled,
  Invited,
  Active,
  Blocked,
  Deleted,
}

export enum UserRole {
  Owner,
  Admin,
  Guest,
}

export type User = {
  firstname: string;
  lastname: string;
  email: string;
  status: UserStatus;
  role: UserRole;
  merchantid: string;
  merchantRef: firebase.firestore.DocumentReference;
  createTime: firebase.firestore.Timestamp;
  updateTime: firebase.firestore.Timestamp;
};
