import Loading from "..";

type LoadingComponentProps = {
  isLoading: boolean;
  children?: any;
};

const LoadingComponent = (props: LoadingComponentProps) => {
  return props.isLoading ? <Loading /> : props.children;
};

export default LoadingComponent;
