import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthContext";

type AdminWrapperProps = {
  children: any;
  noAdmin?: boolean;
};
interface IAdminWrapperState {
  isAdmin: boolean;
  showComponent: boolean;
  loading: boolean;
}

const AdminWrapper = (props: AdminWrapperProps) => {
  const auth = useContext(AuthContext);
  const [adminWrapperState, setAdminWrapperState] =
    useState<IAdminWrapperState>({
      isAdmin: false,
      showComponent: false,
      loading: true,
    });

  useEffect(() => {
    const checkClaims = async () => {
      const idTokenResult = await auth.getClaims();
      if (!!idTokenResult?.claims.admin && false) {
        //Disable admin mode because we dont need admin area
        setAdminWrapperState((adminWrapperState) => {
          return {
            ...adminWrapperState,
            isAdmin: true,
            showComponent: props.noAdmin ? false : true,
            loading: false,
          };
        });
      } else {
        setAdminWrapperState((adminWrapperState) => {
          return {
            ...adminWrapperState,
            isAdmin: false,
            showComponent: props.noAdmin ? true : false,
            loading: false,
          };
        });
      }
    };
    checkClaims();
  }, [auth, props.noAdmin]);

  if (adminWrapperState.loading) {
    return <></>;
  } else {
    if (adminWrapperState.showComponent) {
      return props.children;
    } else {
      return <></>;
    }
  }
};

export default AdminWrapper;
