import image from "../../../../../assets/btn_mollie.png";
import { useTranslation } from "react-i18next";
import { Functions } from "../../../../../firebase";
import { SetStateAction } from "react";

const MollieConnectContent = ({
  setConnectionLoading,
}: {
  setConnectionLoading: (value: SetStateAction<boolean>) => void;
}) => {
  const { t } = useTranslation();
  const generateState = Functions().httpsCallable("calls-oauth-generateState");

  const handleOAuth = async () => {
    setConnectionLoading(true);
    const state = (await generateState({ application: `mollie` })).data;
    window.location.assign(
      process.env.REACT_APP_MOLLIE_API_AUTHORIZE +
        "?client_id=" +
        process.env.REACT_APP_MOLLIE_ACCOUNT_CLIENT_ID +
        `&state=${state}&scope=payments.read payments.write customers.write customers.read profiles.read profiles.write mandates.write mandates.read&redirect_uri=` +
        process.env.REACT_APP_MOLLIE_ACCOUNT_REDIRECT_URI +
        "&response_type=code&approval_promt=auto"
    );
  };

  return (
    <>
      <p className="text-center m-4 text">
        {t("components.settings.payments.mollie_connect")}
      </p>
      <img
        className="m-auto mb-6 cursor-pointer"
        onClick={handleOAuth}
        src={image}
        alt="Mollie-Button"
      />
    </>
  );
};

export default MollieConnectContent;
