// import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useFirestore } from "react-redux-firebase";
// import Banner from "../../../components/Banner";
// import { AuthContext } from "../../../components/Context/AuthContext";
// import Loading from "../../../components/Loading";
// import { Functions } from "../../../firebase";
// import { merchantRef, tariffCollectionRef } from "../../../utils/firestore";
// import TariffCard from "./TariffCard";
// import firebase from "firebase/compat/app";
// import { Tariff } from "../../../types/Tariff";
// import { Merchant } from "../../../types/Merchant";
import SettingsWrapper from "../../../components/SettingsWrapper";
import { useContext, useEffect, useState } from "react";
import CurrentTariff from "./CurrentTariff";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../../../components/Modal/SuccessModal";
import TariffSelection from "../../../components/Tariff/Selection";
import { SessionContext } from "../../../context/SessionContext";

// interface ITariffState {
//   loading: boolean;
//   tariffs: any[];
//   currentTariff: string | null | undefined;
//   bookedTariff: string | null | undefined;
//   stored: boolean;
// }

const Tariffs = () => {
  // const firestore = useFirestore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { selectTariff, selectedTariff } = useContext(SessionContext);
  const params = new URLSearchParams(location.search);
  const successToken = params.get("initialized");
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(true);

  useEffect(() => {
    selectTariff(null);
  }, [selectTariff]);

  const checkout = () => {
    navigate(`/settings/tariff/checkout/${selectedTariff}`);
    selectTariff(null);
  };
  // const [booking, setBooking] = useState<boolean>(false);
  // const createBooking = Functions().httpsCallable(
  //   "management-calls-booking-create"
  // );

  // const auth = useContext(AuthContext);

  // const [tariffState, setTariffState] = useState<ITariffState>({
  //   loading: true,
  //   tariffs: [],
  //   currentTariff: null,
  //   bookedTariff: null,
  //   stored: false,
  // });

  // const book = async () => {
  //   setBooking(true);
  //   await createBooking({ tid: "default" })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.success === true) {
  //         window.location.href = res.data.checkout;
  //       }
  //     })
  //     .catch((err) => {
  //       setBooking(false);
  //       console.log(err);
  //     })
  //     .finally(() => {});
  // };

  // useEffect(() => {
  //   const loadTariffs = async () => {
  //     const tariffs =
  //       (await tariffCollectionRef().get()) as firebase.firestore.QuerySnapshot<Tariff>;
  //     let tariffArray: any[] = [];
  //     tariffs.forEach((tariff) => {
  //       tariffArray.push(tariff);
  //     });
  //     const merchant = (await merchantRef(
  //       auth.authContextState.user.uid
  //     ).get()) as firebase.firestore.DocumentSnapshot<Merchant>;
  //     if (merchant.data()?.tariff && merchant.data()?.tariff?.tariffRef) {
  //       const currentTariffId = merchant.data()?.tariff?.tariffRef.id;
  //       let bookedTariffId: string | undefined;
  //       if (
  //         merchant.data()?.tariff?.tariffBookedRef &&
  //         merchant.data()?.tariff?.tariffBookedRef !== null
  //       ) {
  //         bookedTariffId = merchant.data()?.tariff?.tariffBookedRef?.id;
  //       }
  //       setTariffState((tariffState) => {
  //         return {
  //           ...tariffState,
  //           currentTariff: currentTariffId,
  //           bookedTariff: bookedTariffId,
  //           tariffs: tariffArray,
  //           loading: false,
  //         };
  //       });
  //     } else {
  //       setTariffState((tariffState) => {
  //         return {
  //           ...tariffState,
  //           tariffs: tariffArray,
  //           loading: false,
  //         };
  //       });
  //     }
  //   };
  //   loadTariffs();
  // }, [auth.authContextState.user, firestore]);

  // const bookTariff = (id: string) => {
  //   setTariffState((tariffState) => {
  //     return {
  //       ...tariffState,
  //       loading: true,
  //     };
  //   });
  //   //Create MolliePayment(first)
  //   //Set subscription
  //   const createSubscription = Functions().httpsCallable(
  //     "func-mollie-createAboCloudSubcription"
  //   );
  //   createSubscription({
  //     testmode: true,
  //     tariffId: id,
  //   })
  //     .then((value) => {
  //       if (value.data.checkout) {
  //         if (value.data.checkout === "none") {
  //           setTariffState((tariffState) => {
  //             return {
  //               ...tariffState,
  //               stored: true,
  //             };
  //           });
  //         } else {
  //           window.location.assign(value.data.checkout);
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       setTariffState((tariffState) => {
  //         return {
  //           ...tariffState,
  //           loading: false,
  //         };
  //       });
  //     });
  // };

  return (
    <SettingsWrapper title={t("components.settings.tariff.mytariff")}>
      {/* {booking === true ? (
        <BookingProcess />
      ) : ( */}
      <>
        {successToken && successToken === "true" && successModalOpen && (
          <SuccessModal close={() => setSuccessModalOpen(false)} />
        )}
        <CurrentTariff />
        <div className="w-full flex flex-wrap gap-4 justify-start lg:justify-center sm:justify-left mt-16">
          <p className="w-max text-3xl sm:text-xl font-bold">
            Jetzt deinen Tarif wechseln
          </p>
        </div>

        <div className="lg:w-full w-8/12 bg-red-300">
          <TariffSelection onComplete={checkout} />
        </div>

        {/*
          <input
            onClick={book}
            type="submit"
            id="save"
            value={"Tarif buchen"}
            className="w-max rounded-xl p-2 w-1/4 text-base my-4 box-border text-white bg-black cursor-pointer mt-16"
          />*/}
      </>
      {/* )} */}
    </SettingsWrapper>
  );

  // return (
  //   <>
  //     {tariffState.stored ? (
  //       <Banner
  //         cancelable={true}
  //         type={2}
  //         text={t("components.settings.tariff.tariff_stored")}
  //       />
  //     ) : (
  //       <></>
  //     )}
  //     <SettingsWrapper title={t("components.settings.tariff.mytariff")}>
  //       {tariffState.loading ? (
  //         <Loading />
  //       ) : (
  //         <>
  //           <div className="w-full flex flex-wrap gap-4 justify-start lg:justify-center mt-16">
  //             {tariffState.tariffs.map((tariff) => {
  //               return (
  //                 <TariffCard
  //                   key={`${tariff.id}_tar`}
  //                   tariff={tariff.data()}
  //                   tariffId={tariff.id}
  //                   bookedTariff={tariffState.bookedTariff}
  //                   currentTariff={tariffState.currentTariff}
  //                   bookTariff={bookTariff}
  //                 ></TariffCard>
  //               );
  //             })}
  //           </div>
  //         </>
  //       )}
  //     </SettingsWrapper>
  //   </>
  // );
};

// const BookingProcess = () => {
//   const processText: Array<string> = [
//     "Du kannst gleich starten!",
//     "Wir schaffen kurz Platz in der Cloud für dich!",
//     "Die nächste abocloud gehört dir!",
//     "Fast fertig!",
//   ];
//   const [counter, setCounter] = useState<number>(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (counter < processText.length - 1) {
//         setCounter(counter + 1);
//       } else {
//         setCounter(0);
//       }
//     }, 3000);

//     return () => clearInterval(interval);
//   }, [counter]);
//   return (
//     <div>
//       <LoadingComponent isLoading />
//       <p>{processText[counter]}</p>
//     </div>
//   );
// };

export default Tariffs;
