import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  translateUserRole,
  translateUserState,
} from "../../../../../utils/utils";
import { ChangeEvent, SetStateAction, useContext, useState } from "react";
import { Functions } from "../../../../../firebase";
import Loading from "../../../../../components/Loading";
import { SessionContext } from "../../../../../context/SessionContext";
import "./index.css";

const UserCard = ({
  user,
  setUpdate,
}: {
  user: Record<string, any>;
  setUpdate: (value: SetStateAction<boolean>) => void;
}) => {
  const { t } = useTranslation();
  const { userRole, userStatus } = useContext(SessionContext);
  const editUser = Functions().httpsCallable("management-calls-user-edit");
  const deleteUser = Functions().httpsCallable("management-calls-user-delete");
  const isActive = (): boolean => {
    return user.status === 2;
  };
  const isOwner = (): boolean => {
    return user.role === 0;
  };

  const canEdit = (): boolean => {
    return [0, 1].includes(userRole) && [2].includes(userStatus);
  };

  const [edit, setEdit] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  const UserEdit = () => {
    const [userState, setUserState] = useState<number>(user.status);
    const [userRole, setUserRole] = useState<number>(user.role);
    const [userLoading, setUserLoading] = useState<boolean>(false);

    const onUserStateChanged = (
      _event: ChangeEvent<HTMLInputElement>,
      value: string
    ) => {
      setUserState(+value);
    };

    const onUserRoleChanged = (
      _event: ChangeEvent<HTMLInputElement>,
      value: string
    ) => {
      setUserRole(+value);
    };

    const saveUser = () => {
      setUserLoading(true);
      editUser({
        uid: user.id,
        user: { status: userState, role: userRole },
      }).finally(() => {
        setUserLoading(false);
        setUpdate(true);
        setEdit(false);
      });
    };

    return (
      <div
        onClick={/*closeScreen*/ () => {}}
        className="block h-screen w-auto z-50 inset-0 bg-black_rgba fixed"
      >
        <div className="flex w-full h-screen justify-center content-center fixed">
          <div
            className="flex justify-start flex-wrap flex-col rounded rounded-xl bg-white text-black p-5 m-2 self-center w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-full"
            style={{ minHeight: 370, height: 370 }}
          >
            <div className="flex flex-col justify-between content-between h-full min-h-full">
              {userLoading === true ? (
                <Loading />
              ) : (
                <>
                  <div>
                    <p className="text-2xl font-bold">{`${user.firstname} ${user.lastname}`}</p>

                    <Grid
                      style={{ marginTop: 20 }}
                      container
                      spacing={0}
                      alignContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={2}>
                        <p className="text-lg font-bold">
                          {t("components.settings.organisation.status")}:
                        </p>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl>
                          <RadioGroup
                            value={userState}
                            onChange={onUserStateChanged}
                            row
                          >
                            <FormControlLabel
                              value={0}
                              disabled
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.status.invited"
                              )}
                            />
                            <FormControlLabel
                              value={1}
                              disabled
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.status.disabled"
                              )}
                            />
                            <FormControlLabel
                              value={2}
                              disabled={[0, 1, 4].includes(user.status)}
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.status.active"
                              )}
                            />
                            <FormControlLabel
                              value={3}
                              disabled={[0, 1, 4].includes(user.status)}
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.status.blocked"
                              )}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <p className="text-lg font-bold">
                          {t("components.settings.organisation.role")}:
                        </p>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl>
                          <RadioGroup
                            value={userRole}
                            onChange={onUserRoleChanged}
                            row
                          >
                            <FormControlLabel
                              value={0}
                              disabled
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.role.owner"
                              )}
                            />
                            <FormControlLabel
                              value={1}
                              disabled={[0].includes(user.status)}
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.role.admin"
                              )}
                            />
                            <FormControlLabel
                              value={2}
                              disabled={[0].includes(user.status)}
                              control={<Radio />}
                              label={t(
                                "components.settings.organisation.user.role.guest"
                              )}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      marginTop: 30,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!isOwner() && (
                      <>
                        <div
                          className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-600"
                          onClick={() => setEdit(false)}
                        >
                          {t("actions.cancel")}
                        </div>
                        {canEdit() && (
                          <div
                            className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-600"
                            onClick={saveUser}
                          >
                            {t("actions.save")}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const UserDelete = () => {
    const [userLoading, setUserLoading] = useState<boolean>(false);

    const removeUser = () => {
      setUserLoading(true);
      deleteUser({
        uid: user.id,
      }).finally(() => {
        setUpdate(true);
        setUserLoading(false);
      });
    };

    return (
      <div
        onClick={/*closeScreen*/ () => {}}
        className="block h-screen w-auto z-50 inset-0 bg-black_rgba fixed"
      >
        <div className="flex w-full h-screen justify-center content-center fixed">
          <div
            className="flex justify-start flex-wrap flex-col rounded rounded-xl bg-white text-black p-5 m-2 self-center w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-full"
            style={{ minHeight: 370, height: 370 }}
          >
            <div className="flex flex-col justify-between content-between h-full min-h-full">
              {userLoading === true ? (
                <Loading />
              ) : (
                <>
                  <div>
                    <p className="text-2xl font-bold">{`${user.firstname} ${user.lastname}`}</p>
                    <p>
                      {t(
                        "components.settings.organisation.user.delete_confirm"
                      )}
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: 30,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!isOwner() && (
                      <>
                        <div
                          className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-600"
                          onClick={() => setDeletePopup(false)}
                        >
                          {t("actions.cancel")}
                        </div>
                        {canEdit() && (
                          <div
                            className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-600"
                            onClick={removeUser}
                          >
                            {t("actions.delete")}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`3xl:w-1/2 2xl:w-2/3 w-1/3 rounded-xl  mt-8 mr-12 flex flex-col justify-center items-start content-start bg-white user-card ${
        isActive() === false && "inactive"
      }`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          alignContent: "flex-start",
          width: "100%",
        }}
      >
        <div>
          <p className="text-xl font-bold">{`${user.firstname} ${user.lastname}`}</p>
          <p className="text-sm ">{user.email}</p>
        </div>
        <div
          style={{
            borderRadius: 15,
            borderColor: "#000",
            borderStyle: "solid",
            borderWidth: 0.5,
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 3,
            paddingBottom: 1,
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#DDD",
          }}
        >
          <p style={{ textAlign: "center", fontWeight: "lighter" }}>
            {translateUserRole(user.role)}
          </p>
        </div>
      </div>
      <div style={{ marginTop: 15, width: "100%" }}>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <p>{`Status:`}</p>
          </Grid>
          <Grid item xs={7}>
            <p style={{ fontWeight: "bold" }}>
              {translateUserState(user.status)}
            </p>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {!isOwner() && canEdit() && (
          <>
            <div
              className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-600"
              onClick={() => setEdit(true)}
            >
              {t("actions.edit")}
            </div>
            <div
              className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-red-900"
              onClick={() => setDeletePopup(true)}
            >
              {t("actions.delete")}
            </div>
          </>
        )}
      </div>
      {edit && <UserEdit />}
      {deletePopup && <UserDelete />}
    </div>
  );
};

export default UserCard;
