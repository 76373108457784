import "firebaseui/dist/firebaseui.css";
import aboCloudImage from "./../../../assets/abocloud_image.svg";
import logo from "./../../../assets/abocloud_logo.svg";
import "firebase/auth";
import { useState } from "react";
import "./Login.css";
import LoginComponent from "./Components/LoginComponent";
import RegisterComponent from "./Components/RegisterComponent";
import ForgotPasswordComponent from "./Components/ForgotPasswordComponent";

interface ILoginState {
  isRegister: boolean;
  loginComponent: any;
  loginText: string;
  resetPassword: boolean;
}

const Login = ({isRegister= false }: {isRegister?: boolean}) => {
  const setResetPasswordState = (state: boolean) => {
    setLoginState({
      ...loginState,
      resetPassword: state,
    });
  };
  const [loginState, setLoginState] = useState<ILoginState>({
    isRegister: isRegister,
    loginComponent: (isRegister? <RegisterComponent/> :
      <LoginComponent setForgotPasswordState={setResetPasswordState} />
    ),
    loginText: isRegister?"login":"jetzt registrieren",
    resetPassword: false,
  });
  const switchLoginState = () => {
    if (loginState.isRegister) {
      setLoginState({
        ...loginState,
        isRegister: false,
        loginComponent: (
          <LoginComponent setForgotPasswordState={setResetPasswordState} />
        ),
        loginText: "jetzt registrieren",
      });
    } else {
      setLoginState({
        ...loginState,
        isRegister: true,
        loginComponent: <RegisterComponent />,
        loginText: "login",
      });
    }
  };

  return (
    <div className="grid grid-cols-2 2xl:grid-cols-1 gap-4 min-h-screen ">
      <div className="mx-72 3xl:mx-32 2xl:mx-56 lg:mx-9  my-36 ">
        <div className="flex justify-between items-center">
          <img className="" src={logo} alt="Logo" />
          <p
            onClick={() => switchLoginState()}
            className=" text-base m-2 cursor-pointer"
          >
            {loginState.loginText}
          </p>
        </div>
        <div>
          {loginState.resetPassword ? (
            <ForgotPasswordComponent setForgotPasswordState={setResetPasswordState} />
          ) : (
            loginState.loginComponent
          )}
        </div>
      </div>

      <div className="bg-black overflow-hidden 2xl:hidden">
        <img
          className="m-auto min-h-full overflow-hidden"
          src={aboCloudImage}
          alt="abocloud-Logo"
        />
      </div>
    </div>
  );
};
export default Login;
