import { useContext, useEffect, useState } from "react";
import { UserRole, UserStatus } from "../../types/User";
import { SessionContext } from "../../context/SessionContext";

type RoleWrapperProps = {
  children: any;
  roles: Array<UserRole>;
};

const RoleWrapper = (props: RoleWrapperProps) => {
  const { userRole, userStatus } = useContext(SessionContext);
  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.roles.includes(userRole) &&
      [UserStatus.Active].includes(userStatus)
    ) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  }, [props, userRole, userStatus]);

  if (verified) {
    return props.children;
  } else {
    return <></>;
  }
};

export default RoleWrapper;
