import { FaSync } from "react-icons/fa";
import { translateAboState } from "../../../../../../../utils/utils";
import BadgeAmount from "../../../../../../Badge/BadgeAmount";

interface IAbonnementCardProps {
  abonnement: Record<string, any>;
  onClick?: (path: string, object: any) => void;
}

const AbonnementCard = ({ abonnement, onClick }: IAbonnementCardProps) => {
  const doClickTransaction = (path: string, transaction: any) => {
    if (onClick && transaction) {
      onClick(path, transaction);
    }
  };
  const data = abonnement.data;

  return (
    <div
      key={`abonnement_${abonnement.id}`}
      onClick={() => doClickTransaction("../abo/" + abonnement.id, abonnement)}
      className="flex bg-gray-100 p-14 rounded-xl w-full my-2 cursor-pointer"
    >
      <div className="grid grid-cols-5 xl:grid-cols-1 gap-2 items-center m-4 content-center w-full">
        <p className="col-span-2">{data.description}</p>
        <div className="flex col-span-1">
          <FaSync className="text-xl mr-4 p-0.5 inline-block " />
          <p className="">{data.interval}</p>
        </div>
        <div className="text-sm col-span-1 font-light justify-center xl:justify-start flex">
          <BadgeAmount amount={data.amount} />
        </div>
        <p className="col-span-1">{translateAboState(data.status)}</p>
      </div>
    </div>
  );
};

export default AbonnementCard;
