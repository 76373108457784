import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom-v5-compat";
import { translateKeyState } from "../../../../utils/utils";
import { ProfileStatus } from "../../../../types/Profile";

type TokenCardAdminProps = {
  id: string;
  merchantid: string;
  title: string;
  children: any;
  apikey: string;
  url: string;
  status: ProfileStatus;
  actions?: any;
};

interface ITokenCardAdminState {
  showToken: boolean;
}

const TokenCard = (props: TokenCardAdminProps) => {
  const { t } = useTranslation();
  const [tokenCardState, setTokenCardState] = useState<ITokenCardAdminState>({
    showToken: false,
  });

  const showToken = () => {
    setTokenCardState({
      ...tokenCardState,
      showToken: true,
    });
  };

  return (
    <div className=" bg-gray-100  rounded rounded-xl w-full">
      <div className="flex justify-start ml-4 mt-4 gap-4 inline-block text-left h-max bg-gray-100 rounded-t-xl overflow-hidden align-middle">
        <Link to={`../atoken/${props.merchantid}/${props.id}`}>
          {" "}
          <p className="h-full text-xl font-bold flex hover:underline">
            {props.title}
          </p>
        </Link>
        <p className="h-full my-auto text-sm flex text-gray-500 font-bold">
          {props.url}
        </p>
        <p className="h-full w-full text-right mr-4 my-auto text-sm text-gray-500 font-bold">
          {translateKeyState(props.status)}
        </p>
      </div>
      <div className="grid grid-flow-col auto-cols-max xl:grid-flow-row xl:grid-cols-2 sm:grid-cols-1 m-4 text-left  flex items-center">
        <div className="mr-8 font-bold flex flex-shrink">
          {t("components.settings.token.key")}
        </div>
        <div className="mr-2 flex flex-shrink">
          <input
            className="w-max focus:outline-none bg-white p-1 rounded"
            type={tokenCardState.showToken ? "text" : "password"}
            readOnly={true}
            value={props.apikey}
          />
        </div>
        <div className="grid grid-flow-col auto-cols-max xl:mt-4 font-bold">
          <div
            className={
              tokenCardState.showToken
                ? "hidden"
                : "mr-4 text-sm flex flex-shrink cursor-pointer"
            }
            onClick={() => showToken()}
          >
            {t("components.settings.token.show")}
          </div>
          <div className="mr-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-700">
            <CopyToClipboard text={props.apikey}>
              <p>{t("components.settings.token.copy")}</p>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default TokenCard;
