import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { useTranslation } from "react-i18next";
// import { useContext, useEffect, useState } from "react";
// import {
//   ContingentForMonth,
//   // getAboContingent,
// } from "../../../../utils/statistics";
// import { AuthContext } from "../../../../components/Context/AuthContext";
import { addTextToChart } from "./helper";
// import Loading from "../../../../components/Loading";
import useAboQuota from "../../../../hooks/quoata/useAboQuota";
import unlimited_image from "./../../../../assets/abocloud_unbegrenzt.svg";

// interface IContingentChartAbosState {
//   data: ContingentForMonth;
//   loading: boolean;
// }

const ContingentChartAbos = () => {
  const { t } = useTranslation();
  // const auth = useContext(AuthContext);
  const aboQuota = useAboQuota();
  // const [contingentChartAbosState, setContingentChartAbosState] =
  //   useState<IContingentChartAbosState>({
  //     data: { contingent: 0, current: 0, percentReached: 0, percentLeft: 100 },
  //     loading: true,
  //   });

  // useEffect(() => {
  //   const refreshData = async () => {
  //     const data = await getAboContingent(auth.authContextState.user.uid);
  //     setContingentChartAbosState((contingentChartAbosState) => {
  //       return {
  //         ...contingentChartAbosState,
  //         data: data,
  //         loading: false,
  //       };
  //     });
  //   };
  /*
    aboCollectionRef(auth.authContextState.user.uid).onSnapshot(() => {
      refreshData();
    });*/
  //   refreshData();
  // }, [auth.authContextState.user.uid]);

  return (
    <div className="rounded rounded-xl bg-white w-1/4 2xl:w-1/2 lg:w-3/4 flex flex-wrap p-4">
      <>
        <div className="w-2/6">
          {aboQuota.quoata === -1 ? (
            <img
              className=""
              src={unlimited_image}
              alt="Logo"
              style={{
                width: "100%",
                padding: 10,
              }}
            />
          ) : (
            <Doughnut
              data={{
                datasets: [
                  {
                    borderColor: "white",
                    borderWidth: 0,
                    data: [aboQuota.percent, aboQuota.percentLeft],
                    backgroundColor:
                      aboQuota.percent > 90
                        ? ["rgb(166, 0, 0)", "rgb(235, 235, 235)"]
                        : aboQuota.percent > 70
                        ? ["rgb(201, 83, 4)", "rgb(235, 235, 235)"]
                        : ["rgb(0, 0, 0)", "rgb(235, 235, 235)"],
                  },
                ],
              }}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
                events: [],
              }}
              plugins={[
                {
                  id: "textInMiddle",
                  beforeDraw: (chart) => {
                    //@ts-ignore
                    addTextToChart(chart);
                  },
                  afterDraw: (chart) => {
                    //@ts-ignore
                    addTextToChart(chart);
                  },
                },
              ]}
            />
          )}
        </div>
        <div className="w-3/6">
          <p className="font-bold text-xl">{t("components.dashboard.abos")}</p>
          <div className="flex flex-wrap gap-2 m-4 justify-center text-center">
            <p className="w-4/6 text-sm font-thin">
              {t("components.dashboard.included_tariff")}
            </p>
            <p className="w-1/6 text-sm font-bold">
              {aboQuota.quoata === -1
                ? t("components.dashboard.unlimited")
                : aboQuota.quoata}
            </p>
            <p className="w-4/6 text-sm font-thin">
              {t("components.dashboard.current")}
            </p>
            <p className="w-1/6 text-sm font-bold">{aboQuota.current}</p>
          </div>
        </div>
      </>
    </div>
  );
};

export default ContingentChartAbos;
