import NoEntry from "../../../../../NoEntries";
import AbonnementCard from "./AbonnementCard";

interface IAbonnementTabProps {
  abonnements?: Array<Record<string, any>>;
  onClick?: (path: string, object: any) => void;
}

const AbonnementTab = ({ onClick, abonnements }: IAbonnementTabProps) => {
  if (!abonnements || abonnements === null || abonnements.length === 0)
    return <NoEntry />;

  const arrayTrans = abonnements.reverse();

  return (
    <>
      {arrayTrans?.map((abonnement) => {
        return (
          <AbonnementCard
            key={abonnement.id}
            abonnement={abonnement}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

export default AbonnementTab;
