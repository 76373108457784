import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import {
  createTimestampFromObject,
  transactionCollectionRef,
} from "../../../utils/firestore";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { CLASS_NAME_MAIN, FIREBASE_REQUEST_LIMIT } from "../../../constants";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/Body";
import { Transaction } from "../../../types/Transaction";
import {
  createArrayByProperty,
  formatDateFromFirebaseTimestamp,
  translateTransactionState,
} from "../../../utils/utils";
import Filter, { FilterTypes } from "../../../components/Filter";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../../context/SessionContext";
// import { useNavigate } from "react-router-dom-v5-compat";

// type TransactionProps = {
//   history: any;
//   location: any;
//   match: any;
// };

interface ITransactionState {
  loading: boolean;
  transactions: any[];
  moreItemsExist: boolean;
  loadingMore: boolean;
  transactionQuery:
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query;
}

const Transactions = () => {
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);
  const firestore = useFirestore();
  const navigate = useNavigate();

  const [transactionState, setTransactionState] = useState<ITransactionState>({
    loading: true,
    transactions: [],
    moreItemsExist: true,
    loadingMore: false,
    transactionQuery: transactionCollectionRef(mid || "-")
      .orderBy("updateTime", "desc")
      .where(
        "profileRef",
        "==",
        firebase.firestore().doc(currentProfile?.path || "-/-")
      ),
  });

  useEffect(() => {
    if (currentProfile?.path) {
      updateTransactionQuery(
        transactionCollectionRef(mid || "-")
          .where(
            "profileRef",
            "==",
            firebase.firestore().doc(currentProfile.path)
          )
          .orderBy("updateTime", "desc")
      );
    }
  }, [currentProfile, mid]);

  useEffect(() => {
    const refreshTransactions = async (
      transactionsParam?: firebase.firestore.QuerySnapshot
    ) => {
      let transactions: firebase.firestore.QuerySnapshot;
      if (transactionsParam) {
        transactions = transactionsParam;
      } else {
        transactions = (await transactionState.transactionQuery
          .limit(FIREBASE_REQUEST_LIMIT)
          .get()) as firebase.firestore.QuerySnapshot;
      }
      const transactionArray = createArrayByProperty<Transaction>(transactions);
      setTransactionState((transactionState) => {
        return {
          ...transactionState,
          transactions: transactionArray,
          loading: false,
          ...(transactions.size < FIREBASE_REQUEST_LIMIT && {
            moreItemsExist: false,
          }),
        };
      });
    };
    transactionState.transactionQuery.limit(FIREBASE_REQUEST_LIMIT).onSnapshot(
      (transactions) => {
        refreshTransactions(transactions);
      },
      () => {
        setTransactionState((transactionState) => {
          return {
            ...transactionState,
            loading: false,
            moreItemsExist: false,
          };
        });
      }
    );
  }, [mid, firestore, transactionState.transactionQuery]);

  const updateTransactionQuery = (query: firebase.firestore.Query) => {
    setTransactionState((transactionState) => {
      return {
        ...transactionState,
        loading: true,
        transactions: [],
        transactionQuery: query,
      };
    });
  };

  const loadMoreItems = async () => {
    setTransactionState((transactionState) => {
      return { ...transactionState, loadingMore: true };
    });
    if (transactionState.transactions.length <= 0) {
      setTransactionState((transactionState) => {
        return { ...transactionState, loadingMore: false };
      });
      return;
    }
    const transactions = await transactionState.transactionQuery
      .limit(FIREBASE_REQUEST_LIMIT)
      .startAfter(
        createTimestampFromObject(
          transactionState.transactions[
            transactionState.transactions.length - 1
          ].data.updateTime
        ).toDate()
      )
      .get();
    if (transactions.empty) {
      setTransactionState((transactionState) => {
        return { ...transactionState, loading: false, loadingMore: false };
      });
      return;
    }

    const transactionArray = createArrayByProperty<Transaction>(transactions);
    setTransactionState((transactionState) => {
      return {
        ...transactionState,
        transactions: transactionState.transactions.concat(transactionArray),
        loading: false,
        loadingMore: false,
        ...(transactions.size < FIREBASE_REQUEST_LIMIT && {
          moreItemsExist: false,
        }),
      };
    });
  };

  const handleOnClick = useCallback(
    (link: string, transaction?: any) =>
      navigate(link, {
        state: { transaction: transaction },
      }),
    [navigate]
  );

  return (
    <div className={CLASS_NAME_MAIN}>
      <Filter
        filterType={FilterTypes.TRANSACTION}
        query={transactionCollectionRef(mid || "-")}
        updateQuery={updateTransactionQuery}
      />
      <LoadingComponent isLoading={transactionState.loading}>
        <Table
          loadMoreItems={loadMoreItems}
          moreItemsExist={transactionState.moreItemsExist}
          loadingMoreItems={transactionState.loadingMore}
          itemsTitle={[
            t("components.transactions.id"),
            t("components.transactions.created"),
            t("components.transactions.state"),
          ]}
        >
          {transactionState.transactions.map((transaction) => {
            return (
              <TableBody
                key={transaction.id}
                categoryTitle={`${t("components.abonnements.abonnement")}`}
                component={transaction}
                componentPath={"/transaction/" + transaction.id}
                isClickable={true}
                onClick={handleOnClick}
                id={transaction.id}
                rows={3}
                itemsBody={[
                  {
                    title: t("components.transactions.id"),
                    value: transaction.id,
                  },
                  {
                    title: t("components.transactions.created"),
                    value: formatDateFromFirebaseTimestamp(
                      transaction.data.createTime
                    ),
                  },
                  {
                    title: t("components.transactions.state"),
                    value: translateTransactionState(transaction.data.status),
                  },
                ]}
              />
            );
          })}
        </Table>
      </LoadingComponent>
    </div>
  );
};

export default Transactions;
