import { getTailwindColumnClass } from "../../../utils/utils";

type TableHeadProps = {
  itemsTitle: string[];
  rows: number;
};

const TableHead = (props: TableHeadProps) => {
  const colString: string = getTailwindColumnClass(props.rows);
  return (
    <li
      className={"block rounded-xl bg-black mt-4 m-4 mx-10 font-bold text-lg xl:hidden grid".concat(
        colString
      )}
    >
      {props.itemsTitle.map((item) => {
        return (
          <div className="block text-white text-left m-2 w-full" key={item+(Math.random()+1000)} id={item}>
            {item}
          </div>
        );
      })}
    </li>
  );
};

export default TableHead;
