import { useTranslation } from "react-i18next";

interface IProductCardProps {
  product: Record<string, any>;
}

const ProductCard = ({ product }: IProductCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex bg-gray-100 p-14 rounded-xl w-full my-4">
      <div className="grid grid-cols-4 xl:grid-cols-1 gap-2 items-center m-4 content-center w-full">
        <div className="col-span-1 mx-4 justify-center flex ">
          <img
            className="mx-4 w-1/2"
            alt={`${t("components.abonnement_details.products.product_image")}`}
            src={product.imageUrl}
          />
        </div>
        <div className="col-span-2 grid grid-rows-3 gap-1">
          <p className="text-2xl mb-4">{product.name}</p>
          <div className="flex grid grid-cols-4 gap-1">
            <p className="font-light">{`${t(
              "components.abonnement_details.products.type"
            )}:`}</p>
            <p className="col-span-3">{product.type}</p>
          </div>
          <div className="flex grid grid-cols-4 gap-1">
            <p className="font-light">{`${t(
              "components.abonnement_details.products.count"
            )}:`}</p>
            <p className="col-span-3">{product.quantity}</p>
          </div>
          <div className="flex grid grid-cols-4 gap-1">
            <p className="font-light">{`${t(
              "components.abonnement_details.products.sku"
            )}:`}</p>
            <p className="col-span-3">{product.sku}</p>
          </div>
        </div>
        <div className="col-span-1 flex mr-8 justify-end xl:justify-center xl:mt-4 grid grid-rows-2 gap-1">
          <p className="text-2xl font-bold">{`${product.totalAmount.value} ${product.totalAmount.currency}`}</p>
          <p className="text-sm font-light justify-end flex">{`${
            product.vatAmount.value
          } ${product.vatAmount.currency} (${t(
            "components.abonnement_details.products.vat"
          )})`}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
