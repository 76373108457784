import { Languages } from "../types/language";
export const SET_LANGUAGE = "SET_LANGUAGE";

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: Languages;
}

export type BaseActionTypes = SetLanguageAction;

export type LanguageType = Languages;
