import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { useTranslation } from "react-i18next";
import { addTextToChart } from "./helper";
import useTransactionQuota from "../../../../hooks/quoata/useTransactionQuota";
import unlimited_image from "./../../../../assets/abocloud_unbegrenzt.svg";

const ContingentChartTransactions = () => {
  const { t } = useTranslation();
  const transactionQuota = useTransactionQuota();

  return (
    <div className="rounded rounded-xl bg-white w-1/4 2xl:w-1/2 lg:w-3/4 flex flex-wrap p-4">
      {/* {contingentChartAbosState.loading ? (
        <Loading />
      ) : ( */}
      <>
        <div className="w-2/6">
          {transactionQuota.quoata === -1 ? (
            <img
              className=""
              src={unlimited_image}
              alt="Logo"
              style={{
                width: "100%",
                padding: 10,
              }}
            />
          ) : (
            <Doughnut
              data={{
                datasets: [
                  {
                    borderColor: "white",
                    borderWidth: 0,
                    label: `${t(
                      "components.dashboard.statistic.abos.tooltip_text"
                    )}`,
                    data: [
                      transactionQuota.percent,
                      transactionQuota.percentLeft,
                    ],
                    backgroundColor:
                      transactionQuota.percent > 90
                        ? ["rgb(166, 0, 0)", "rgb(235, 235, 235)"]
                        : transactionQuota.percent > 70
                        ? ["rgb(201, 83, 4)", "rgb(235, 235, 235)"]
                        : ["rgb(0, 0, 0)", "rgb(235, 235, 235)"],
                  },
                ],
              }}
              options={{
                parsing: { xAxisKey: "day" },
                cutout: "70%",
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
                events: [],
              }}
              plugins={[
                {
                  id: "textInMiddle",
                  beforeDraw: (chart) => {
                    //@ts-ignore
                    addTextToChart(chart);
                  },
                  afterDraw: (chart) => {
                    //@ts-ignore
                    addTextToChart(chart);
                  },
                },
              ]}
            />
          )}
        </div>
        <div className="w-3/6">
          <p className="font-bold text-xl">
            {t("components.dashboard.transactions")}
          </p>
          <div className="flex flex-wrap gap-2 m-4 justify-center text-center">
            <p className="w-4/6 text-sm font-thin">
              {t("components.dashboard.included_tariff")}
            </p>
            <p className="w-1/6 text-sm font-bold">
              {/* {contingentChartAbosState.data.contingent} */}
              {transactionQuota.quoata === -1
                ? t("components.dashboard.unlimited")
                : transactionQuota.quoata}
            </p>
            <p className="w-4/6 text-sm font-thin">
              {t("components.dashboard.current")}
            </p>
            <p className="w-1/6 text-sm font-bold">
              {/* {contingentChartAbosState.data.current} */}
              {transactionQuota.current}
            </p>
          </div>
        </div>
      </>
    </div>
  );
};

export default ContingentChartTransactions;
