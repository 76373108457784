import { forwardRef, useImperativeHandle, useRef } from "react";

type Option = {
  id: string;
  value: string | undefined;
  name: string;
};

type DropdownProps = {
  initialId?: string;
  options: Option[];
  className?: string;
  title?: string;
  id: string;
  onChangeListener: (event: any, id: string) => void;
};

const Dropdown = forwardRef((props: DropdownProps, ref) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  useImperativeHandle(ref, () => ({
    reset() {
      selectRef.current!.selectedIndex = 0;
    },
  }));

  return (
    <select
      ref={selectRef}
      onChange={(e) => props.onChangeListener(e, props.id)}
      defaultValue={props.initialId ? props.initialId : undefined}
      className={
        props.className
          ? props.className
          : "rounded-xl w-full p-3 my-4 border-black border"
      }
    >
      {props.title ? (
        <option
          key="title"
          id="title"
          className="option"
          selected={true}
          disabled={true}
          value={props.title}
        >
          {props.title}
        </option>
      ) : (
        <></>
      )}
      {props.options.map((elem: Option) => {
        return (
          <option
            key={elem.id}
            id={elem.id}
            className="option"
            value={elem.value}
          >
            {elem.name}
          </option>
        );
      })}
    </select>
  );
});

export default Dropdown;
export type { Option };
