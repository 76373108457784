import { useTranslation } from "react-i18next";
import Button from "../Button";
import LoadingComponent from "../Loading/LoadingWrapper";
import TableHead from "./Head";

type TableProps = {
  itemsTitle: string[];
  moreItemsExist?: boolean;
  loadingMoreItems?: boolean;
  loadMoreItems?: () => {};
  children?: any;
};

const Table = (props: TableProps) => {
  const { t } = useTranslation();
  const loadMore = () => {
    if (props.loadMoreItems !== undefined) {
      props.loadMoreItems();
    }
  };
  const tableSize = props.itemsTitle.length;
  return (
    <ul className="block">
      <TableHead itemsTitle={props.itemsTitle} rows={tableSize} />
      {props.children ? props.children : "Keine Daten"}
      {!props.moreItemsExist ? (
        <p className="mt-2">{t("navigation.no_more_items")}</p>
      ) : (
        <LoadingComponent isLoading={props.loadingMoreItems!== undefined && props.loadingMoreItems}>
          <Button
            title={t("navigation.load_more_items")}
            onClickListener={() => loadMore()}
          />
        </LoadingComponent>
      )}
    </ul>
  );
};

export default Table;
