import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { AuthContext } from "../../../../components/Context/AuthContext";
import {
  getWeekStatisticsCustomer,
  ValueAtDay,
} from "../../../../utils/statistics";
import Loading from "../../../../components/Loading";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../../../context/SessionContext";

interface ICustomerChartState {
  data: Array<ValueAtDay>;
  loading: boolean;
}

const CustomerChart = () => {
  const { mid } = useContext(AuthContext);
  const { currentProfile } = useContext(SessionContext);
  const { t } = useTranslation();
  const [customerChartState, setCustomerChartState] =
    useState<ICustomerChartState>({
      data: Array<ValueAtDay>(),
      loading: true,
    });

  useEffect(() => {
    const refreshData = async () => {
      try {
        const data = await getWeekStatisticsCustomer(
          mid || "-",
          currentProfile?.path || "-/-"
        );

        setCustomerChartState((customerChartState) => {
          return {
            ...customerChartState,
            data: data,
            loading: false,
          };
        });
      } catch {
        setCustomerChartState((customerChartState) => {
          return {
            ...customerChartState,
            loading: false,
          };
        });
      }
    };
    /*
    customerCollectionRef(auth.authContextState.user.uid).onSnapshot(() => {
      refreshData();
    });*/
    refreshData();
  }, [mid, currentProfile?.path]);

  return (
    <div className="bg-white rounded rounded-2xl p-4 pr-8">
      {customerChartState.loading ? (
        <Loading />
      ) : (
        <>
          <p className="font-bold">
            {t("components.dashboard.statistic.customer.title")}
          </p>
          <Line
            key={Math.random()}
            data={{
              datasets: [
                {
                  backgroundColor: "black",
                  borderColor: "black",
                  label: `${t(
                    "components.dashboard.statistic.customer.tooltip_text"
                  )}`,
                  data: customerChartState.data,
                  tension: 0.2,
                  borderWidth: 3,
                },
              ],
            }}
            options={{
              parsing: { xAxisKey: "day", yAxisKey: "value" },
              scales: {
                y: {
                  type: "linear",
                  position: "left",
                  title: {
                    text: `${t(
                      "components.dashboard.statistic.customer.y_axis"
                    )}`,
                    display: true,
                  },
                },
                x: {
                  reverse: true,
                },
              },
              plugins: {
                legend: {
                  labels: {
                    font: {
                      size: 32,
                      family: "Comfortaa",
                      weight: "bold",
                    },
                  },
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  titleFont: {
                    size: 1,
                    family: "Comfortaa",
                    weight: "bold",
                  },
                  bodyFont: {
                    size: 18,
                    family: "Comfortaa",
                    weight: "bold",
                  },
                  displayColors: false,
                  callbacks: {
                    title: function () {
                      return "";
                    },
                    beforeLabel: () => {
                      return "";
                    },
                    beforeBody: () => {
                      return `${t(
                        "components.dashboard.statistic.customer.tooltip_text"
                      )}`;
                    },
                    label: (context) => {
                      return `${context.formattedValue}`;
                    },
                  },
                },
              },
              layout: {
                padding: 0,
              },
            }}
          />
        </>
      )}
    </div>
  );
};
export default CustomerChart;
