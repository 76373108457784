import { SetStateAction, useContext, useState } from "react";
import Loading from "../../../../components/Loading";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../../../context/SessionContext";
import { UserRole } from "../../../../types/User";
import { Functions } from "../../../../firebase";
import { AuthContext } from "../../../../components/Context/AuthContext";

const DeleteAccountDialog = ({
  setDialogVisible,
}: {
  setDialogVisible: (value: SetStateAction<boolean>) => void;
}) => {
  const deleteUser = Functions().httpsCallable(
    "management-calls-user-deleteSelf"
  );
  const { t } = useTranslation();
  const { userRole } = useContext(SessionContext);
  const { signOut } = useContext(AuthContext);
  const [deleting, setDeleting] = useState<boolean>(false);

  const isOwner = (): boolean => {
    return userRole === UserRole.Owner;
  };

  const deleteAccount = () => {
    setDeleting(true);
    deleteUser().then(() => {
      setDeleting(false);
      signOut();
    });
  };
  //   console.log(isOwner());
  //   setDeleting(false);

  return (
    <div
      onClick={/*closeScreen*/ () => {}}
      className="block h-screen w-auto z-50 inset-0 bg-black_rgba fixed"
    >
      <div className="flex w-full h-screen justify-center content-center fixed">
        <div
          className="flex justify-start flex-wrap flex-col rounded rounded-xl bg-white text-black p-5 m-2 self-center w-1/3 3xl:w-1/2 2xl:w-2/3 lg:w-full"
          style={{ minHeight: 370 }}
        >
          <div className="flex flex-col justify-between content-between h-full min-h-full">
            {deleting === true ? (
              <Loading />
            ) : (
              <>
                <div>
                  <p className="text-2xl font-bold">
                    {t("components.settings.account.delete_dialog.title")}
                  </p>
                  <p>
                    {t(
                      "components.settings.account.delete_dialog.description_1"
                    )}
                  </p>
                  <p>
                    {t(
                      "components.settings.account.delete_dialog.description_2"
                    )}
                  </p>
                  {isOwner() === true && (
                    <div className="bg-red-800 text-white p-4 rounded flex flex-col justify-start content-start items-start">
                      <p className="text-left font-bold">
                        {t(
                          "components.settings.account.delete_dialog.description_owner_1"
                        )}
                      </p>
                      <p className="text-left">
                        {t(
                          "components.settings.account.delete_dialog.description_owner_2"
                        )}
                      </p>
                      <Grid
                        style={{ marginTop: 20 }}
                        container
                        spacing={0}
                        alignContent={"center"}
                        alignItems={"center"}
                      >
                        <Grid item xs={1}>
                          <p className="text-lg font-bold">-</p>
                        </Grid>
                        <Grid item xs={11}>
                          <p className="text-lg text-left">
                            {t("components.settings.account.delete_dialog.abo")}
                          </p>
                        </Grid>
                        <Grid item xs={1}>
                          <p className="text-lg font-bold">-</p>
                        </Grid>
                        <Grid item xs={11}>
                          <p className="text-lg text-left">
                            {t(
                              "components.settings.account.delete_dialog.customer"
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={1}>
                          <p className="text-lg font-bold">-</p>
                        </Grid>
                        <Grid item xs={11}>
                          <p className="text-lg text-left">
                            {t(
                              "components.settings.account.delete_dialog.transaction"
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={1}>
                          <p className="text-lg font-bold">-</p>
                        </Grid>
                        <Grid item xs={11}>
                          <p className="text-lg text-left">
                            {t(
                              "components.settings.account.delete_dialog.user"
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={1}>
                          <p className="text-lg font-bold">-</p>
                        </Grid>
                        <Grid item xs={11}>
                          <p className="text-lg text-left">
                            {t(
                              "components.settings.account.delete_dialog.payment"
                            )}
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginTop: 30,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    <div
                      className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-blue-600"
                      onClick={() => setDialogVisible(false)}
                    >
                      {t("actions.cancel")}
                    </div>

                    <div
                      className="ml-2 text-sm flex flex-shrink cursor-pointer font-bold text-red-600"
                      onClick={deleteAccount}
                    >
                      {t("components.settings.account.delete")}
                    </div>
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountDialog;
