import {
  formatDateFromFirebaseTimestamp,
  translateTransactionState,
} from "../../../../../../../utils/utils";
import BadgeAmount from "../../../../../../Badge/BadgeAmount";

interface ITransactionCardProps {
  transaction: Record<string, any>;
  onClick?: (path: string, object: any) => void;
}

const TransactionCard = ({ transaction, onClick }: ITransactionCardProps) => {
  const doClickTransaction = (path: string, transaction: any) => {
    if (onClick && transaction) {
      onClick(path, transaction);
    }
  };
  const data = transaction.data;

  return (
    <div
      key={`transaction_${transaction.id}`}
      onClick={() =>
        doClickTransaction("../transaction/" + transaction.id, transaction)
      }
      className="flex bg-gray-100 p-14 rounded-xl w-full my-2 cursor-pointer"
    >
      <div className="grid grid-cols-5 xl:grid-cols-1 gap-2 items-center m-4 content-center w-full">
        <p className="col-span-3">
          {formatDateFromFirebaseTimestamp(data.createTime)}
        </p>
        <p className="col-span-3">{data.ppId}</p>
        <p className="col-span-3">{data.externalId}</p>
        <div className="text-sm font-light justify-center xl:justify-start flex">
          <BadgeAmount amount={data.amount} />
        </div>
        <p className="col-span-3">{translateTransactionState(data.status)}</p>
      </div>
    </div>
  );
};

export default TransactionCard;
