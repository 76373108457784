import { useTranslation } from "react-i18next";
import ContingentChartAbos from "./ContingentChartAbos";
import ContingentChartCustomer from "./ContingentChartCustomer";
import ContingentChartTransactions from "./ContingentChartTransactions";
import moment from "moment";

const Contingent = () => {
  const { t } = useTranslation();
  const FIRST_DAY_MOTNH = moment().startOf("month").toDate();
  const LAST_DAY_MOTNH = moment().endOf("month").toDate();

  return (
    <div className="mt-16">
      <div className=" flex flex-wrap  w-full text-left mb-4">
        <p className="text-3xl font-bold w-full">
          {t("components.dashboard.your_contingents")}
        </p>
        <p className="font-thin w-max ">
          {t("components.dashboard.period", {
            from: moment(FIRST_DAY_MOTNH).format("DD.MM.YYYY"),
            to: moment(LAST_DAY_MOTNH).format("DD.MM.YYYY"),
          })}
        </p>
      </div>
      <div className="flex flex-wrap lg:justify-center gap-4 mb-8">
        <ContingentChartTransactions />
        <ContingentChartCustomer />
        <ContingentChartAbos />
      </div>
    </div>
  );
};

export default Contingent;
