const VersionInfo = () => {
  return (
    <div
      style={{
        marginTop: 0,
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontSize: 10,
          fontWeight: "lighter",
          textAlign: "center",
          color: "rgba(0,0,0,0.6)",
        }}
      >{`v1.0.0`}</p>
    </div>
  );
};

export default VersionInfo;
