import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { useTranslation } from "react-i18next";

const ProfileSelector = () => {
  const { profiles, selectProfile, currentProfile } =
    useContext(SessionContext);
  const { t } = useTranslation();
  const selectTheme = createTheme({
    palette: {
      primary: {
        light: "#000",
        main: "#000",
        dark: "#000",
        contrastText: "#000",
      },
    },
    typography: {
      fontFamily: ["Comfortaa"].join(","),
      fontSize: 12,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: { borderRadius: 15 },
          root: { marginRight: 5 },
        },
      },
    },
  });

  const onProfileSelect = (event: SelectChangeEvent<string>) => {
    if (event?.target?.value) selectProfile(event.target.value);
  };

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <ThemeProvider theme={selectTheme}>
        <FormControl fullWidth color="primary">
          <InputLabel id="profil-select-label">
            {t("components.settings.profiles.profile")}
          </InputLabel>
          <Select
            disabled={profiles.length <= 1}
            labelId="profil-select-label"
            id="profile-select"
            value={currentProfile !== null ? currentProfile.path : ""}
            label="Profil"
            onChange={onProfileSelect}
            style={{ borderRadius: 15, color: "#000" }}
          >
            {profiles.map((profile, index) => {
              return (
                <MenuItem
                  key={`profile_option_${index}`}
                  value={profile.path}
                >{`${profile.name}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
};

export default ProfileSelector;
