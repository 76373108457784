import { CLASS_NAME_MAIN } from "../../constants";

type SettingsWrapperProps = {
  title: string;
  children?: any;
};

const SettingsWrapper = (props: SettingsWrapperProps) => (
  <div className={CLASS_NAME_MAIN}>
    <div className="w-full mt-10 ml-32 lg:ml-4">
      <p className="text-3xl font-bold sm:text-xl text-left w-full break-normal overflow-hidden">
        {props.title}
      </p>
      {props.children}
    </div>
  </div>
);

export default SettingsWrapper;
