type ButtonProps = {
  title: string;
  color?: string;
  className?: string;
  disabled?: boolean;
  onClickListener?: () => void;
};

const Button = (props: ButtonProps) => {
  return (
    <button
      className={
        props.className
          ? props.className
          : "hover:bg-black_hover rounded-2xl p-2 text-white border-0 cursor-pointer " +
            (props.color ? props.color : "bg-black")
      }
      onClick={props.onClickListener}
      disabled={props.disabled && props.disabled === true}
    >
      {props.title}
    </button>
  );
};

export default Button;
