import { useContext, useState } from "react";
import { AuthContext } from "../../../../components/Context/AuthContext";
import Error from "../../../../components/Error";

interface IForgotPasswordState {
  email: string;
  success: boolean;
  errorComponent: any;
}

type ForgotPasswordProps = {
  setForgotPasswordState: (state: boolean) => void;
};

const ForgotPasswordComponent = (props: ForgotPasswordProps) => {
  const [forgotPasswordState, setForgotPasswordState] =
    useState<IForgotPasswordState>({
      email: "",
      success: false,
      errorComponent: null,
    });
  const auth = useContext(AuthContext);

  const onLoginDataChanged = (event: any, id: string) => {
    if (id === "email") {
      setForgotPasswordState({
        ...forgotPasswordState,
        email: event.target.value,
      });
    } else {
      return;
    }
  };

  const sendResetMail = () => {
    auth
      .sendPasswordResetEmail(forgotPasswordState.email)
      .then(() => {
        setForgotPasswordState({
          ...forgotPasswordState,
          errorComponent: null,
          success: true,
        });
      })
      .catch((error) => {
        setForgotPasswordState({
          ...forgotPasswordState,
          errorComponent: <Error errorMessage={error.message} />,
          success: false,
        });
      });
    return false;
  };
  if (forgotPasswordState.success) {
    return (
      <div>
        <p className=" mt-16 text-3xl mb-32 font-bold">
          Willkommen bei abocloud
        </p>
        {forgotPasswordState.errorComponent}
        <div className="block w-full my-2 box-border text-center">
          <p className="my-6">
            Wir haben dir eine E-Mail zum zurücksetzen deines Passworts
            gesendet!
          </p>
          <p
            onClick={() => props.setForgotPasswordState(false)}
            className="cursor-pointer"
          >
            zurück
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p className=" mt-16 text-3xl mb-32 font-bold">
          Willkommen bei abocloud
        </p>
        {forgotPasswordState.errorComponent}
        <div className="block w-full my-2 box-border text-center">
          <input
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                sendResetMail();
              }
            }}
            onChange={(event) => onLoginDataChanged(event, "email")}
            type="email"
            id="email"
            placeholder="E-Mail"
            className="block mx-auto rounded-lg text-base w-full p-2 my-2 box-border border-black border text-black"
          />
          <input
            onClick={() => sendResetMail()}
            type="submit"
            id="login"
            data-testid="login"
            value="passwort zurücksetzen"
            className="block mx-auto rounded-lg p-4 w-full text-base my-8 box-border text-white bg-black cursor-pointer"
          />
          <p
            onClick={() => props.setForgotPasswordState(false)}
            className="cursor-pointer"
          >
            zurück
          </p>
        </div>
      </div>
    );
  }
};

export default ForgotPasswordComponent;
