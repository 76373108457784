import {Tooltip as ReactTooltip} from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

type CustomerTooltipProps = {
  id: string;
  text: string;
};

const CustomerTooltip = (props: CustomerTooltipProps) => {
  return (
    <ReactTooltip

      id={props.id}
      // type="info"
      place="bottom"
      // effect="solid"
      // backgroundColor="black"
      
    >
      <span>{props.text}</span>
    </ReactTooltip>
  );
};

export default CustomerTooltip;
