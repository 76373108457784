import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import JavascriptTimeAgo from "javascript-time-ago";
import enLocale from "javascript-time-ago/locale/en";
import deLocale from "javascript-time-ago/locale/de";

import numbro from "numbro";
import moment from "moment";
import "moment/locale/de";

import de from "./de.json";
import en from "./en.json";
import { Languages } from "../types/language";

const deDE = require("numbro/languages/de-DE");
const enGB = require("numbro/languages/en-GB");

numbro.registerLanguage({
  ...deDE,
  languageTag: "de",
  abbreviations: {
    thousand: "Tsd.",
    million: "Mill.",
    billion: "Mrd.",
    trillion: "Bill.",
  },
  currencyFormat: {
    thousandSeparated: true,
    spaceSeparated: true,
    mantissa: 2,
  },
});

numbro.registerLanguage({
  ...enGB,
  languageTag: "en",
  currencyFormat: {
    thousandSeparated: true,
    spaceSeparated: true,
    mantissa: 2,
  },
});

// JavascriptTimeAgo.locale(enLocale);
// JavascriptTimeAgo.locale(deLocale);

JavascriptTimeAgo.addLocale(enLocale);
JavascriptTimeAgo.addLocale(deLocale);

const resources = {
  [Languages.DE]: {
    translation: de,
  },
  [Languages.EN]: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources: resources,
  supportedLngs: [Languages.DE, Languages.EN],
  fallbackLng: Languages.DE,
  //debug: process.env.NODE_ENV !== "production",
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    useSuspense: false,
  },
});

i18n.on("languageChanged", (lng) => {
  numbro.setLanguage(lng);
  moment.locale(lng);
});

export default i18n;
