import { useState } from "react";
import { Navigate } from "react-router-dom";
// import { Navigate } from "react-router-dom-v5-compat";

type StepCardProps = {
  title: string;
  icon: any;
  children: any;
  action: string;
  path: string;
  hidden: boolean;
};

interface IStepCardHoverState {
  hover: boolean;
}
interface IStepCardRedirectState {
  redirect: boolean;
}

const StepCard = (props: StepCardProps) => {
  const [stepCardHoverState, setStepCardHoverState] =
    useState<IStepCardHoverState>({
      hover: false,
    });

  const [stepCardRedirectState, setStepCardRedirectState] =
    useState<IStepCardRedirectState>({
      redirect: false,
    });
  if (props.hidden) {
    return <></>;
  } else {
    if (stepCardRedirectState.redirect) {
      return <Navigate to={props.path} />;
    } else {
      return (
        <div
          onMouseOver={() => setStepCardHoverState({ hover: true })}
          onMouseOut={() => setStepCardHoverState({ hover: false })}
          onClick={() => setStepCardRedirectState({ redirect: true })}
          className="group rounded rounded-2xl p-4 h-40 basis-2/12 w-2/12 3xl:w-4/12 3xl:basis-4/12 2xl:w-5/12 2xl:basis-5/12 lg:basis-full lg:w-full bg-white text-left hover:bg-black cursor-pointer"
        >
          <div className={stepCardHoverState.hover ? "hidden" : "inline"}>
            <div className="flex items-center gap-4">
              <div className="w-max text-xl">{props.icon}</div>
              <p className="w-max text-xl font-bold ">{props.title}</p>
            </div>
            <hr className="mb-2" />
            <div className="text-sm">{props.children}</div>
          </div>
          <div
            className={
              "text-white font-bold text-xl w-full text-center flex-row flex-wrap align-center justify-center gap-4 " +
              (stepCardHoverState.hover ? "flex" : "hidden")
            }
          >
            <p className="w-full text-center">{props.action}</p>
            <div className="text-center text-4xl">{props.icon}</div>
          </div>
        </div>
      );
    }
  }
};

export default StepCard;
