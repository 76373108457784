import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { Navigate, CompatRoute } from "react-router-dom-v5-compat";
import { AuthContext } from "../components/Context/AuthContext";
import Loading from "../components/Loading";

// type ProtectedRouteProps = {
//   path: string;
//   children: any;
//   exact?: boolean;
// };

interface IAdminRouteState {
  isAdmin: boolean;
  loading: boolean;
}

const AdminRoute = () => {
  const auth = useContext(AuthContext);
  const [adminRouteState, setAdminRouteState] = useState<IAdminRouteState>({
    isAdmin: false,
    loading: true,
  });

  useEffect(() => {
    const checkClaims = async () => {
      const idTokenResult = await auth.getClaims();
      if (!!idTokenResult?.claims.admin) {
        setAdminRouteState((adminRouteState) => {
          return { ...adminRouteState, isAdmin: true, loading: false };
        });
      } else {
        setAdminRouteState((adminRouteState) => {
          return { ...adminRouteState, isAdmin: false, loading: false };
        });
      }
    };
    checkClaims();
  }, [auth]);

  // if (adminRouteState.loading) {
  //   return <Loading />;
  // } else {
  //   if (adminRouteState.isAdmin) {
  //     return (
  //       <Route path={props.path}>
  //         {props.children}
  //       </Route>
  //     );
  //   } else {
  //     return <Navigate to="/" />;
  //   }
  // }
  if (adminRouteState.loading) {
    return <Loading />;
  } else {
    if (adminRouteState.isAdmin) {
      return <Outlet />;
    } else {
      return <Navigate to="/" />;
    }
  }
};

export default AdminRoute;
